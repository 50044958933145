import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import AccountRoleDialog from "./AccountRoleDialog";
import AccountWebMenu from "./AccountWebMenu";
import AccountMobileMenu from "./AccountMobileMenu";

const getUiTab = (accountid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Cuenta", component: <EditObjectDialog objectid={accountid} entityDefinition={accountUI}/> },
                {label : "Roles", component: <AccountRoleDialog accountid={accountid}/> },
                {label : "Menu web", component: <AccountWebMenu accountid={accountid}/> },
                {label : "Menu móvil", component: <AccountMobileMenu accountid={accountid}/> },
            ]}
    })
}

export const accountUI = {
    entity : {
        name: 'account',
        keyField: 'accountid',
        label: 'Cuenta',
        info: {typeName: 'es.rbm.model.jpa.Account'}
    },
    uiTable: {
        fields:{
            accountid : {name: 'accountid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3 , optional: true},
            account : {name: 'account', label:'Nombre' , typeName: 'String', optional: true},
            login : {name: 'login', label:'Usuario' , typeName: 'String', editable: true, align: 'center'},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true, align: 'center'},
            lastlogin : {name: 'lastlogin', label:'Último login' , typeName: 'Time', editable: false},
        },
        keyComponent: 'accountCrudManagement'
    },
    uiForm : {
        fields:{
            accountid : {name: 'accountid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true},
            account : {name: 'account', label:'Nombre' , typeName: 'String', optional: true},
            login : {name: 'login', label:'Usuario' , typeName: 'String', editable: true},
            password : {name: 'password', label:'Contraseña' , typeName: 'Password', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true},
            enabled : {name: 'enabled', label:'Activo' , typeName: 'Boolean', editable: true, optional: true, size: 2},
            keepsessionopen : {name: 'keepsessionopen', label:'Mantener sesión activa' , typeName: 'Boolean', editable: true, optional: true, size: 2},
        }
    },
    uiTab: (account) => getUiTab(account)
}

