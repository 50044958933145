import * as React from 'react';
import AccountingentryDialog from "../Accounting/AccountingentryDialog";
import Grid from "@mui/material/Grid";
import ChargeduedateGrid from "./ChargeduedateGrid";
import {useEffect} from "react";
import {doGetRest} from "../../../../../../Utils/Restclient/NetworkRestActions";

export default function SaleAccountingDialog(props) {
    const {saleinvoiceid} = props;
    const [invoice, setInvoice] = React.useState({...saleinvoiceid});

    useEffect(() => {
        doGetRest('saleinvoice', saleinvoiceid.saleinvoiceid, result => setInvoice(result));
    }, [props])

    return(
        <Grid container>
            <Grid item md={12}>
                <AccountingentryDialog accountingentryid={invoice.accountingentryid} />
            </Grid>
            <Grid item md={12}>
                <ChargeduedateGrid saleinvoiceid={saleinvoiceid} />
            </Grid>
        </Grid>
    )
}
