import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import NewWorkerContractDialog from "./NewWorkerContractDialog";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import CustomDialog from "../../../../../../View/CustomDialog";


const ui = {
    fields:{
        contractid : {name: 'contractid', label: '#' ,typeName: 'Number', editable: false, flex: 0.3},
        since : {name: 'since', label: 'Desde' ,typeName: 'Datetime', editable: true},
        until : {name: 'until', label: 'Hasta' ,typeName: 'Datetime', optional: true, editable: true},
        contractorid : {name: 'contractorid', label: 'Empresa' ,typeName: 'es.rbm.model.jpa.Contractor'},
        categoryid : {name: 'categoryid', label: 'Categoria' ,typeName: 'es.rbm.model.jpa.Category'},
        hourlycost : {name: 'hourlycost', label: 'Precio hora' ,typeName: 'Number'},

    }
}

export default function WorkerContractView(props) {
    const {workerid} = props;
    const [records, setRecords] = React.useState([]);
    const [contract, setContract] = React.useState(null);
    const [openNewWorkerContract, setOpenNewWorkerContract] = React.useState(false);
    const [refreshObjects, setRefreshObjects] = React.useState(false);

    const newWorkerContract = () => {
        setOpenNewWorkerContract(true);
    }

    const onEdit = (contractid) => {
        setContract(contractid);
        setOpenNewWorkerContract(!openNewWorkerContract)
    }

    const onDelete = (contractid) =>{
        deleteObject(contractid,{name:"contract", keyField:'contractid'},() => setRefreshObjects(!refreshObjects))
    }

    const onChange = (e) => {
        const record = records.filter(r => r.contractid === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        doPutRest('contract',toSend, response =>{
            if(response){
                success('Se ha guardado correctamente')
            }
        })

    }

    useEffect(() =>{
        if(!openNewWorkerContract){
            const filters = {
                fields: {workerid : {name: 'workerid', operator: 'OPERATOR_EQUAL', value: workerid},},
                entity : { name: 'contract'}
            }
            searchByCriteria(filters, result =>{
                setRecords(result);
                setContract(null);
            });
        }
    }, [refreshObjects, openNewWorkerContract, props])

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <CustomDialog maxWidth={"md"} title={!contract ? 'Nuevo contrato' : 'Editar contrato'}
                              open={openNewWorkerContract} onClose={() => setOpenNewWorkerContract(false)}>
                    <NewWorkerContractDialog contractid={contract} worker={workerid} setOpen={setOpenNewWorkerContract} open={openNewWorkerContract}/>
                </CustomDialog>
                <Button size={'small'} variant="contained" onClick={newWorkerContract} startIcon={<AddIcon />} sx={{m: 1}}>
                    Añadir contrato
                </Button>
                <GridTable ui={ui} sx={{mt: 2, height: '72vh'}} onDelete={onDelete} onEdit={onEdit}
                           records={records} rowId={'contractid'}
                           onChange={onChange} toolBar
                />
            </Box>
        </Box>
    );
}
