import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Chip
} from "@mui/material";
import { doGet, doPost } from "../../../../../Utils/Restclient/NetworkActions";
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import {cellStyles, headerCellStyles} from "./WorkerTimeControl";

const VacationManagement = () => {
    const [vacations, setVacations] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [vacationData, setVacationData] = useState({ startDate: "", endDate: "" });
    const navigate = useNavigate();

    useEffect(() => {
        fetchVacations();
    }, []);

    const fetchVacations = () => {
        doGet("presence/getAbsenceRequestForWorker", result => {
            if (result) {
                setVacations(result);
            }
        });
    };

    const handleVacationChange = (e) => {
        let {name, value} = e.target;
        let newData = {...vacationData, [name]: value };
        if (name === 'startDate') {
            newData.endDate = value;
        }
        setVacationData({...newData});
    };

    const handleRequestVacation = () => {
        const request = {since: vacationData.startDate, until: vacationData.endDate, reason: vacationData.reason}
        doPost("presence/generateAbsenceRequest", request, () => {
            setOpenDialog(false);
            fetchVacations();
        });
    };

    return (
        <Box sx={{ width: "100%", padding: 2 }}>
            <AppBar position="static" sx={{ backgroundColor: "white", color: "black" }}>
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Mis vacaciones
                    </Typography>
                    <Button color="inherit" onClick={() => navigate("/WorkerTimeControl")}>Volver</Button>
                </Toolbar>
            </AppBar>
            <Box textAlign="center" mt={2}>
                <Button variant="contained" color="primary" onClick={() => setOpenDialog(true)}>
                    Solicitar Vacaciones
                </Button>
            </Box>
            <TableContainer component={Paper} sx={{
                display: "flex",
                flexDirection: "column",
                overflow: "hidden",
                mt: 4
            }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell>F. Inicio</TableCell>
                            <TableCell>F. Fin</TableCell>
                            <TableCell>Estado</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {vacations.map((vacation, index) => (
                            <TableRow key={index}>
                                <TableCell>{vacation.since}</TableCell>
                                <TableCell>{vacation.until}</TableCell>
                                <TableCell>
                                    <Chip label={vacation.status} color={vacation.color}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
                <DialogTitle>Solicitar Vacaciones</DialogTitle>
                <DialogContent>
                    {/* Separar las fechas en una caja con margen */}
                    <Stack  spacing={1.5} sx={{pt: 2}}>
                        <TextField
                            label="Inicio"
                            type="date"
                            fullWidth
                            name="startDate"
                            value={vacationData.startDate}
                            onChange={handleVacationChange}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Fin"
                            type="date"
                            fullWidth
                            name="endDate"
                            value={vacationData.endDate}
                            onChange={handleVacationChange}
                            InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                            label="Motivo (Opcional)"
                            multiline
                            rows={3}
                            fullWidth
                            name="reason"
                            value={vacationData.reason || ""}
                            onChange={handleVacationChange}
                            placeholder="Escribe un comentario..."
                        />
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenDialog(false)}>Cancelar</Button>
                    <Button disabled={!vacationData.endDate || !vacationData.startDate} onClick={handleRequestVacation} variant="contained" color="primary">Enviar</Button>
                </DialogActions>
            </Dialog>

        </Box>
    );
};

export default VacationManagement;
