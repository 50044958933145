import {error} from "../Notification/notifications";
import store from "../Redux/store";
import {actionTypes} from "../Redux/reducer";
import {getToken, rootServer} from "../Constant";
import {JSOGdecode} from "../Jsog/JSOG";
import {useLocation} from "react-router-dom";
import {getPassword, getUser, isRememberPassword} from "../UtilsCommon";
import {doLogin} from "../../Solutions/ERP/src/Login/actions";


const handleJsog = (entity, jsog) =>  {

}

const disableNetwork = () => {
    store.dispatch({type: actionTypes.DISABLE_NETWORK})
}

const enableNetwork = () => {
    store.dispatch({type: actionTypes.ACTIVE_NETWORK})
}

export const networkHandle = () => {
    const originalFetch = window.fetch;
    window.fetch = (url, options = {}) => {
        enableNetwork();
        const event = new Event('networkRequestStart');
        document.dispatchEvent(event);
        return originalFetch(url, options)
            .then(response => {
                const event = new Event('networkRequestEnd');
                document.dispatchEvent(event);
                return response;
            })
            .finally(() => {
                disableNetwork();
            });
    };
}

const handleResponse = (response, entity ,callback, json, jsog, url, options) => {
    if (response.ok){
        if (json) {
            return response.json().then(json => {
                if(json){
                    json = JSOGdecode(json);
                    callback && callback(json)
                }
            }).catch(e => callback && callback());
        } else {
            callback && callback(response)
        }
    } else {
        let onError = options?.onError;
        if (onError) {
            onError(response, entity, callback)
        } else if (response.status === 500){
            response.text().then(message => error(message));
        } else if(response.status === 401){
            const pathname = window.location.pathname;
            if (pathname.length > 0 && (pathname !== "/" && !pathname.toLowerCase().includes('login'))){
                store.dispatch({type: actionTypes.UNAUTHORIZED, authorized: false })
            }
            store.dispatch({type: actionTypes.SET_USER, token: null})
            error("No autorizado. Inicie sesión con su cuenta");
            localStorage.removeItem("token-session-erp");
        } else if( response.status === 404) {
            error("No se encuentra la ruta en el servidor");
        } else{
            fetch("https://www.google.com", { method: "HEAD", mode: "no-cors" })
                .then(() => response.text().then(message =>error(message)))
                .catch(() => error("Por favor, revise su conexión a internet"));

        }
    }
}

export const doGet = (url, callback, jsog, options) => {
    let hideError = options?.hideError;
    isAvailableNetwork(available => {
        if (available) {
            const token = getToken();
            fetch(rootServer + url, {
                method: 'GET',
                headers: {
                    "Content-type": "application/json",
                    'Authorization': 'Bearer ' + token,
                }
            })
                .then(response =>{
                    handleResponse(response,null,callback, true, jsog, null, options)
                })
                .catch(e => {
                    if (!options?.hideError) {
                        error(e.toString())
                    }
                    let onError = options?.onError();
                    onError && onError();
                })
        }
    }, hideError);
}

export const doPost = (url, entity, callback, json, jsog, options) => {
    let hideError = options?.hideError;
    isAvailableNetwork(available => {
        let offlineMode = options?.offlineMode;
        if (available) {
            handleJsog(entity, jsog !== false);
            fetch(rootServer + url, {
                method: 'POST',
                body: JSON.stringify(entity),
                headers: {
                    "Content-type": "application/json",
                    'Authorization': 'Bearer ' + getToken(),
                }
            })
            .then(response => {
                handleResponse(response, entity, callback, true, jsog, url, options)
            })
            .catch(e => {
                if (!options?.hideError) {
                    error(e.toString())
                }
                let onError = options?.onError();
                onError && onError();
            })
        } else if (offlineMode) {
            handleResponse({}, entity, callback, true, jsog, url, options)
        }
    }, hideError);
}

export const doExternalPost = (url, entity ,callback, json, jsog) => {
    isAvailableNetwork(available => {
        if (available) {
            fetch(url, {
                method: 'POST',
                body: JSON.stringify(entity),
                headers: {
                    "Content-type": "application/json"
                }
            })
                .then(response =>{
                    handleResponse(response,entity ,callback, true, jsog)
                })
                .catch(e => {
                    error(e.toString())
                })
        }
    });
}

export const doDelete = (url, entity, callback, jsog) => {
    isAvailableNetwork(available => {
        if (available) {
            handleJsog(entity, jsog !== false);
            fetch(rootServer + url, {
                method: 'DELETE',
                body: JSON.stringify(entity),
                headers: {
                    "Content-type": "application/json",
                    'Authorization': 'Bearer ' + getToken(),
                }
            })
                .then(response =>{
                    handleResponse(response, entity, callback, false, jsog)
                })
                .catch(e => error(e.toString()))
        }
    });
}

export const doPut = (url, entity, callback, jsog) => {
    isAvailableNetwork(available => {
        if (available) {
            handleJsog(entity, jsog !== false);
            fetch(rootServer + url, {
                method: 'PUT',
                body: JSON.stringify(entity),
                headers: {
                    "Content-type": "application/json",
                    'Authorization': 'Bearer ' + getToken(),
                }
            })
                .then(response =>{
                    handleResponse(response, entity, callback, true)
                })
                .catch(e => error(e.toString()))
        }
    });
}

export const doGetDownloadFile = (url, fileName) => {
    isAvailableNetwork(available => {
        if (available) {
            fetch(rootServer + url, {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + getToken(),
                    }
                }
            ).then(response => {
                if (response.ok){
                    response.blob().then(blob => {
                        const fileURL = window.URL.createObjectURL(blob);
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = fileName;
                        alink.click();
                    })
                } else if(response.status === 500){
                    response.text().then(message => error(message));
                } else if(response.status === 401){
                    const pathname = window.location.pathname;
                    if (pathname.length > 0 && (pathname !== "/" || !pathname.toLowerCase().includes('login'))) {
                        store.dispatch({type: actionTypes.UNAUTHORIZED, authorized: false })
                    }
                    store.dispatch({type: actionTypes.SET_USER, token: null})
                    error("No autorizado. Inicie sesión con su cuenta");
                    localStorage.removeItem("token-session-erp");
                } else if( response.status === 404) {
                    error("No se encuentra la ruta en el servidor");
                } else{
                    response.text().then(message =>error(message));
                }
            }).catch( err => error(err))
        }
    });
}


export const doPostDownloadImage = (url, body, callback, options) => {
    isAvailableNetwork(available => {
        if (available) {
            const {printError} = options || {}
            fetch(rootServer + url, {
                    method: 'POST',
                    body: JSON.stringify(body),
                    headers: {
                        "Content-type": "application/json",
                        'Authorization': 'Bearer ' + getToken(),
                    }
                }
            ).then(response => {
                if (response.ok){
                    response.blob().then(blob => {
                        const fileURL = window.URL.createObjectURL(blob);
                        callback && callback(fileURL)
                    })
                } else if(response.status === 500 && printError){
                    response.text().then(message => error(message));
                }
            }).catch( err => error(err))
        }
    });
}

export const doPostDownloadFile = (url, toSent, fileName) => {
    isAvailableNetwork(available => {
        if (available) {
            fetch(rootServer + url, {
                    method: 'POST',
                    body: JSON.stringify(toSent),
                    headers: {
                        "Content-type": "application/json",
                        'Authorization': 'Bearer ' + getToken(),
                    }
                }
            ).then(response => {
                if (response.ok) {
                    response.blob().then(blob => {
                        const fileURL = window.URL.createObjectURL(blob);
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = fileName;
                        alink.click();
                    })
                } else {
                    handleResponse(response)
                }
            }).catch( err => error(err))
        }
    });
}

export const doGetPDF = (url, callback) => {
    isAvailableNetwork(available => {
        if (available) {
            fetch(rootServer + url,
                {
                    method: 'POST',
                    headers: {
                        "Content-type": "application/json",
                        'Authorization': 'Bearer ' + getToken(),
                    }
                }
            ).then( res => {
                if (res.ok) {
                    res.blob().then(blob => {
                        var file = window.URL.createObjectURL(blob);
                        callback && callback(file);
                    })
                } else {
                    handleResponse(res)
                }
            }).catch( err => error(err.toString()));
        }
    });
}

export const doPostPDF = (url, request, callback) => {
    isAvailableNetwork(available => {
        if (available) {
            fetch(rootServer + url,
                {
                    method: 'POST',
                    body: JSON.stringify(request),
                    headers: {
                        "Content-type": "application/json",
                        'Authorization': 'Bearer ' + getToken(),
                    }
                }
            ).then( res => {
                if (res.ok) {
                    res.blob().then(blob => {
                        var file = window.URL.createObjectURL(blob);
                        callback && callback(file);
                    })
                } else {
                    handleResponse(res)
                }
            }).catch( err => error(err.toString()));
        }
    });
}

export const doDownloadFile = (url, entity, fileName, jsog) => {
    isAvailableNetwork(available => {
        if (available) {
            handleJsog(entity, jsog !== false);
            fetch(rootServer + url,
                {
                    method: 'POST',
                    body: JSON.stringify(entity),
                    headers: {
                        "Content-type": "application/json",
                        'Authorization': 'Bearer ' + getToken(),
                    }
                }).then(response => {
                if (response.ok) {
                    response.blob().then(blob => {
                        const fileURL = window.URL.createObjectURL(blob);
                        let alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = fileName;
                        alink.click();
                    })
                } else {
                    handleResponse(response)
                }

            }).catch( err => error(err))
        }
    });
}

export const doPostFile = (url, file, callback, json) => {
    isAvailableNetwork(available => {
        if (available) {
            fetch(rootServer + url, {
                method: 'POST',
                body: file,
                headers: {
                    'Content-type': 'application/octet-stream',
                    'Authorization': 'Bearer ' + getToken(),
                }
            })
                .then(response =>{
                    handleResponse(response, file ,callback, json || false)
                })
                .catch(e => error(e.toString()))
        }
    });

}

const isAvailableNetwork = (callback, hideError) => {
    if (!navigator.onLine) {
        if (!hideError) {
            error('Por favor, revise su conexión a internet');
        }
        callback && callback(false);
    } else {
        callback && callback(true);
    }

    /*fetch("https://www.google.com", { method: "HEAD", mode: "no-cors" })
        .then(() => callback && callback(true))
        .catch(() => callback && callback(false));*/

}


