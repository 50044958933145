import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import {Badge, Divider, LinearProgress, Stack} from "@mui/material";
import {Link, useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {doGet, doPost} from "../Utils/Restclient/NetworkActions";
import {getToken, serverName} from "../Utils/Constant";
import {useStateValue} from "../Utils/Redux/StateProvider";
import {actionTypes} from "../Utils/Redux/reducer";
import store from "../Utils/Redux/store";
import Grid from "@mui/material/Grid";
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import PropTypes from "prop-types";
import HelpIcon from '@mui/icons-material/Help';
import ListItemIcon from "@mui/material/ListItemIcon";
import GroupIcon from '@mui/icons-material/Group';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {ROLES} from "../roles";
import {useTheme} from "@mui/material/styles";
import {SOLUTIONS} from "../solutions";
import NotificationsIcon from "@mui/icons-material/Notifications";
import WebSocketService from "../Utils/WebSocket/WebSocketService";
import CloseIcon from '@mui/icons-material/Close';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import {format, parseISO} from "date-fns";
import {doPostRest, doPostRestList} from "../Utils/Restclient/NetworkRestActions";
import {playNotificationSound} from "../Utils/UtilsCommon";
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import {info} from "../Utils/Notification/notifications";
import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated';


const ALLS_USERS = 'ALLS_USERS';

const settingsMenu = {
    notUser: [
        {   title: 'Contactar',
            //destination: "/",
            color: 'black',
            action: null,
            icon: <ContactSupportIcon />,
            roles: [ALLS_USERS]
        },
    ],

    user: [
        {   title: 'Inicio',
            destination: "/main",
            action: null,
            color: 'black',
            roles: [ALLS_USERS, ROLES.ROLE_HUMAN_RESOURCES],
            hides_roles: [ROLES.ROLE_TICKS],
            icon: <HomeIcon fontSize="small"/>,
        },
        {   title: 'Usuarios',
            destination: "/users",
            action: null,
            color: 'black',
            roles: [ROLES.ROLE_ADMIN],
            hides_roles: [ROLES.ROLE_TICKS],
            solutions: [SOLUTIONS.SOLUTION_AGRO, SOLUTIONS.SOLUTION_DEFAULT],
            icon: <GroupIcon/>
        },
        {   title: 'Ayuda',
            destination: null,
            action: () => window.open('https://docs.sibema.es', '_blank'),
            color: 'black',
            roles: [ALLS_USERS],
            hides_roles: [ROLES.ROLE_TICKS],
            icon: <HelpIcon fontSize="small"/>
        },
        {   title: 'Salir',
            destination: "/logout",
            action: "Logout",
            color: 'red',
            roles: [ALLS_USERS],
            hides_roles: [],
            icon: <ExitToAppIcon/>
        }
    ]
}

const CustomAvatar = (props) => {
    const {state, handleOpenUserMenu, account} = props;

    useEffect(() => {}, [props]);

    return(
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={state.avatar ? account?.login?.substring(0,1)?.toUpperCase() : ""} src={state.token ? "/static/images/avatar/2.jpg" : null} />
        </IconButton>
    )
}

CustomAvatar.propTypes = {
    state: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    handleOpenUserMenu: PropTypes.func.isRequired,
};

const LabelText = ({ account, state }) => {
    if (!account || !state.token || !state.avatar) return null;

    return (
        <Typography
            sx={{
                mr: 2,
                textAlign: "center",
                fontWeight: "bold",
                "&::before": {
                    content: {
                        xs: `"${account?.login?.toUpperCase()}"`,
                        md: `"${(account?.account || account?.login)?.toUpperCase()}"`
                    }
                }
            }}
        />
    );
};


LabelText.propTypes = {
    state: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    handleOpenUserMenu: PropTypes.func.isRequired,
};

const NavBar = () => {
    const [{menuVisibility}, dispatch] = useStateValue();
    const navigate = useNavigate();
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [account, setAccount] = React.useState(null);
    const [companyName, setCompanyName] = React.useState('');
    const [state, setState] = React.useState({menu : (getToken() ? settingsMenu.user : settingsMenu.notUser), token: getToken(), waiting: false, avatar: false});
    const [notifications, setNotifications] = useState([]);
    const [anchorElNotifications, setAnchorElNotifications] = useState(null);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isIOS, setIsIOS] = useState(false);
    const isMobile = /android|iphone|ipad|ipod/i.test(navigator.userAgent);


    const theme = useTheme();

    store.subscribe(() => {
        const change = store.getState();
        const localToken = getToken();
        const isUserLoad = change.token?.length > 0 || localToken?.length > 0;
        const newMenu = isUserLoad ? settingsMenu.user : settingsMenu.notUser;
        const newToken = isUserLoad ? localToken : null ;
        const newState = {token: newToken, waiting: change?.active_network, avatar: isUserLoad}
        setState({...state, ...newState})
    })

    useEffect(() => {
        if (account) {
            doGet('notification/getPendingUserNotification', result => {
                setNotifications([...result]);
                WebSocketService.connect(account, (result) => {
                    playNotificationSound();
                    let newNotifications =  JSON.parse(result);
                    setNotifications((prevNotifications) => [...newNotifications, ...prevNotifications]);
                });
            })
        }
    }, [account]);

    useEffect(() => {
        const userAgent = window.navigator.userAgent.toLowerCase();
        setIsIOS(/iphone|ipad|ipod/.test(userAgent) && !window.navigator.standalone);

        window.addEventListener('beforeinstallprompt', (event) => {
            event.preventDefault();
            setDeferredPrompt(event);
        });
    }, []);

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const Logout = () =>{
        localStorage.removeItem("token-session-erp");
        localStorage.removeItem('remember-email');
        localStorage.removeItem('remember-password');
        localStorage.removeItem('remember-me');
        setState({...state, token: null, menu: settingsMenu.notUser})
        dispatch({type: actionTypes.SET_USER, token: null})
    }

    const changeMenu = () =>{
        dispatch({type: actionTypes.VISIBLE_MENU, value: !menuVisibility})
    }

    const handleSelectUserMenu = (index, setting) => {
        if (setting.action) {
            if (setting.action === "Logout") {
                Logout();
            } else {
                setting.action();
            }
        }
        setAnchorElUser(null);
    };

    function filterMenu(newState) {
        doGet('auth/getRoles', roles => {
             const newMenu = settingsMenu.user.filter(menuEntry => {
                if (menuEntry?.roles?.length && !menuEntry?.roles?.includes(ALLS_USERS)) {
                    return menuEntry?.roles?.some(e => roles?.includes(e)) && !menuEntry?.hides_roles?.some(e => roles?.includes(e));
                } else if (menuEntry?.hides_roles?.length) {
                    return !menuEntry?.hides_roles?.some(e => roles?.includes(e))
                }
                return menuEntry?.roles?.includes(ALLS_USERS);
            });
            setState({...newState, menu: newMenu})
        })
    }

    useEffect(() =>{
        if (state.token) {
            doGet('auth/getProfile', result =>{
                setAccount(result);
                filterMenu({...state, avatar: true});
            }, null, {hideError: true})

            doGet('configuration/getAttributeConfig/Nombre servidor', result => {
                setCompanyName(result?.value || '');
            }, null, {hideError: true})
        }
    },[state.token])

    const handleOpenNotifications = (event) => {
        setAnchorElNotifications(event.currentTarget);
    };

    const handleCloseNotifications = () => {
        setAnchorElNotifications(null);
    };

    const handleMarkAsRead = (notification, index) => {
        const toSend = {...notification}
        toSend.readed = true;
        doPostRest('accountnotification', toSend, result => {
            let newNotifications = [...notifications];
            newNotifications.splice(index, 1);
            setNotifications([...newNotifications]);
        })
    };

    const clearAllNotification = () => {
        const toSend = [...notifications].map(n => {
            n.readed = true;
            return n;
        })
        doPostRestList('accountnotification', toSend, result =>  setNotifications([]))

    };

    const formatDate = (input) => {
        let date;
        if (typeof input === "string") {
            date = parseISO(input);
        } else if (typeof input === "number") {
            date = new Date(input);
        } else {
            return '';
        }
        return isNaN(date) ? '' : format(date, "dd/MM/yyyy HH:mm");
    };

    const handleInstallClick = async () => {
        if (isIOS) {
            info('Para instalar la app en iOS, abre Safari, toca Compartir y selecciona Añadir a pantalla de inicio')
        } else if (deferredPrompt) {
            deferredPrompt.prompt();
            const { outcome } = await deferredPrompt.userChoice;
            if (outcome === 'accepted') {
                console.log('El usuario aceptó la instalación');
            }
            setDeferredPrompt(null);
        }
    };


    return (
        <Box>
            <AppBar position={"fixed"}
                    style={{
                        background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
                        color: '#ffffff',
                        boxShadow: 'inset 0 0 20px rgba(255, 255, 255, 0.1), 0 0 20px rgba(255, 255, 255, 0.1)',
                    }}>

                <Grid container alignItems="center" justifyContent="space-between" sx={{ px: 2 }}>
                    <Grid item xs={2} sm={1} md={0.5}>
                        <Tooltip title="Abrir/cerrar menú">
                            <IconButton onClick={changeMenu} color={'inherit'}>
                                <MenuIcon fontSize={'large'}/>
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={8} sm={5} md={4.5} textAlign="center">
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            sx={{
                                fontFamily: 'monospace',
                                fontWeight: 400,
                                color: 'white',
                                textDecoration: 'none',
                                display: { xs: 'none', md: 'flex' }
                            }}>
                            {companyName}
                        </Typography>
                    </Grid>
                    <Grid item xs={2} sm={6} md={7}>
                        <Toolbar disableGutters sx={{ display: "flex", justifyContent: "flex-end" }}>
                            {deferredPrompt && (
                                <IconButton color="inherit" onClick={handleInstallClick}>
                                    {isMobile ? <InstallMobileIcon /> : <BrowserUpdatedIcon/>}
                                </IconButton>
                            )}
                            <Stack spacing={2} deli direction={'row'} alignItems={'center'}>
                                <Stack spacing={0} deli direction={'row'} alignItems={'center'} sx={{display: { xs: 'none', md: 'flex' }}}>
                                    {/*<IconButton color="inherit" onClick={() => navigate('/UserCalendar')}>
                                        <EventIcon />
                                    </IconButton>*/
                                    }
                                    {state?.avatar && (<IconButton color="inherit">
                                        <Badge badgeContent={notifications.length} color="error" onClick={handleOpenNotifications}>
                                            <NotificationsIcon />
                                        </Badge>
                                    </IconButton>)}
                                </Stack>
                                <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', md: 'flex' }, borderRightWidth: 2, borderColor: 'gray' }} />
                                <LabelText account={account} state={state} />
                                <Box sx={{ flexGrow: 0 }}>
                                    <Tooltip title="Abrir ajustes">
                                        <CustomAvatar account={account} state={state} handleOpenUserMenu={handleOpenUserMenu}/>
                                    </Tooltip>
                                    <Menu
                                        sx={{ mt: '45px'}}
                                        id="menu-appbar"
                                        anchorEl={anchorElUser}
                                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        keepMounted
                                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                                        open={Boolean(anchorElUser)}
                                        onClose={handleCloseUserMenu}
                                    >
                                        {state.menu?.map((setting, i) => (
                                            <Link key={i} to={setting.destination} style={{ textDecoration: 'none', color: 'black' }}>
                                                <MenuItem key={i} onClick={() => handleSelectUserMenu(i, setting)}>
                                                    {setting.icon && <ListItemIcon> {setting.icon} </ListItemIcon>}
                                                    <Typography sx={{ mr: 3 }} textAlign="center">{setting.title}</Typography>
                                                </MenuItem>
                                                {i !== (state.menu.length - 1) && <Divider />}
                                            </Link>
                                        ))}
                                    </Menu>
                                </Box>
                            </Stack>
                        </Toolbar>
                    </Grid>
                    <Menu
                        anchorEl={anchorElNotifications}
                        open={Boolean(anchorElNotifications)}
                        onClose={handleCloseNotifications}
                        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                        transformOrigin={{ vertical: "top", horizontal: "right" }}
                        sx={{ mt: 1, height: '50vh', width: '70vh' }}
                    >
                        {notifications.length > 0 ? (
                            <div>
                                <Grid container alignItems="center" sx={{
                                    position: "sticky",
                                    top: 0,
                                    backgroundColor: "background.paper",
                                    zIndex: 10,
                                    padding: "8px 16px"}}>
                                    <Grid item md={11} sx={{ display: "flex", justifyContent: "center" }}>
                                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>Notificaciones</Typography>
                                    </Grid>
                                    <Grid item md={1} sx={{ display: "flex", justifyContent: "center" }}>
                                        <Tooltip title="Limpiar todo">
                                            <IconButton onClick={clearAllNotification}
                                                        size="medium">
                                                <ClearAllIcon fontSize="large" />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                                <Divider />
                                {notifications.map((notification, index) => (
                                <Box sx={{backgroundColor: '#ffffff', borderBottom: 2, borderColor: 'divider', pb: 2}}>
                                    <MenuItem key={index} sx={{backgroundColor: '#ffffff', flexDirection: "row", display: "flex", alignItems: "center", justifyContent: "flex-start", width: '70vh', height: '100%'}}/*onClick={() => handleNotificationClick(notification)}*/>
                                        <Grid container >
                                            <Grid item md={10.5}>
                                                <Grid container>
                                                    <Grid item md={12}>
                                                        <Typography sx={{whiteSpace: "normal", wordWrap: "break-word", alignItems: "center"}} noWrap variant="body2">
                                                            {formatDate(notification.date)}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item md={10.5} sx={{ display: 'flex', whiteSpace: "normal", wordWrap: "break-word", alignItems: "center"}}>
                                                        <Typography sx={{whiteSpace: "normal", wordWrap: "break-word", alignItems: "center"}} noWrap variant="body2">
                                                            {notification.message}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item md={1}>
                                                <IconButton
                                                    onClick={() => handleMarkAsRead(notification, index)}
                                                    size="small">
                                                    <CloseIcon size="small"/>
                                                </IconButton>
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                </Box>
                                ))}
                            </div>
                        ) : (
                            <MenuItem disabled>
                                <Typography variant="body2" color="textSecondary">No hay notificaciones</Typography>
                            </MenuItem>
                        )}
                    </Menu>
                    {state.waiting && (
                        <Grid item xs={12}>
                            <LinearProgress color="secondary" key={'mainLinearProgress'}/>
                        </Grid>
                    )}
                </Grid>
            </AppBar>
        </Box>
    );
};
export default NavBar;
