import * as React from 'react';
import Box from "@mui/material/Box";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import AccountingModel349 from "./AccountingModel349";
import AccountingModel303 from "./AccountingModel303";
import AccountingModel347 from "./AccountingModel347";
import AccountingModel390 from "./AccountingModel390";
import {Component} from "react";

const accountingmodels = ['Modelo 303', 'Modelo 347', 'Modelo 349', 'Modelo 390']


const components = (option, onPressBack) => {
    const options = {
        [accountingmodels[0]]: <AccountingModel303 onPressBack={onPressBack} />,
        [accountingmodels[1]]: <AccountingModel347 onPressBack={onPressBack} />,
        [accountingmodels[2]]: <AccountingModel349 onPressBack={onPressBack} />,
        [accountingmodels[3]]: <AccountingModel390 onPressBack={onPressBack} />,
    };

    return options[option] || null;
};

const formUI =  {
    fields: {
        e1 : {name: 'e1', label:'Modelo' , typeName: 'Espace', size: 4},
        model : {name: 'model', label:'Modelo' , typeName: 'Enum', valueOptions: accountingmodels, size: 4},
        e2 : {name: 'e2', label:'Modelo' , typeName: 'Espace', size: 4},
      }
}

const initialState = {filter: {model: null}};
export default function AccountingModels() {
    const [state, setState] = React.useState({...initialState});
    const {filter} = state;
    const {model} = filter;

    const filterChange = (filter) => setState({...state, filter})
    const onPressBack = () => setState({...initialState})


    let loadComponent = components(model, onPressBack);
    return(
        <Box sx={{m: 2}}>
            {loadComponent ? loadComponent :
                <SingleRecordForm ui={formUI} records={filter} onChange={filterChange} disableAutoFocus/>}
        </Box>
    )
}
