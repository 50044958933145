import { Client } from '@stomp/stompjs';
import SockJS from 'sockjs-client';
import {getToken, rootServer} from "../Constant";

class WebSocketService {
    constructor() {
        this.client = null;
    }

    connect(account, callback) {
        const socketUrl = rootServer + 'ws-notifications';
        const sockJsOptions = {
            transports: ["websocket", "xhr-streaming", "xhr-polling"]
        };

        this.client = new Client({
            webSocketFactory: () => new SockJS(socketUrl, null, sockJsOptions),
            debug: (str) => console.log(str),
            reconnectDelay: 5000,
            beforeConnect: () => {
                this.client.connectHeaders = {
                    Authorization: `Bearer ${getToken()}`
                };
            },
            onConnect: (frame) => {
                this.client.subscribe('/topic/notifications/user/' + account.accountid, (message) => {
                    callback(message.body);
                }, { Authorization: `Bearer ${getToken()}` });
            },
            onStompError: (frame) => {
                console.error("Error en STOMP: ", frame);
            }
        });

        this.client.activate();
    }

    disconnect() {
        if (this.client) {
            this.client.deactivate();
        }
    }
}

export default new WebSocketService();
