import {useEffect, useState} from "react";
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Tooltip,
    MenuItem,
    Select,
    FormControl,
    InputLabel,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Button,
    Chip
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import {getRelativeMaxViews} from "../../../../../Utils/Constant";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import SingleRecordForm from "../../../../../View/Form/SingleRecordForm";
import * as React from "react";
import {doPost} from "../../../../../Utils/Restclient/NetworkActions";

const StyledTableHead = styled(TableHead)({
    backgroundColor: "#f5f5f5",
    "& th": {
        fontWeight: "bold",
    },
});

const defaultUI = (options) => (
    {
        fields: {
            since: {name: 'since', label: 'Desde', typeName: 'Date', size: 2.5},
            until: {name: 'until', label: 'Hasta', typeName: 'Date', size: 2.5, empty: true, optional: true},
            status: {name: 'status', label: 'Estado', typeName: 'Enum', valueOptions: options, size: 2.5, optional: true},
            workers : {name: 'workers', label: 'Trabajadores' ,typeName: 'es.rbm.model.jpa.Worker', size: 4, height: 'small', isMulti: true, optional: true},
        }
    }
)

export default function HolidayManagement() {
    const [records, setRecords] = useState([]);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState(null);
    const [remarks, setRemarks] = useState("");
    const [filters, setFilters] = useState({});
    const [complete, setComplete] = useState(null);
    const [ui, setUI] = useState(defaultUI(['Pendiente', 'Aprobado', 'Rechazado']));



    useEffect(() => {
        if (complete) {
            refresh();
        }
    }, [filters])

    const refresh = () => {
        doPost('presence/getAbsenceRequest', filters, result => {
            let status = filters.status;
            let newRecords = [...result];
            if (status) {
                newRecords = newRecords.filter(r => r.status === status);
            }
            setRecords([...newRecords]);
        })
    }

    const handleOpenDialog = (request, accept) => {
        setSelectedRequest({ selected: {...request}, accept: accept });
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setRemarks("");
    };

    const handleUpdateStatus = () => {
        const toSend = {...selectedRequest, remarks}
        handleCloseDialog();
        doPost('presence/acceptOrRejectAbsencerequest', toSend, () => refresh());
    };

    const handledChange = (record, complete) => {
        setFilters({...record});
        setComplete(complete);
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box maxHeight sx={{mx: 1}}>
                <Box sx={{ display: "flex", gap: 2, mb: 3, mt: 2 }}>
                    <SingleRecordForm ui={ui} records={filters} onChange={handledChange} />
                </Box>
                <Box sx={{ display: "flex", overflow: "auto" }}>
                    <TableContainer component={Paper} sx={{ height: getRelativeMaxViews(-7)}}> {/* Ajusta la altura según necesites */}
                        <Table stickyHeader> {/* Activa el encabezado pegajoso */}
                            <StyledTableHead>
                                <TableRow>
                                    <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                                        Trabajador
                                    </TableCell>
                                    <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                                        DNI
                                    </TableCell>
                                    <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                                        Fecha Inicio
                                    </TableCell>
                                    <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                                        Fecha Fin
                                    </TableCell>
                                    <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                                        Motivo
                                    </TableCell>
                                    <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                                        Estado
                                    </TableCell>
                                    <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                                        Observaciones
                                    </TableCell>
                                    <TableCell sx={{ position: "sticky", top: 0, backgroundColor: "white", zIndex: 1 }}>
                                        Acciones
                                    </TableCell>
                                </TableRow>
                            </StyledTableHead>
                            <TableBody>
                                {records.map((absencerequest) => (
                                    <TableRow bgcolor={absencerequest.color} key={absencerequest?.absencerequestid?.absencerequestid}>
                                        <TableCell>{absencerequest.worker}</TableCell>
                                        <TableCell>{absencerequest.idcardnumber}</TableCell>
                                        <TableCell>{absencerequest.since}</TableCell>
                                        <TableCell>{absencerequest.until}</TableCell>
                                        <TableCell>
                                            <Tooltip title={absencerequest.motivo} arrow>
                                <span style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", display: "block", maxWidth: "150px" }}>
                                    {absencerequest.reason}
                                </span>
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell><Chip label={absencerequest.status} color={absencerequest.chipcolor || 'primary'}/></TableCell>
                                        <TableCell>{absencerequest.remarks}</TableCell>
                                        <TableCell>
                                            {!absencerequest?.absencerequestid?.rejected && !absencerequest?.absencerequestid?.absenceid && (
                                                <>
                                                    <IconButton onClick={() => handleOpenDialog(absencerequest, true)} color="success">
                                                        <CheckCircleIcon />
                                                    </IconButton>
                                                    <IconButton onClick={() => handleOpenDialog(absencerequest, false)} color="error">
                                                        <CancelIcon />
                                                    </IconButton>
                                                </>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>
                <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                    <DialogTitle>Agregar observación</DialogTitle>
                    <DialogContent>
                        <TextField sx={{mt: 2}} fullWidth multiline rows={4} label="Observación" variant="outlined" value={remarks} onChange={(e) => setRemarks(e.target.value)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialog}>Cancelar</Button>
                        <Button onClick={handleUpdateStatus} color="primary">Guardar</Button>
                    </DialogActions>
                </Dialog>
            </Box>
        </LocalizationProvider>
    );
}
