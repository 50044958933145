import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";

const getUiTab = (saleorderstatus) =>{
    return ({
        uiTabs : {fields : [
                {label : "Estado", component: <EditObjectDialog objectid={saleorderstatus} entityDefinition={saleorderstatusUI}/> },
            ]}
    })
}

export const saleorderstatusUI = {
    entity : {
        name: 'saleorderstatus',
        keyField: 'saleorderstatusid',
        label: 'Estado de pedido de venta',
        info: {typeName: 'es.rbm.model.jpa.Saleorderstatus'},
        colorValue: 'colorid',

    },
    uiTable : {
        fields:{
            saleorderstatusid : {name: 'saleorderstatusid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            saleorderstatus : {name: 'saleorderstatus', label:'Estado' , typeName: 'String', flex: 0.8, align: 'center'},
            nature : {name: 'nature', label:'Naturaleza' , typeName:'Number', align: 'center'},
            colorid : {name: 'colorid', label:'Color' , typeName:'es.rbm.model.jpa.Color', align: 'center', editable: false},
        },
        keyComponent: 'saleorderstatusCrudManagement'

    },
    uiForm : {
        fields:{
            saleorderstatusid : {name: 'saleorderstatusid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            saleorderstatus : {name: 'saleorderstatus', label:'Estado' , typeName: 'String', flex: 0.8, align: 'center'},
            nature : {name: 'nature', label:'Naturaleza' , typeName:'Number', align: 'center'},
            colorid : {name: 'colorid', label:'Color' , typeName:'es.rbm.model.jpa.Color', align: 'center'},
        },
    },
    uiTab: (saleorderstatus) => getUiTab(saleorderstatus)

}

