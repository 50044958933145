import * as React from 'react';
import Box from "@mui/material/Box";
import {addNewObject} from "./actions";
import SuccessButton from "../../Button/SuccessButton";
import SingleRecordForm from "../SingleRecordForm";
import Grid from "@mui/material/Grid";
import {success} from "../../../Utils/Notification/notifications";

export default function NewObjectDialog(props) {
    const {open, setOpen, entityDefinition, handleNewObject} = props;
    const [state, setState] = React.useState({objectid: {}, isComplete: false});
    const {isComplete, objectid} = state;


    const handledChange = (record, complete) => {
        setState(prevState => ({...prevState, objectid: {...record}, isComplete: complete}));
    }

    const onClick = (e) => {
        e.preventDefault();
        addNewObject(objectid, entityDefinition.entity, result => {
            success('Se ha añadido correctamente', () => {
                setOpen(!open);
                handleNewObject(result)
            });
        });
    };
    return (
        <Grid sx={{m: 3, display: 'flex', flexDirection: 'column', alignItems: 'center'}} maxWidth={'80vh'}>
            <Grid container>
                <Grid item md={12} overflowY={'auto'}>
                    <Box component="form" noValidate sx={{p: 1}} maxHeight={'60vh'} overflow={'auto'}>
                        <SingleRecordForm ui={entityDefinition.uiForm} records={objectid} onChange={handledChange}/>
                    </Box>
                </Grid>
                <Grid item md={12}>
                    <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onClick} disabled={!isComplete}/>
                </Grid>
            </Grid>
        </Grid>
    );

}
