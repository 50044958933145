import * as React from 'react';
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import Box from "@mui/material/Box";
import SaveRoundedButton from "../../../../../../View/Button/SaveRoundedButton";
import Grid from "@mui/material/Grid";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";

const formUI =  {
    fields: {
        date : {name: 'date', label:'Fecha contable' , typeName: 'Date', editable: true, autofocus: true, size: 2},
        reference : {name: 'reference', label:'Referencia' , typeName: 'String', editable: true, align: 'center', size: 2, autofocus: true},
        customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer', editable: true, align: 'center', size: 3},
        bankaccountid : {name: 'bankaccountid', label:'Cuenta banco' , typeName: 'es.rbm.model.jpa.Bankaccount', optional: false, editable: true, size: 3},
        amount : {name: 'amount', label:'Importe (€)' , typeName: 'Number', editable: true, align: 'center', size: 2},
    }
}

export default function AccountingentryFromCharge(props) {
    let initialState = {records: {}, complete: false};
    const [state, setState] = React.useState(initialState);
    const {records, complete} = state;
    const {contractorid, onFinish} = props;

    const handledChange = (record, complete) => {
        setState({...state, records: {...record}, complete: complete});
    }

    const generateAccountingEntry = () => {
        const request = {...records, contractorid}
        doPost('accounting/accountingentryFromCharge', request, result => {
            if (result) {
                onFinish && onFinish(result);
            }
        })

    }

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container spacing={2}>
                <Grid item md={11}>
                    <SingleRecordForm ui={formUI} records={records} onChange={handledChange}/>
                </Grid>
                <Grid item md={1} sx={{mt: -1}}>
                    <SaveRoundedButton onClick={generateAccountingEntry} disabled={!complete}/>
                </Grid>
            </Grid>
        </Box>
    )
}
