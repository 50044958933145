import * as React from 'react';
import {sidebarTest} from "./sidebarTest";
import {useEffect} from "react";
import {
    Box,
    Grid,
    Paper,
    TextField
} from "@mui/material";
import PersistentHorizontalTabList from "./View/Tab/PersistentHorizontalTabList";
import {doGet, doPostDownloadImage} from "./Utils/Restclient/NetworkActions";
import {useStateValue} from "./Utils/Redux/StateProvider";
import {actionTypes} from "./Utils/Redux/reducer";
import {useNavigate} from "react-router-dom";
import {tags} from "./sidebarTags";
import {error} from "./Utils/Notification/notifications";
import TestSidebar from "./TestSidebar";
import {serverName} from "./Utils/Constant";
import _ from "lodash";

const defaultValueInit = {
    fields: []
};

export default function MainSidebar() {
    const [tab, setTab] = React.useState(0);
    const [menu, setMenu] = React.useState(null);
    const [expansible, setExpansible] = React.useState(false);
    const [result, setResult] = React.useState(null);
    const [completeMenu, setCompleteMenu] = React.useState(null);
    const [{menuVisibility, token}, dispatch] = useStateValue();
    const navigate = useNavigate();
    const [filterText, setFilterText] = React.useState('');
    const [sidebarTabUI, setSidebarTabUI] = React.useState(defaultValueInit);
    const [imageSrc, setImageSrc] = React.useState(null);

    useEffect(() => {
        refresh();
    }, [menuVisibility]);

    useEffect(() => {
        fetchImage();
    }, []);

    function fetchImage() {
        const request = { path: `main/documents/${serverName}/${serverName}` };
        doPostDownloadImage("rest/getFile", request, (result) => {
            setImageSrc(result);
        });
    }

    const refresh = (callback, useCache) => {
        if (!useCache || !result) {
            doGet("ui/getMenuEntries", (res) => {
                processResult(res, callback);
            });
        } else {
            processResult(result, callback);
        }
    };

    const processResult = (res, callback) => {
        if (res?.length === 0) {
            localStorage.removeItem("token-session-erp");
            localStorage.removeItem("remember-me");
            navigate("/");
            dispatch({ type: actionTypes.SET_USER, token: null });
        } else {
            setResult(res);
            const filtered = _.cloneDeep(sidebarTest).filter((e) => res?.includes(e.key));
            let filteredByPermission = filtered.filter((i) => filterMenuItemsByPermission(i, res));
            filteredByPermission = filteredByPermission.filter(
                (e) => e.component || e.children?.length > 0
            );
            setMenu(filteredByPermission);
            setCompleteMenu(_.cloneDeep(filteredByPermission));
            callback && callback(_.cloneDeep(filteredByPermission));
        }
    };

    const handleSearchChange = (evt) => {
        const value = evt?.target?.value || "";
        if (!value) {
            setExpansible(false);
            setFilterText("");
            refresh((r) => setMenu(r), true);
        } else {
            setExpansible(true);
            // Si estamos borrando parte del texto, refrescamos para restaurar menús
            if (value.length < filterText.length && !evt?.target?.noRefresh) {
                refresh(() =>
                    handleSearchChange({ target: { value, noRefresh: true } }), true
                );
            }
            setFilterText(value);
            const menuToShow = value
                ? completeMenu.filter((i) => filterMenuItems(i, value))
                : completeMenu;
            setMenu(menuToShow);
        }
    };

    const filterMenuItems = (menuItem, value) => {
        const hasValue =
            menuItem.label?.toLowerCase().includes(value.toLowerCase()) ||
            tags[menuItem.key]?.toLowerCase().includes(value.toLowerCase());
        const hasValueInChildren = menuItem.children?.some((child) =>
            filterMenuItems(child, value)
        );

        if (hasValue || hasValueInChildren) {
            if (menuItem.children?.length > 0) {
                menuItem.children = menuItem.children.filter((child) =>
                    filterMenuItems(child, value)
                );
            }
            return true;
        }
        return false;
    };

    const filterMenuItemsByPermission = (menuItem, roles) => {
        const hasValue = menuItem.key && roles.includes(menuItem.key);
        const hasValueInChildren =
            menuItem.children?.some((child) => filterMenuItemsByPermission(child, roles));

        if (hasValue || hasValueInChildren) {
            if (menuItem.children?.length > 0) {
                menuItem.children = menuItem.children.filter((child) =>
                    filterMenuItemsByPermission(child, roles)
                );
            }
            return true;
        }
        return false;
    };

    const clearFilter = () => {
        handleSearchChange({ target: { value: "", noRefresh: true } });
    };

    const addComponent = (component) => {
        const fields = sidebarTabUI.fields;
        if (fields.length > 8) {
            error("No se pueden tener más de 8 pestañas abiertas");
            return;
        }
        const existing = fields.find((m) => m.key === component.key);
        if (existing) {
            // Ya existe, solo hacemos foco en esa pestaña
            const index = fields.indexOf(existing) + 1;
            setTab(index);
        } else {
            fields.push(component);
            setSidebarTabUI(sidebarTabUI);
            setTab(sidebarTabUI.fields.length);
        }
    };

    const closeComponent = (component) => {
        const indexToRemove = sidebarTabUI.fields.findIndex((m) => m.key === component.key);
        if (indexToRemove !== -1) {
            sidebarTabUI.fields.splice(indexToRemove, 1);
            setSidebarTabUI(sidebarTabUI);
            setTab(sidebarTabUI.fields.length);
        }
    };

    return (
        <Grid
            container
            sx={{
                height: "100%", // Ocupa todo el alto disponible
            }}
        >
            {/* SIDEBAR */}
            {menu && menuVisibility && (
                <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        height: "100%",
                    }}
                >
                    {/*
                      Paper con margen superior e inferior (my)
                      y algo de espacio a los lados (mx),
                      para un aspecto menos pegado.
                    */}
                    <Paper
                        sx={{
                            flex: 1,
                            display: "flex",
                            flexDirection: "column",
                            overflowY: "auto",
                            my: 2,    // margen arriba y abajo
                            mx: 1     // margen a los lados
                        }}
                    >
                        <Box sx={{ px: 2, py: 2 }}>
                            <TextField
                                value={filterText}
                                onChange={handleSearchChange}
                                fullWidth
                                name="Buscar"
                                label="Buscar"
                                placeholder="Buscar..."
                                id="search"
                                sx={{
                                    backgroundColor: "#fff",
                                    borderRadius: 1
                                }}
                            />
                        </Box>

                        <TestSidebar
                            clearFilter={clearFilter}
                            expansible={expansible}
                            setComponent={addComponent}
                            main
                            sidebar={menu}
                        />
                    </Paper>
                </Grid>
            )}

            {/* CONTENIDO PRINCIPAL */}
            <Grid
                item
                xs={12}
                md={menuVisibility ? 10 : 12}
                sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // Margen lateral en el contenido principal
                    px: 2
                }}
            >
                {sidebarTabUI.fields.length ? (
                    <PersistentHorizontalTabList
                        defaultValue={tab}
                        expansible={false}
                        key="mainTabs"
                        sideBarTab={sidebarTabUI}
                        onCloseTab={closeComponent}
                    />
                ) : (
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {(imageSrc && imageSrc.length) && <Box
                            component="img"
                            id="companyImage"
                            src={imageSrc}
                            alt="Logo"
                            sx={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                                objectFit: "contain"
                            }}
                        />}
                    </Box>
                )}
            </Grid>
        </Grid>
    );
}
