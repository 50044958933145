import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";

const getUiTab = (retentionid) =>{
    return ({
        uiTabs : {fields : [
                {label : "IVA", component: <EditObjectDialog objectid={retentionid} entityDefinition={retentionUI}/> },
            ]}
    })
}
export const retentionUI = {
    entity : {
        name: 'retention',
        keyField: 'retentionid',
        label: 'Retención'
    },
    uiTable: {
        fields:{
            retentionid : {name: 'retentionid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3 },
            retention : {name: 'retention', label:'Descripción' , typeName: 'String', editable: true, align: 'center' },
            percentage : {name: 'percentage', label:'Porcentaje' , typeName: 'Number', editable: true },
            purchaseaccountid : {name: 'purchaseaccountid',label: 'Cuenta compras', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
            saleaccountid : {name: 'saleaccountid',label: 'Cuenta ventas', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},

        },
        keyComponent: 'retentionCrudManagement'
    },
    uiForm : {
        fields:{
            retentionid : {name: 'retentionid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true },
            retention : {name: 'retention', label:'Descripción' , typeName: 'String', editable: true },
            percentage : {name: 'percentage', label:'Porcentaje' , typeName: 'Number', editable: true },
            purchaseaccountid : {name: 'purchaseaccountid',label: 'Cuenta compras', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
            saleaccountid : {name: 'saleaccountid',label: 'Cuenta ventas', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
        }
    },
    uiTab: (retention) => getUiTab(retention)
}

