import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import {movementunitUI} from "../Movementunit/movementunitCrud";
import SaveRoundedButton from "../../../../../../View/Button/SaveRoundedButton";
import SuccessButton from "../../../../../../View/Button/SuccessButton";


const ui = {
    fields:{
        productquantity : {name: 'productquantity', label:'Cantidad de producto' , typeName: 'Number', editable: true, size: 3},
        movementunitid : {name: 'movementunitid', label:'Unidad' , typeName: 'es.rbm.model.jpa.Movementunit', editable: true, addObject: movementunitUI, size: 6},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', editable: true, size: 3},
    }
}
export default function NewProductContainerDialog(props) {
    const {open, setOpen, product} = props;
    const [containerid, setContainerid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setContainerid({...record});
        setComplete(complete);
    }

    const addContainer = (containerid, callback) => {
        const toSend = {...containerid, productid: {productid: product.productid}}
        doPost('rest/container/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const containerid1 = props.containerid;
        setContainerid(containerid1 ? containerid1 : {});
    }, [props.containerid])

    const onSuccessClick = (e) => {
        e.preventDefault()
        addContainer(containerid, () => setOpen(!open))
    };

    return(
        <Box sx={{m: 2, mb: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SingleRecordForm ui={ui} records={containerid} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    );
}
