import SignIn from "../Solutions/ERP/src/Login/SignIn";
import ProfileManagement from "../Solutions/ERP/src/Profile/ProfileManagement";
import {accountUI} from "../Solutions/ERP/src/Maintenance/Crud/Account/AccountCrud";
import ObjectManagementView from "../View/Form/CrudForm/ObjectManagementView";
import ERPConfigurationView from "../Solutions/ERP/src/Config/ERPConfigurationView";
import MainSidebar from "../MainSidebar";
import NewMain from "../Solutions/ERP/src/Index/NewMain";
import WorkerTimeControl from "../Solutions/ERP/src/Presence/Timetick/WorkerTimeControl";
import WorkerTimeCodeControl from "../Solutions/ERP/src/Presence/Timetick/WorkerTimeCodeControl";
import UserCalendar from "../View/Calendar/UserCalendar";
import VacationManagement from "../Solutions/ERP/src/Presence/Timetick/VacationManagement";
import WorkerTimeHistory from "../Solutions/ERP/src/Presence/Timetick/WorkerTimeHistory";
import LogoutPage from "../Solutions/ERP/src/Login/LogoutPage";

const routes = [
    {path: "/login", element: <SignIn/>},
    {path: "/logout", element: <LogoutPage />},
    {path: "/", element: <NewMain/>},
    {path: "/main", element: <MainSidebar/>},
    {path: "/profile", element: <ProfileManagement/> },
    {path: "/users", element: <ObjectManagementView entityDefinition={accountUI}/> },
    {path: "/admin/config", element: <ERPConfigurationView/> },
    {path: "/WorkerTimeControl", element: <WorkerTimeControl/> },
    {path: "/WorkerTimeCodeControl", element: <WorkerTimeCodeControl/> },
    {path: "/wtclogin2", element: <SignIn navigateTo={'/WorkerTimeControl'} offLineLogin={true}/> },
    {path: "/wtclogin1", element: <SignIn navigateTo={'/WorkerTimeCodeControl'}/> },
    {path: "/UserCalendar", element: <UserCalendar/> },
    {path: "/WorkerHolidaysManagement", element: <VacationManagement /> },
    {path: "/WorkerTicksHistory", element: <WorkerTimeHistory /> },
]
export default routes;