import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Chip
} from "@mui/material";
import { doGet, doPost } from "../../../../../Utils/Restclient/NetworkActions";
import { useNavigate } from "react-router-dom";
import {DataTable, TotalTime} from "./WorkerTimeControl";

const WorkerTimeHistory = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState(new Date().toISOString().split("T")[0]); // Fecha de hoy por defecto
    const [rows, setRows] = useState([]);
    const [hours, setHours] = useState([]);

    useEffect(() => {
        fetchRecords(date);
    }, [date]);

    const fetchRecords = (selectedDate) => {
        const request = {date: selectedDate};
        doPost('presence/getWorkedDayInfo', request, (result) => {
            if (result) {
                const {hours, records } = result;
                setRows([...records]);
                setHours(hours)
            }
        });
    };
    return (
        <Box sx={{ width: "100%", padding: 2, height: "100vh" }}>
            <AppBar position="static" sx={{ backgroundColor: "white", color: "black" }}>
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Mis marcajes
                    </Typography>
                    <Button color="inherit" onClick={() => navigate("/WorkerTimeControl")}>
                        Volver
                    </Button>
                </Toolbar>
            </AppBar>
            <TextField
                type="date"
                value={date}
                fullWidth
                onChange={(e) => setDate(e.target.value)}
                sx={{ backgroundColor: "white", borderRadius: 1, my: 2 }}
                size="small"
            />
            <DataTable rows={rows} />
            <TotalTime totalTime={hours} />
        </Box>
    );
};

export default WorkerTimeHistory;
