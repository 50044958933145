import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {doGet, doGetDownloadFile, doGetPDF} from "../../../../../../Utils/Restclient/NetworkActions";
import DetailsSaleorderDialog from "./DetailsSaleorderDialog";
import * as React from "react";
import SaleorderSalenoteView from "./SaleorderSalenoteView";
import SaleorderSaleinvoiceView from "./SaleorderSaleinvoiceView";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {customerAddressUI, customerUI} from "../Customer/customerCrud";
import {saleorderstatusUI} from "./saleorderstatusUI";
import {getConstructions} from "../../../Aluminium/Crud/Workbudget/workbudgetCrud";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

const getUiTab = (saleorderid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Pedido de venta", component: <EditObjectDialog objectid={saleorderid} entityDefinition={saleorderUI}/> },
                {label : "Detalles", component: <DetailsSaleorderDialog selected={saleorderid}/> },
                {label : "Facturas", component: <SaleorderSaleinvoiceView saleorderid={saleorderid}/> },
                {label : "Albaránes", component: <SaleorderSalenoteView saleorderid={saleorderid}/> },
            ]}
    })
}

const sendMail = (saleorderid, setOpenMail, setMailRecords) =>{
    const to = saleorderid.email || saleorderid.customerid?.email;
    setMailRecords({to: to, subject: 'PEDIDO: '+ saleorderid.saleorderid + ' : ' + saleorderid.saleorder})
    setOpenMail(true);
}

const getReportOptions = (setOption) => {
    doGet('saleorder/getSaleorderReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    const url = 'saleorder/downloadSaleorderReport/' + selected.saleorderid;
    let fileName = op.label + '_' + selected.saleorderid + op.extension;
    showOrDownloadDocument(url, op, fileName, pdfSet);
}

export const filterCustomerAddresses = (record, callback) =>{
    if (record.customerid) {
        let filters = {
            entity : {name: 'customeraddress'},
            fields: {
                customerid : {name: 'customerid', operator: OPERATORS.OPERATOR_EQUAL, value: record.customerid},
            }
        }
        searchByCriteria(filters, result => {
            if (result && result.length > 0) {
                let toReturn = [...result];
                callback && callback(toReturn)
            } else {
                callback && callback([])
            }
        });
    }
    callback && callback([]);
}

const autoComplete = (record, field, value, callback) => {
    if (false && !record.saleorderid && Object.keys(record).length === 1) {
        doGet("rest/contractor/getAll", records =>{
            if (records.length === 1) {
                const newRecord = {...record, contractorid: records[0]}
                callback && callback(newRecord);
            } else {
                callback && callback()
            }
        })
    } else if (field === 'customerid') {
        const newRecord = {...record};
        newRecord.customeraddressid = null;
        callback && callback({...newRecord});
    } else if (field === 'contractorid') {
        const newRecord = {...record};
        const contractorid = record?.contractorid;
        const filters = {
            entity : { name: 'serie'},
            fields: {
                contractorid : {name: 'contractorid', operator: OPERATORS.OPERATOR_EQUAL, value: contractorid},
                seriesaleorder : {name: 'seriesaleorder', operator: OPERATORS.OPERATOR_EQUAL, value: true},
            },
        }
        searchByCriteria(filters, result => {
            if (result && result.length) {
                newRecord.serie = result[0].serie;
                callback && callback({...newRecord});
            } else {
                callback && callback({...record})
            }
        });
    } else {
        callback && callback({...record})
    }
}

const customerAddressCustomUI = {...customerAddressUI,
    uiForm: {
        fields: {
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer'},
            ...customerAddressUI.uiForm.fields,
        }
    }
}


export const saleorderUI = {
    entity : {
        name: 'saleorder',
        keyField: 'saleorderid',
        label: 'Pedido de venta',
        colorValue: 'saleorderstatusid',
        info: {typeName: 'es.rbm.model.jpa.Saleorder'}
    },
    uiTable: {
        fields:{
            saleorderid : {name: 'saleorderid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.5, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer'},
            customeraddressid : {name: 'customeraddressid', label: 'Dirección' ,typeName: 'es.rbm.model.jpa.Customeraddress', editable: false},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            shippingdate : {name: 'shippingdate', label: 'Fecha de envío' ,typeName: 'Date', optional: true},
            deliverydate : {name: 'deliverydate', label: 'Fecha de entrega' ,typeName: 'Date', optional: true},
            saleorderstatusid : {name: 'saleorderstatusid', label: 'Estado',typeName:'es.rbm.model.jpa.Saleorderstatus', align: 'center'},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center'},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
        },
        actions:{
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            },
            sendEmail:{
                fun: (entity, setOpenMail, setMailRecords) => sendMail(entity, setOpenMail, setMailRecords)
            },
        },
        keyComponent: 'saleorderCrudManagement'
    },
    uiForm : {
        fields:{
            saleorderid : {name: 'saleorderid', label: 'Código' ,typeName: 'String', editable: false, optional: true, size: 2},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', size: 2},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 2, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            saleorder : {name: 'saleorder', label: 'Descripción' ,typeName: 'String', size: 12},
            date : {name: 'date', label: 'Fecha' ,typeName: 'Date'},
            shippingdate : {name: 'shippingdate', label: 'Fecha de envío' ,typeName: 'Date', optional: true, empty: true},
            deliverydate : {name: 'deliverydate', label: 'Fecha de entrega' ,typeName: 'Date', optional: true, empty: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', addObject: customerUI},
            customeraddressid : {name: 'customeraddressid', label: 'Dirección' ,typeName: 'es.rbm.model.jpa.Customeraddress', filterRecords: filterCustomerAddresses, addObject: customerAddressCustomUI},
            saleorderstatusid : {name: 'saleorderstatusid', label: 'Estado' ,typeName: 'es.rbm.model.jpa.Saleorderstatus', addObject: saleorderstatusUI},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center'},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        fields: {
            saleorderid : {name: 'saleorderid', label: 'Nº pedido' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            date : {name: 'date', label: 'Fecha' ,typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            shippingdate : {name: 'shippingdate', label: 'Fecha de envío' ,typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'Number', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            saleorderstatusid : {name: 'saleorderstatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Saleorderstatus', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        }
    },
    uiTab: (saleorder) => getUiTab(saleorder)
}

