import * as React from 'react';

import {useEffect} from "react";
import GridTable from "../../../../../View/Form/GridTable";
import {doGet} from "../../../../../Utils/Restclient/NetworkActions";
import Paper from "@mui/material/Paper";
import SingleRecordForm from "../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import {Chip, IconButton, TextField} from "@mui/material";
import Button from "@mui/material/Button";
import EditTimetickDialog from "./EditTimetickDialog";
import AddIcon from "@mui/icons-material/Add";
import {doDeleteRest} from "../../../../../Utils/Restclient/NetworkRestActions";
import {getRelativeMaxViews} from "../../../../../Utils/Constant";
import Tooltip from "@mui/material/Tooltip";
import Autocomplete from "@mui/material/Autocomplete";

export const uiTimeticks = {
    uiTable: {
        sort: false,
        fields:{},
        keyComponent: 'TimetickDayView',
    },
}

const uiFilters = {
    fields: {
        date : {name: 'date', label: 'Fecha' ,typeName: 'Date', size: 12, height: 'small'}
    }
}

const tyckTypes = {
    1 : 'Presencia',
    2 : 'Salida',
    3 : 'Cambio de posición',
    4 : 'Entrada de parte',
    5 : 'Salida de parte',
}

export default function TimetickDay(props) {
    const [ui, setUI] = React.useState(uiTimeticks);
    const [originalRecords, setOriginalRecords] = React.useState([]);
    const [records, setRecords] = React.useState([]);
    const [filters, setFilters] = React.useState({date: new Date().getTime()});
    const [openEditTimetick, setOpenEditTimetick] = React.useState(false);
    const [selected, setSelected] = React.useState(false);
    const [tags, setTags] = React.useState([]);
    const [options, setOptions] = React.useState([]);

    const handledChange = (filter) =>{
        setFilters({...filter});
    }

    const renderRecord = (result => {
        let maxNumberTick = 0;
        const records = result.reduce((l, r) =>{
            let worker = r.worker;
            let newObject = {worker: worker.workerid, workerid: worker, idcardnumber: worker.idcardnumber, timeworked: r.timeworked,
                status: r.ticks.length === 0 ? 'KO' : 'OK'}
            r.ticks.forEach((tick, i) =>{
                newObject = {...newObject, ["T"+(i+1)]: {
                        timetickid: tick.timetickid,
                        timetick: tick.timetick,
                        timeticktype: tick.timeticktype,
                        workspaceid: tick.workspaceid,
                        latitude: tick.latitude,
                        dataperioddetailid: tick.dataperioddetailid,
                        longitude: tick.longitude,
                        taskid: tick.taskid,
                        remarks: tick.remarks,
                        workerid: tick.workerid}}
            })
            maxNumberTick = r.ticks.length > maxNumberTick ? r.ticks.length : maxNumberTick;
            return [...l, newObject];
        }, [])
        let newUI = {workerid : {name: 'workerid', label: 'Trabajador' ,typeName: 'es.rbm.model.jpa.Worker', align: 'left', editable: false},
            idcardnumber : {name: 'idcardnumber', label: 'DNI' ,typeName: 'String', flex:0.3, editable: false, align: 'center'},
            timeworked : {name: 'timeworked', label: 'Horas de presencia' ,typeName: 'Number', flex:0.3, editable: false}}
        for (let i = 1; i <= maxNumberTick ; i++) {
            const tag = String("T" + i);
            newUI = {...newUI, [tag]: {name: tag, label:tag, hourField: 'timetick', typeName: 'Hour', flex: 0.2, optional: true, renderCell : (v, row, column, apiRef) => {
                if (v.value) {
                    const inCell = new Date(v.value).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit' }).toString();
                    const inTooltip = new Date(v.value).toLocaleTimeString('es-ES', { hour: '2-digit', minute: '2-digit', second: '2-digit' }).toString();
                    const timetick = v.row[v.field];
                    const workerInfo = "Trabajador: " + ((timetick.workerid?.name + " " + timetick.workerid?.surname) || 'Sin trabajador');
                    const taskid = timetick.taskid;
                    const taskInfo = "Tarea: " + (taskid ? "(" + taskid.taskid + ") " + taskid.task : 'Sin tarea');
                    const workspaceid = timetick.workspaceid;
                    const workspaceInfo = "Espacio: " + (workspaceid ? "(" + workspaceid.workspaceid + ") " +  workspaceid.workspace : 'Sin espacio');
                    const timeticktype = timetick.timeticktype;
                    const typeInfo = "Tipo: " + (timeticktype ? "(" + timeticktype + ") " + tyckTypes[timeticktype] : 'Sin tipo');
                    const latitudeInfo = "Latitud: " + (timetick.latitude || '');
                    const longitudeInfo = "Longitud: " + (timetick.longitude || '');
                    const dataperiodInfo = "Parte: " + (timetick.dataperioddetailid?.dataperiodid.dataperiodid || '');
                    const remarksInfo = "Observacines: " + (timetick.remarks || '');
                    const title = <span>{inTooltip}<br/>{workerInfo}<br/>{taskInfo}<br/>{workspaceInfo}<br/>{typeInfo}<br/>{latitudeInfo}<br/>{longitudeInfo}<br/>{dataperiodInfo}<br/>{remarksInfo}<br/></span>;
                    return <Tooltip title={title}>
                        {inCell}
                    </Tooltip>
                }
                return '';
            }}}
        }

        const uiToSet = {...ui}
        uiToSet.uiTable.fields = {...newUI}
        setUI(uiToSet);
        setOriginalRecords(records);
        setRecords(records);
    })

    useEffect(() => {
        if (selected) {
            setOpenEditTimetick(true)
        }
    }, [selected]);

    useEffect(() => {
        const defaultDay = new Date().getTime();
        refresh(defaultDay);
    }, [props, filters]);

    useEffect(() => {
        let idcardnumbers = records.filter(d => d?.workerid?.idcardnumber).map(d => 'DNI: ' + d?.workerid?.idcardnumber);
        let workersNames = records.filter(d => d?.workerid?.name)
            .map(d => 'TRAB: ' + d?.workerid?.name + ' ' +(d?.workerid?.surname || ''));
        let workspaces = Array.from(new Set(
            records.flatMap(worker =>
                Object.values(worker)
                    .filter(entry => entry && typeof entry === "object" && "workspaceid" in entry && entry.workspaceid !== null)
                    .map(entry => 'ESP: ' + entry?.workspaceid?.workspace)
            )
        ));
        let tasks = Array.from(new Set(
            records.flatMap(worker =>
                Object.values(worker)
                    .filter(entry => entry && typeof entry === "object" && "taskid" in entry && entry.taskid !== null)
                    .map(entry => 'TAR: ' + entry?.taskid?.task)
            )
        ));
        setOptions([...idcardnumbers, ...workersNames, ...workspaces, ...tasks])
    }, [ui]);

    const refresh = (day) => {
        let date = filters?.date? filters.date : new Date().getTime();
        let isValidDate = Date.parse(date)
        let dateTicks;
        if (isValidDate && Object.keys(date).length>0){
            dateTicks = date;
        } else if(day){
            dateTicks = day;
        } else{
            dateTicks = Date.parse(new Date());
        }
        doGet("presence/getTimetickBySubjectGroup/" + dateTicks, result => {
            renderRecord(result);
        })
    }

    const addNewTimetick = () => {
        setSelected(null);
        setOpenEditTimetick(true);
    }

    const onCellDoubleClick = (v, e) => {
        const value = v.row[v.field];
        if (value) {
            if (!value.timetickid) {
                setSelected({workerid: value})
            } else {
                setSelected(value)
            }

        }
    }
    const onCellKeyDown = (v, e) => {
        if (e.code.toLowerCase() === 'delete' && v.field.startsWith('T') && v.field.length < 5) {
            doDeleteRest('timetick', v.row[v.field].timetickid, () => refresh())
        } else if (e.code.toLowerCase() === 'enter'  && v.field.startsWith('T') && v.field.length < 5) {
            setSelected(v.row[v.field])
        }
    }

    const getColorCell = (params, colorCell) => {
        const notNull = params.row[params.field];
        if (params.field === 'timeworked' && params.row.status === 'OK') {
            return !notNull ? 'color--silver' : '';
        }
        if (notNull && notNull[colorCell]) {
            return 'color--cell--TimetickDayView--' + notNull[colorCell]
        }
    }

    const onClose = () => {
        setOpenEditTimetick(false);
        setSelected(null)
        const defaultDay = new Date().getTime();
        refresh(defaultDay);
    }

    const onChangeTags = (event, newValue) => {
        setTags(newValue);
        if (newValue.length) {
            const filters = newValue.reduce((acc, item) => {
                const [key, value] = item.split(":").map(s => s.trim());
                acc[key] = acc[key] || [];
                acc[key].push(value);
                return acc;
            }, {});

            let filteredRecords = originalRecords
                .map(worker => {
                    let filteredWorker = { ...worker };
                    const matchesDNI = filters.DNI ? filters.DNI.includes(worker.idcardnumber) : false;
                    const matchesTRAB = filters.TRAB ? filters.TRAB.includes(worker.workerid.name + ' ' + (worker.workerid.surname || '')) : false;
                    let parentFilter = matchesDNI || matchesTRAB;
                    Object.keys(filteredWorker)
                        .filter(key => /^T\d+$/.test(key))
                        .forEach(key => {
                            const entry = filteredWorker[key];

                            if (entry && typeof entry === "object") {
                                const specificFilters = filters.ESP || filters.TAR;
                                const matchesEspMarcaje = filters.ESP ? (entry.workspaceid && filters.ESP.includes(entry.workspaceid.workspace)): false;
                                const matchesTarMarcaje = filters.TAR ? (entry.taskid && filters.TAR.includes(entry.taskid.task)): false;
                                if ((!matchesEspMarcaje && !matchesTarMarcaje) && (specificFilters || !parentFilter)) {
                                    filteredWorker[key] = null;
                                }
                            }
                        });

                    const hasValidEntries = Object.keys(filteredWorker)
                        .some(key => /^T\d+$/.test(key) && filteredWorker[key] !== null);

                    return hasValidEntries ? filteredWorker : null;
                })
                .filter(worker => worker !== null);

            setRecords(filteredRecords);
        } else {
            setRecords(originalRecords);
        }
    }

    return (
        <Paper sx={{ width: '100%', height: getRelativeMaxViews(), pt: 1, mt:1}}>
            <EditTimetickDialog onClose={onClose} open={openEditTimetick} selected={selected}/>
            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item md={12}>
                    <Paper sx={{backgroundColor: 'white', m:2, p: 2}}>
                        <Grid container direction="row" justify="flex-end" alignItems="center" spacing={0.5}>
                            <Grid item md={2}>
                                <Button size={'small'} variant="contained" onClick={addNewTimetick} startIcon={<AddIcon />} sx={{ml: 3}}> Añadir marcaje </Button>
                            </Grid>
                            <Grid item md={7}>
                                <Autocomplete
                                    multiple
                                    options={options}
                                    value={tags}
                                    size={'small'}
                                    limitTags={2}
                                    onChange={onChangeTags}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => <TextField {...params} label="Buscar..." />}
                                />
                            </Grid>
                            <Grid item md={0.2}/>
                            <Grid item md={2} align={'center'}>
                                <SingleRecordForm ui={uiFilters} records={filters} sx={{pr: 1}} onChange={handledChange}/>
                            </Grid>
                            <Grid item md={0.1}/>
                            <Grid item md={0.5} align={'center'} sx={{mt:0}}>
                                <IconButton onClick={() => refresh()} color={'primary'} variant="contained" size="large">
                                    <RefreshIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item md={12}>
                    <GridTable ui={ui.uiTable} sx={{mt:0, mb: 3, mx: 2, height: '68vh', backgroundColor: 'white'}}
                               records={records} rowId={'idcardnumber'} refreshUI={true}
                               colorCell={'timeticktype'} toolBar
                               colorRow={'status'} getCellColor={getColorCell}
                               density={'compact'} onCellDoubleClick={onCellDoubleClick}
                               hideFooter onCellKeyDown={onCellKeyDown} />
                </Grid>
            </Grid>
        </Paper>
    )

}
