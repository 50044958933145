import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {
    doGet,
    doPost,
} from "../../../../../../Utils/Restclient/NetworkActions";
import DetailsSalenoteDialog from "./DetailsSalenoteDialog";
import * as React from "react";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

const getUiTab = (salenoteid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Albarán de venta", component: <EditObjectDialog objectid={salenoteid} entityDefinition={salenoteUI}/> },
                {label : "Detalles", component: <DetailsSalenoteDialog selected={salenoteid}/> },
            ]}
    })
}

const filterOrders = (records, callback) => {
    if (records.customerid) {
        const toSend = {customerid: records.customerid};
        doPost('salenote/getPendingOrders', toSend, r => {
            callback && callback(r);
        })
    } else {
        callback && callback([])
    }
}

const getReportOptions = (setOption) => {
    doGet('salenote/getSalenoteReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    let url = 'salenote/downloadSalenoteReport/' + selected.salenoteid;
    let fileName = op.label + '_' + selected.salenoteid + '.pdf';
    showOrDownloadDocument(url, op, fileName, pdfSet);
}

export const filterCustomerAddresses = (record, callback) =>{
    if (record.customerid) {
        let filters = {
            entity : {name: 'customeraddress'},
            fields: {
                customerid : {name: 'customerid', operator: OPERATORS.OPERATOR_EQUAL, value: record.customerid},
            }
        }
        searchByCriteria(filters, result => {
            if (result && result.length > 0) {
                let toReturn = [...result];
                callback && callback(toReturn)
            } else {
                callback && callback([])
            }
        });
    } else if (record.customerid) {
        let filters = {
            entity : {name: 'customeraddress'},
            fields: {
                customerid : {name: 'customerid', operator: OPERATORS.OPERATOR_EQUAL, value: record.customerid},
            }
        }
        searchByCriteria(filters, result => {
            if (result && result.length > 0) {
                let toReturn = [...result];
                callback && callback(toReturn)
            } else {
                callback && callback([])
            }
        });
    }
    callback && callback([]);
}

const autoComplete = (record, field, value, callback) => {
    if (false && !record.salenoteid && Object.keys(record).length === 1) {
        doGet("rest/contractor/getAll", records =>{
            if (records.length === 1) {
                const newRecord = {...record, contractorid: records[0]}
                callback && callback(newRecord);
            } else {
                callback && callback()
            }
        })
    } else {
        if (field === 'saleorderid' && record.saleorderid) {
            const newRecord = {...record};
            newRecord.customeraddressid = record.saleorderid.customeraddressid;
            callback && callback({...newRecord});
        } else if (field === 'contractorid') {
            const newRecord = {...record};
            const contractorid = record?.contractorid;
            const filters = {
                entity : { name: 'serie'},
                fields: {
                    contractorid : {name: 'contractorid', operator: OPERATORS.OPERATOR_EQUAL, value: contractorid},
                    seriesalenote : {name: 'seriesalenote', operator: OPERATORS.OPERATOR_EQUAL, value: true},
                },
            }
            searchByCriteria(filters, result => {
                if (result && result.length) {
                    newRecord.serie = result[0].serie;
                    callback && callback({...newRecord});
                } else {
                    callback && callback({...record})
                }
            });
        } else {
            callback && callback({...record})
        }
    }
}

export const salenoteUI = {
    entity : {
        name: 'salenote',
        keyField: 'salenoteid',
        label: 'Albarán de venta',
        colorRow: 'saleinvoiceid',
    },
    uiTable: {
        fields:{
            salenoteid : {name: 'salenoteid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.5, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            salenote : {name: 'salenote', label: 'Albarán',typeName:'String'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer'},
            customeraddressid : {name: 'customeraddressid', label: 'Dirección' ,typeName: 'es.rbm.model.jpa.Customeraddress', filterRecords: filterCustomerAddresses},
            signature : {name: 'signature', label:'Firmado' , typeName: 'Boolean', flex: 0.5, editable: false},
            saleinvoiceid : {name: 'saleinvoiceid', label:'Factura' , typeName: 'es.rbm.model.jpa.Saleinvoice', flex: 0.5},
            saleorderid : {name: 'saleorderid', label:'Pedido' , typeName: 'es.rbm.model.jpa.Saleorder', flex: 0.8, align: 'center'},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
        },
        actionFlex: 0.5,
        actions:{
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            }
        },
        keyComponent: 'salenoteCrudManagement'
    },
    uiForm : {
        fields:{
            salenoteid : {name: 'salenoteid', label:'Cod.' , typeName: 'Number', editable: false, size: 1, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false, size: 1, optional: true},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 1, optional: true},
            contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: false, size: 9, optional: true},
            date : {name: 'date', label: 'Fecha',typeName:'Date', size: 3},
            salenote : {name: 'salenote', label: 'Descripción',typeName:'String', optional: true, size: 9},
            customerid : {name: 'customerid', label:'Cliente' , typeName: 'es.rbm.model.jpa.Customer', size: 6},
            saleorderid : {name: 'saleorderid', label:'Pedido' , typeName: 'es.rbm.model.jpa.Saleorder', size: 6, align: 'center', filterRecords: filterOrders},
            customeraddressid : {name: 'customeraddressid', label: 'Dirección' ,typeName: 'es.rbm.model.jpa.Customeraddress', filterRecords: filterCustomerAddresses, optional: true},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        fields: {
            salenoteid : {name: 'salenoteid', label: 'Nº albarán' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        }
    },
    uiTab: (salenote) => getUiTab(salenote)
}

