import * as React from 'react';
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import {getRelativeMaxViews} from "../../../../../../Utils/Constant";
import Grid from "@mui/material/Grid";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {doPost, doPostFile} from "../../../../../../Utils/Restclient/NetworkActions";
import {Stack} from "@mui/material";
import {info} from "../../../../../../Utils/Notification/notifications";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}

const colSpan = (value, row) => {
    let debit = row.debit;
    if (!row.accountingentryid && (!debit && debit !== 0)) {
        return Object.keys(ui.fields).length;
    } else if (!row.accountingentryid && (debit || debit === 0)) {
        return Object.keys(ui.fields).length - 4;
    }
    return undefined;
};



const ui = {
    fields: {
        //id : {name: 'id', label: '#',typeName:'Number', flex: 0.5, align: 'left', optional: false, editable: false, visible: false},
        date : {name: 'date', label: 'Fecha',typeName:'Date', flex: 0.3, align: 'center', optional: false, editable: false},
        concept : {name: 'concept', label: 'Concepto',typeName:'String', flex: 0.7, align: 'left', optional: false, editable: false, colSpan: colSpan},
        accountingentryid : {name: 'accountingentryid', label: 'Asiento', typeName:'es.rbm.model.jpa.Accountingentry', flex: 0.3, align: 'left', optional: false, editable: true},
        debit : {name: 'debit', label: 'Debe',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        credit : {name: 'credit', label: 'Haber',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        balance : {name: 'balance', label: 'Saldo',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
    },
    keyComponent: 'AccountingExtractGrid',
    disableSorted: true
}

const formUI =  {
    fields: {
        since : {name: 'since', label:'Desde' , typeName: 'Date', editable: true, autofocus: true, size: 3},
        until : {name: 'until', label:'Hasta' , typeName: 'Date', editable: true, size: 3},
        contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center', size: 6},
        accountingaccountid : {name: 'accountingaccountid', label:'Cuentas' , typeName: 'es.rbm.model.jpa.Accountingaccount', height: 'small', size: 12, isMulti: true, limitTag: 4},
    }
}

const currentYear = new Date().getFullYear();
const firstDayOfYear = new Date(currentYear, 0, 1);
const lastDayOfYear = new Date(currentYear, 11, 31);

const initialState = {filter: {since: firstDayOfYear, until: lastDayOfYear}, records: []};

export default function AccountingExtract() {
    const [state, setState] = React.useState({...initialState});
    const [gridRef, setGridRef] = React.useState(null);
    const {filter, records, complete} = state;


    const filterChange = (filter, complete) => {
        setState({...state, filter, complete, records: complete ? records : []});
    }

    const updateRecords = () => {
        const request = {...filter}
        doPost("accounting/getAccountingExtract", request, result => {
            if (result) {
                let newRecords = [...result.records];
                if (!newRecords.length) {
                    info('No existen datos para las cuentas seleccionadas');
                }
                setState({...state, records: newRecords})
            }
        })
    }

    const downloadReport = () => {
        const csvString = gridRef.current.getDataAsCsv();
        doPostFile("utils/convertCsvToExcel", [csvString],response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Extracto_' + filter?.contractorid?.contractor + '.xlsx';
                alink.click();
            })
        },false)
    }

    const getRowColor = (data) => {
        return 'color--' + data?.row?.color ;
    }

    let relativeMaxViews = getRelativeMaxViews(-9);
    return(
        <Box sx={{m: 2}}>
            <Grid container spacing={2}>
                <Grid item md={10}>
                    <SingleRecordForm ui={formUI} records={filter} onChange={filterChange} disableAutoFocus/>
                </Grid>
                <Grid item md={2} align={'center'} maxHeight>
                    <Stack spacing={2} direction={'column'} sx={{mt: 0.5}}>
                        <SuccessButton text={'Actualizar'} onClick={updateRecords} disabled={!complete}/>
                        <SuccessButton text={'Descargar'} onClick={downloadReport} disabled={!complete || !records?.length} color={'secondary'}/>
                    </Stack>
                </Grid>
            </Grid>
            <GridTable ui={ui} setGridRef={setGridRef}
                       records={records} rowId={'id'}
                       density={'compact'} rowColorFunc={getRowColor}
                       sx={{height: relativeMaxViews, bgcolor: '#ffffff', mt: 2}}
            />
        </Box>
    )
}
