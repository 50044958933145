import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box, SpeedDial, SpeedDialAction, Fab,
} from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import StopIcon from "@mui/icons-material/Stop";
import {doPost} from "../../../../../Utils/Restclient/NetworkActions";
import {useNavigate} from "react-router-dom";
import VacationIcon from "@mui/icons-material/BeachAccess"; // Ícono de vacaciones
import HistoryIcon from "@mui/icons-material/History"; // Ícono para futuros reportes
import MenuIcon from '@mui/icons-material/Menu';
import RefreshIcon from "@mui/icons-material/Refresh";
import {
    clearFichajes,
    getAllFichajes,
    getSyncedFichajes,
    removeFichajes,
    saveFichaje,
    saveSyncedFichajes
} from "./TicksDB";
import {error, info, warningNotification} from "../../../../../Utils/Notification/notifications";
import {format} from "date-fns";
import warning from "react-redux/es/utils/warning";


export const headerCellStyles = { fontSize: { xs: "0.75rem", sm: "0.875rem", md: "1rem" } };
export const cellStyles = { fontSize: { xs: "0.60rem", sm: "0.875rem", md: "1rem" } };

export const DataTable = ({ rows }) => (
    <TableContainer component={Paper} sx={{
        display: "flex",
        flexDirection: "column",
        overflow: "hidden"
    }}>
        <Table stickyHeader>
            <TableHead>
                <TableRow>
                    <TableCell sx={headerCellStyles}>Fecha y Hora</TableCell>
                    <TableCell sx={headerCellStyles}>Acción</TableCell>
                    <TableCell sx={headerCellStyles}>Usuario</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow key={index}>
                        <TableCell sx={cellStyles}>{row.timetick}</TableCell>
                        <TableCell sx={cellStyles}>{row.type}</TableCell>
                        <TableCell sx={cellStyles}>{row.worker}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
);

export const TotalTime = ({ totalTime }) => (
    <Box textAlign="center" mt={2}>
        <Typography variant="h6">Tiempo Total: {totalTime}</Typography>
    </Box>
);


const WorkerTimeControl = () => {
    const [state, setState] = useState({working: false, records: []});
    const [location, setLocation] = useState(null);
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
    const [showRefresh, setShowRefresh] = useState(true);

    const {working, hours, records} = state;
    const navigate = useNavigate();

    useEffect(() => {
        locationConfig();
        refresh();
    }, []);

    useEffect(() => {
        window.addEventListener('online', trySync);
        trySync();
        return () => window.removeEventListener('online', trySync);
    }, []);

    const trySync = async () => {
        const pending = await getAllFichajes();
        if (!Array.isArray(pending) || pending.length === 0 || !navigator.onLine) return;

        const successfullySent = [];

        for (const pendingTick of pending) {
            const success = await new Promise((resolve) => {
                doPost('presence/doTimetick', pendingTick, () => resolve(true), null, null,
                    {hideError: true, onError: () => {
                            // TODO onERROR
                        }});
            });
            if (success) successfullySent.push(pendingTick);
        }

        if (successfullySent.length > 0) {
            await removeFichajes(successfullySent);
        }
        refresh();
    };


    const refresh = () => {
        const request = {date: new Date()};
        doPost('presence/getWorkedDayInfo', request, (result) => {
            if (result) {
                const { working, hours, records } = result;
                setState({ ...state, records, working, hours });
                saveSyncedFichajes(records);
            }
        }, null, null, {hideError: true, offlineMode: true, onError: () => {
                warningNotification("Modo sin conexión");
                const loadFichajes = async () => {
                    try {
                        const [pendingTicks, loaded] = await Promise.all([
                            getAllFichajes(),
                            getSyncedFichajes()
                        ]);

                        let toSet = Array.isArray(loaded) ? [...loaded] : [];
                        toSet = toSet.sort((a, b) => b.id - a.id);

                        if (Array.isArray(pendingTicks) && pendingTicks.length > 0) {
                            let workerName = '-';
                            if (toSet?.length) {
                                workerName = toSet[0].worker;
                            }
                            const offlineRecords = pendingTicks.sort((a, b) => b.id - a.id).map(tick => ({
                                worker: workerName,
                                type: 'Pendiente',
                                timetick: format(new Date(tick.date), "dd/MM/yyyy HH:mm")
                            }));
                            toSet = [...toSet, ...offlineRecords];
                        }

                        const currentWorking = (toSet.length % 2)

                        setState(prevState => ({
                            ...prevState,
                            records: toSet, working: currentWorking
                        }));
                    } catch (error) {
                        console.error("Error loading fichajes:", error);
                    }
                };
                loadFichajes();

            }});
    }

    const locationConfig = () => {
        const requestLocation = () => {
            if (navigator.geolocation) {
                navigator.permissions?.query({ name: "geolocation" }).then((result) => {
                    if (result.state === "granted" || result.state === "prompt") {
                        navigator.geolocation.getCurrentPosition(
                            (position) => {
                                setLocation({
                                    latitude: position.coords.latitude,
                                    longitude: position.coords.longitude
                                });
                                console.log("Ubicación obtenida:", position.coords);
                            },
                            (error) => {
                                console.error("Error obteniendo ubicación:", error);
                            },
                            { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
                        );
                    } else {
                        console.error("Permiso de geolocalización denegado");
                    }
                }).catch(() => {
                    navigator.geolocation.getCurrentPosition(
                        (position) => {
                            setLocation({
                                latitude: position.coords.latitude,
                                longitude: position.coords.longitude
                            });
                            console.log("Ubicación obtenida:", position.coords);
                        },
                        (error) => {
                            console.error("Error obteniendo ubicación:", error);
                        },
                        { enableHighAccuracy: true, timeout: 10000, maximumAge: 0 }
                    );
                });
            } else {
                console.error("Geolocalización no soportada en este navegador");
            }
        };

        requestLocation();

        const interval = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString());
        }, 1000);

        return () => clearInterval(interval);
    }

    const saveOfflineTick = (request) => {
        let dateTick = new Date();
        request.date = dateTick.getTime();
        saveFichaje(request);
        let worker = '-';
        if (records.length) {
            worker = records[0].worker;
        }
        const newRecords = {worker: worker, type: 'Pendiente', timetick: format(dateTick, "dd/MM/yyyy HH:mm")};
        setState(prevState => ({...prevState, working: !prevState.working, records: [...prevState.records, newRecords]}))
        info("Fichaje guardado offline. Se enviará cuando vuelvas a estar online.");
    }

    const onClickButton = () => {
        const request = {latitude: location?.latitude, longitude: location?.longitude};
        if (!navigator.onLine) {
            saveOfflineTick(request);
            return;
        } else {
            doPost('presence/doTimetick', request, result => {
                if (result) {
                    const {working, hours, records} = result;
                    setState({...state, records: records, working: working, hours: hours})
                }
            }, null, null, {hideError: true, onError: response => {
                    if (response?.status === 500) {
                        response.text().then(message => error(message))
                    } else {
                        saveOfflineTick(request)
                    }
                }})
        }
    };

    const actions = [
        { icon: <VacationIcon />, name: "Vacaciones", action: () => navigate("/WorkerHolidaysManagement") },
        { icon: <HistoryIcon />, name: "Historial", action: () => navigate("/WorkerTicksHistory") }
    ];

    return (
        <Box sx={{ width: "100%", padding: 2}}>
            <AppBar position="static" sx={{ backgroundColor: "white", color: "black" }}>
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Registro Horario
                    </Typography>
                    <Typography variant="h6">{currentTime}</Typography>
                </Toolbar>
            </AppBar>
            <Box textAlign="center" mt={4}>
                <IconButton
                    size="large"
                    sx={{
                        backgroundColor: working ? "red" : "green",
                        color: "white",
                        padding: 2,
                        minWidth: 56,
                        minHeight: 56,
                        '&:hover': {
                            backgroundColor: working ? "darkred" : "darkgreen"
                        }
                    }}
                    onClick={onClickButton}
                >
                    {working ? <StopIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
                </IconButton>
            </Box>
            <Box sx={{mt: 2}}>
                <DataTable rows={records} />
                <TotalTime totalTime={hours} />
            </Box>

            {showRefresh && (
                <Fab
                    size="medium"
                    color="secondary"
                    sx={{
                        position: "fixed",
                        bottom: 80,
                        right: 20,
                        opacity: 0.9, // Ajusta la transparencia
                        transition: "opacity 0.3s", // Para que no sea brusco
                        "&:hover": { opacity: 1 } // Opacidad total al pasar el mouse
                    }}
                    onClick={refresh}
                >
                    <RefreshIcon />
                </Fab>
            )}

            <SpeedDial
                ariaLabel="Opciones"
                sx={{ position: "fixed", bottom: 16, right: 16 }}
                icon={<MenuIcon />}
                onOpen={() => setShowRefresh(false)}
                onClose={() => setShowRefresh(true)}
            >
                {actions.map((action) => (
                    <SpeedDialAction
                        key={action.name}
                        icon={action.icon}
                        tooltipOpen
                        tooltipTitle={action.name}
                        onClick={action.action}
                    />
                ))}
            </SpeedDial>
        </Box>
    );
};

export default WorkerTimeControl;
