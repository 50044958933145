import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import * as React from "react";
import DetailsSaleInvoiceDialog from "./DetailsSaleInvoiceDialog";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {saleinvoicestatusUI} from "./saleinvoicestatusUI";
import {doGet} from "../../../../../../Utils/Restclient/NetworkActions";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import SaleAccountingDialog from "./SaleAccountingDialog";

const getUiTab = (saleinvoiceid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Factura", component: <EditObjectDialog objectid={saleinvoiceid} entityDefinition={saleinvoiceUI}/> },
                {label : "Detalles", component: <DetailsSaleInvoiceDialog saleinvoiceid={saleinvoiceid}/>},
                {label : "Contabilidad/Tesorería", component: <SaleAccountingDialog saleinvoiceid={saleinvoiceid}/>},
            ]}
    })
}

const autoComplete = (record, field, value, callback) =>{
    if (false && !record.saleinvoiceid && Object.keys(record).length === 1) {
        doGet("rest/contractor/getAll", records =>{
            if (records.length === 1) {
                let contractorid = records[0];
                const newRecord = {...record, contractorid: contractorid}
                newRecord.serie = contractorid?.seriesaleinvoice;
                callback && callback(newRecord);
            } else {
                callback && callback()
            }
        })
    } else {
        const valuesToChange = ['address', 'email', 'idcardnumber', 'phone', 'stateid', 'townid', 'countryid', 'paymentmethodid']
        if (field === 'customerid') {
            const newRecord = {...record};
            const customerid = record.customerid;
            valuesToChange.forEach(value => {
                newRecord[value] = customerid ? customerid[value] : null;
            })
            newRecord.name = customerid.customer
            newRecord.postalcode = customerid.postalcode
            callback && callback({...newRecord});
        } else if (field === 'contractorid') {
            const newRecord = {...record};
            const contractorid = record?.contractorid;
            const filters = {
                entity : { name: 'serie'},
                fields: {
                    contractorid : {name: 'contractorid', operator: OPERATORS.OPERATOR_EQUAL, value: contractorid},
                    seriesaleinvoice : {name: 'seriesaleinvoice', operator: OPERATORS.OPERATOR_EQUAL, value: true},
                },
            }
            searchByCriteria(filters, result => {
                if (result && result.length) {
                    newRecord.serie = result[0].serie;
                    callback && callback({...newRecord});
                } else {
                    callback && callback({...record})
                }
            });
        } else {
            callback && callback({...record})
        }
    }

}

const getReportOptions = (setOption) => {
    doGet('saleinvoice/getSaleinvoiceReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    let url = 'saleinvoice/downloadSaleinvoiceReport/' + selected.saleinvoiceid;
    let fileName = op.label + '_' + selected.saleinvoiceid + '.pdf';
    showOrDownloadDocument(url, op, fileName, pdfSet)
}

export const saleinvoiceUI = {
    entity : {
        name: 'saleinvoice',
        keyField: 'saleinvoiceid',
        label: 'Factura de venta',
        colorValue: 'saleinvoicestatusid',
        //info: {typeName: 'es.rbm.model.jpa.Saleinvoice'}
    },
    uiTable: {
        fields:{
            saleinvoiceid : {name: 'saleinvoiceid', label:'#' , typeName: 'Number', editable: false,flex:0.3, optional: true, visible: false},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.5, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa', typeName:'es.rbm.model.jpa.Contractor'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            accountingdate : {name: 'accountingdate', label: 'Fecha contable', typeName:'Date', optional: true},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer'},
            saleinvoicestatusid : {name: 'saleinvoicestatusid', label: 'Estado', typeName:'es.rbm.model.jpa.Saleinvoicestatus', align: 'center'},
            name : {name: 'name', label:'Nombre' , typeName: 'String', align: 'center'},
            idcardnumber : {name: 'idcardnumber', label:'NIF' , typeName: 'String', align: 'center'},
            address : {name: 'address', label:'Dirección' , typeName: 'String', align: 'center'},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', flex: 0.8, align: 'center'},
            stateid : {name: 'stateid', label:'Provinvia' , typeName: 'es.rbm.model.jpa.State', flex: 0.8, align: 'center'},
            countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country', flex: 0.8, align: 'center'},
            postalcode : {name: 'postalcode', label:'Código postal' , typeName: 'String', align: 'center'},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', flex: 0.8, align: 'center'},
            email : {name: 'email', label:'Correo' , typeName: 'String', flex: 0.8, align: 'center'},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', flex: 0.8},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            retentionid : {name: 'retentionid', label:'Retención' , typeName:'es.rbm.model.jpa.Retention', optional: true},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', flex: 0.8},
        },
        actions:{
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            },
        },
        actionFlex: 1,
        keyComponent: 'saleinvoiceCrudManagement'
    },
    uiForm : {
        fields:{
            saleinvoiceid : {name: 'saleinvoiceid', label:'Cod.' , typeName: 'Number', editable: false, size: 2, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', size: 2},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 2, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor', size: 6},
            date : {name: 'date', label: 'Fecha',typeName:'Date', size: 3},
            accountingdate : {name: 'accountingdate', label: 'Fecha contable',typeName:'Date', size: 3, optional: true, empty: true},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer', size: 6},
            saleinvoicestatusid : {name: 'saleinvoicestatusid', label: 'Estado' ,typeName: 'es.rbm.model.jpa.Saleinvoicestatus', addObject: saleinvoicestatusUI, size: 4.5},
            name : {name: 'name', label:'Nombre' , typeName: 'String', size: 7.5},
            idcardnumber : {name: 'idcardnumber', label:'NIF' , typeName: 'String'},
            address : {name: 'address', label:'Dirección' , typeName: 'String'},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', flex: 0.8, align: 'center'},
            stateid : {name: 'stateid', label:'Provinvia' , typeName: 'es.rbm.model.jpa.State', flex: 0.8, align: 'center'},
            countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country', flex: 0.8, align: 'center'},
            postalcode : {name: 'postalcode', label:'Código postal' , typeName: 'String'},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', flex: 0.8},
            email : {name: 'email', label:'Correo' , typeName: 'String', flex: 0.8},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName: 'es.rbm.model.jpa.Paymentmethod', flex: 0.8},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            retentionid : {name: 'retentionid', label:'Retención' , typeName:'es.rbm.model.jpa.Retention', optional: true},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', flex: 0.8, optional: true,size: 12 , multiline: true, rows: 3},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        initialsValues: {
            date_1: new Date(new Date().setDate(new Date().getDate() - 7))
        },
        fields: {
            //saleinvoiceid : {name: 'saleinvoiceid', label: 'Nº factura' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL},
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            date : {name: 'date',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            accountingdate : {name: 'accountingdate',label: 'Fecha contable', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            date_1 : {name: 'date_1',label: 'Desde', typeName: 'Date', operator: OPERATORS.OPERATOR_GTE, empty: true, optional: true},
            date_2 : {name: 'date_2',label: 'Hasta', typeName: 'Date', operator: OPERATORS.OPERATOR_LTE, empty: true, endDay: true, optional: true},
            saleinvoicestatusid : {name: 'saleinvoicestatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Saleinvoicestatus', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            name : {name: 'name',label: 'Nombre', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            idcardnumber : {name: 'idcardnumber',label: 'NIF', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            email : {name: 'email',label: 'Telefono', typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
        }
    },
    uiTab: (saleinvoice) => getUiTab(saleinvoice)
}

