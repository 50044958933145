import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import DataperiodDocumentView from "./DataperiodDocumentView";
import DataperioddetailsDialog from "./DataperioddetailsDialog";
import DataperioconsumptionDialog from "../../../Agro/Crud/Dataperiod/DataperioconsumptionDialog";
import DataperioexecutionDialog from "../../../Agro/Crud/Dataperiod/DataperioexecutionDialog";
import EntityCollectionDialog from "../../../../../../View/Form/CrudForm/EntityCollectionDialog";
import {doGet} from "../../../../../../Utils/Restclient/NetworkActions";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";

const getUiTab = (dataperiodid) => {
    return ({
        uiTabs : {fields : [
                {label : "Parte de trabajo", component: <EditObjectDialog objectid={dataperiodid} entityDefinition={dataperiodUI}/> },
                {label : "Trabajadores", component: <DataperioddetailsDialog selected={dataperiodid}/> },
                {label : "Consumos", component: <EntityCollectionDialog parentid={dataperiodid} info={dataperiodConsumptionInfo} /> },
                {label : "Elaboraciones", component: <DataperioexecutionDialog selected={dataperiodid}/>},
                {label : "Documentos", component: <DataperiodDocumentView dataperiodid={dataperiodid}/> },
            ]}
    })
}


export const dataperiodConsumptionUI = {
    entity : {
        name: 'dataperiodconsumption',
        keyField: 'dataperiodconsumptionid',
        label: 'dataperiodconsumptionid',
        info: {typeName: 'es.rbm.model.jpa.Dataperiodconsumption'}
    },
    uiTable: {
        fields : {
            consumptionid : {name: 'consumptionid', label: '#',typeName:'Number', editable: false, flex: 0.3},
            productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', editable: false},
            warehouseid : {name: 'warehouseid', label: 'Almacén', typeName:'es.rbm.model.jpa.Warehouse', flex: 0.3},
            //batchid : {name: 'batchid', label: 'Lote', typeName:'es.rbm.model.jpa.Batch', flex: 0.3},
            quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', flex: 0.3},
            movementunitid : {name: 'movementunitid', label: 'Unidad de movimiento',typeName:'es.rbm.model.jpa.Movementunit', flex: 0.3},
            cost : {name: 'cost', label: 'Coste',typeName:'Number', flex: 0.3},
        },
        keyComponent: 'dataperiodConsumptionCrudManagement',
    },
    uiForm : {
        fields:{
            productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', editable: false},
            warehouseid : {name: 'warehouseid', label: 'Almacén', typeName:'es.rbm.model.jpa.Warehouse', flex: 0.3},
            //batchid : {name: 'batchid', label: 'Lote', typeName:'es.rbm.model.jpa.Batch', flex: 0.3},
            quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', flex: 0.3},
            movementunitid : {name: 'movementunitid', label: 'Unidad de movimiento',typeName:'es.rbm.model.jpa.Movementunit', flex: 0.3},
        }
    },
}

export const dataperiodConsumptionInfo = {
    childrentypeName: 'es.rbm.model.jpa.Dataperiodconsumption',
    parentTypeName: 'es.rbm.model.jpa.Dataperiod',
    ...dataperiodConsumptionUI
}

const getReportOptions = (setOption) => {
    doGet('dataperiod/getDataperiodReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    let url = 'dataperiod/downloadDataperiodReport/' + selected.dataperiodid;
    let fileName = op.label + '_' + selected.dataperiodid + '.pdf';
    showOrDownloadDocument(url, op, fileName, pdfSet)
}

export const dataperiodUI = {
    entity : {
        name: 'dataperiod',
        keyField: 'dataperiodid',
        label: 'Parte de trabajo',
        info: {typeName: 'es.rbm.model.jpa.Dataperiod'},
    },
    uiTable: {
        fields:{
            dataperiodid : {name: 'dataperiodid', label:'Nº' , typeName: 'Number', editable: false,flex:0.3, align: 'center', optional: true},
            dataperiod : {name: 'dataperiod', label: 'Descripción',typeName:'String', optional: true},
            responsibleid : {name: 'responsibleid', label: 'Responsable',typeName:'es.rbm.model.jpa.Worker', optional: false, urlRecords: 'worker/getResponsibles'},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer', optional: false},
            since : {name: 'since', label: 'Desde',typeName:'Date', optional: false},
            until : {name: 'until', label: 'Hasta',typeName:'Date', optional: false},
            externalcode : {name: 'externalcode', label: 'Codigo externo',typeName:'String', optional: true},
        },
        keyComponent: 'DataperiodCrudManagement',
        actions:{
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            }
        },
    },
    uiForm : {
        fields:{
            dataperiodid : {name: 'dataperiodid', label: 'Código' ,typeName: 'String', editable: false, optional: true},
            dataperiod : {name: 'dataperiod', label: 'Descripción' ,typeName: 'String', optional: true},
            responsibleid : {name: 'responsibleid', label: 'Responsable',typeName:'es.rbm.model.jpa.Worker', optional: true},
            customerid : {name: 'customerid', label: 'Cliente',typeName:'es.rbm.model.jpa.Customer', optional: false},
            since : {name: 'since', label: 'Desde',typeName:'Date', optional: false},
            until : {name: 'until', label: 'Hasta',typeName:'Date', optional: false},
            externalcode : {name: 'externalcode', label: 'Codigo externo',typeName:'String', optional: true},
        },
        /*onChangeHandleTrigger : (records, field, value, callback) =>{
            checkFields(records, field, value, callback);
        }*/
    },

    uiTab: (dataperiod) => getUiTab(dataperiod)
}

