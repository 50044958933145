import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import GridTable from "../../../../../../View/Form/GridTable";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import {Stack} from "@mui/material";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import {GridEditModes} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import MenuButton from "../../../../../../View/Button/MenuButton";
import SaveIcon from '@mui/icons-material/Save';
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import Grid from "@mui/material/Grid";
import AluEditDataperioddetailDialog from "../../../Aluminium/Crud/Dataperiod/AluEditDataperioddetailDialog";
import CustomDialog from "../../../../../../View/CustomDialog";
import AccountingentryFromInvoice from "./AccountingentryFromInvoice";
import AccountingentryFromSaleInvoice from "./AccountingentryFromSaleInvoice";
import AccountingentryFromCharge from "./AccountingentryFromCharge";
import AccountingentryFromPayment from "./AccountingentryFromPayment";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}

export const proccesCellUpdate = (value, newRow, oldRow, gridRef, field, rowId) => {
    if (field.name === 'accountingaccountid') {
        let targetRowId = newRow[rowId];
        let description = null;
        let newValue = newRow[field.name];
        if (newValue) {
            description = newValue.description;
        }
        if (gridRef.current) {
            requestAnimationFrame(() => {
                gridRef.current.setEditCellValue({id: targetRowId, field: 'description', value: description});
            });
        }
    }
}

const ui = {
    fields: {
        accountingentrydetailid : {name: 'accountingentrydetailid', label: '#',typeName:'String', flex: 0.3, align: 'center', optional: false, editable: false},
        accountingaccountid : {name: 'accountingaccountid', label:'Cuenta' , typeName: 'es.rbm.model.jpa.Accountingaccount', hideId: true, keyField: 'account', descriptiveField: 'account', flex: 1.5, optional: false, proccesCellUpdate},
        description : {name: 'description', label: 'Descripción',typeName:'String', align: 'center', optional: false, flex: 1.5},
        concept : {name: 'concept', label: 'Concepto',typeName:'String', align: 'center', optional: false, flex: 1.5},
        debit : {name: 'debit', label:'Debe' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter},
        credit : {name: 'credit', label:'Haber' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter},
    },
    keyComponent: 'saleAccountingentrydetails',
}

const formUI =  {
    fields: {
        date : {name: 'date', label:'Fecha' , typeName: 'Date', editable: true, autofocus: true},
        contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center'},
    }
}

const detailsFormUI =  {
    fields: {
        dumpconcept : {name: 'dumpconcept', label:'Copiar concepto' , typeName: 'Boolean', editable: true, align: 'right', optional: true},
    }
}

export default function NewAccountingentryView(props) {
    const {setOpenNewObject} = props;
    let initialState = {records: [], credit: 0, debit: 0, header: {}, edit: true, detailsForm: {dumpconcept: true},
        isOpenAddInvoice: false,isOpenAddSaleInvoice: false, complete: false, isOpenAddPayment: false, isOpenAddCharge: false};
    const [state, setState] = React.useState({...initialState});
    const [gridRef, setGridRef] = React.useState(null);
    const {credit, debit, records, header, complete, detailsForm,
        isOpenAddInvoice, isOpenAddSaleInvoice, isOpenAddPayment, isOpenAddCharge} = state;
    const balance = debit - credit;
    const {contractorid} = header;

    useEffect(() =>{
        const {records, edit} = state;
        if (records.length) {
            let current = gridRef?.current;
            if (current) {
                setTimeout(() => {
                    const {debit, credit} = state;
                    let allRowIds = current?.getAllRowIds();
                    if (allRowIds?.length && edit/*(debit - credit !== 0 || !(credit || debit))*/) {
                        let rowId = allRowIds[allRowIds.length - 1];
                        try {
                            current.startRowEditMode({ id: rowId })
                        } catch (e) {
                            console.log(e);
                        }
                    }
                }, 1100);
            }
        }
    }, [gridRef?.current?.state?.rows?.dataRowIds])

    const addNewDetail = () => {
        const {records, detailsForm} = state;
        let newRecord = {accountingentrydetailid: records?.length + 1 || 0};
        if (detailsForm?.dumpconcept) {
            let validRecordConcept = records.filter(r => r.concept != null);
            if (validRecordConcept && validRecordConcept.length) {
                newRecord.concept = validRecordConcept[0].concept;
            }
        }
        records.push(newRecord)
        setState({...state, records: [...records], debit, credit, edit: true})
    }


    const onChange = (newRecord, oldRecord) => {
        const {records} = state;
        let edit = false;
        if (records.includes(oldRecord)) {
            let index = records.indexOf(oldRecord);
            records[index] = newRecord;
            let debit = records.reduce((l, c) => l + (c.debit || 0), 0);
            let credit = records.reduce((l, c) => l + (c.credit || 0), 0);
            if (index === records.length - 1 && debit - credit !== 0) {
               addNewDetail();
               edit = true;
            }
        }
        let debit = records.reduce((l, c) => l + (c.debit || 0), 0);
        let credit = records.reduce((l, c) => l + (c.credit || 0), 0);
        setState({...state, records: [...records], debit, credit, edit})
    }

    const formChange = (record, complete) => {
        const {records, detailsForm} = state;
        if (complete && !records.length) {
            let newRecord = {accountingentrydetailid: records?.length || 0};
            records.push(newRecord)
        }
        setState({...state, header: record, complete, records: [...records]});
    }

    const formDetailsChange = (record) => {
        setState({...state, detailsForm: record});
    }

    const saveAndResetView = () => {
        const {records, header } = state;
        const request = {...header, details: records}
        doPost("accounting/generateAccountingentry", request, result => {
            if (result) {
                setState({...state, ...initialState, header, complete});
            }
        })
    }

    const saveAndClose = () => {
        const {records, header } = state;
        const request = {...header, details: records}
        doPost("accounting/generateAccountingentry", request, result => {
            if (result) {
                setOpenNewObject(false);
            }
        })
    }

    const onSelectItem = (op) => {
        const {id} = op;
        switch (id) {
            case 1:
                console.log(op);
                break;
            case 2:
                saveAndClose();
                break;
            case 3:
                saveAndResetView();
                break;
            default:
                throw new Error('No se permite esta opción');
        }
    }

    const onAddInvoiceItem = (op) => {
        const {id} = op;
        switch (id) {
            case 1:
                openAddPurchaseInvoice();
                break;
            case 2:
                openAddSaleInvoice();
                break;
            case 3:
                openAddPayment();
                break;
            case 4:
                openAddCharge();
                break;
            default:
                throw new Error('No se permite esta opción');
        }
    }

    const openAddPurchaseInvoice = () => setState({...state, isOpenAddInvoice: true, edit: false, records: []});
    const openAddSaleInvoice = () => setState({...state, isOpenAddSaleInvoice: true, edit: false, records: []});
    const openAddPayment = () => setState({...state, isOpenAddPayment: true, edit: false, records: []});
    const openAddCharge = () => setState({...state, isOpenAddCharge: true, edit: false, records: []});

    const onFinish = (newDetails) => {
        let newState = {...state, isOpenAddInvoice: false, isOpenAddSaleInvoice: false, isOpenAddCharge: false, isOpenAddPayment: false,
            records: [...newDetails]};
        setState(newState);
    }

    return (
        <Box sx={{m: 2}}>
            <CustomDialog title={'Contabilizar factura compra'} maxWidth="lg" open={isOpenAddInvoice} onClose={() => onFinish([])}>
                <AccountingentryFromInvoice onFinish={onFinish} contractorid={contractorid}/>
            </CustomDialog>
            <CustomDialog title={'Contabilizar factura venta'} maxWidth="lg" open={isOpenAddSaleInvoice} onClose={() => onFinish([])}>
                <AccountingentryFromSaleInvoice onFinish={onFinish} contractorid={contractorid}/>
            </CustomDialog>
            <CustomDialog title={'Contabilizar pago'} maxWidth="lg" open={isOpenAddPayment} onClose={() => onFinish([])}>
                <AccountingentryFromPayment onFinish={onFinish} contractorid={contractorid}/>
            </CustomDialog>
            <CustomDialog title={'Contabilizar cobro'} maxWidth="lg" open={isOpenAddCharge} onClose={() => onFinish([])}>
                <AccountingentryFromCharge onFinish={onFinish} contractorid={contractorid}/>
            </CustomDialog>
            <SingleRecordForm ui={formUI} records={header} onChange={formChange} disableAutoFocus />
            <Grid container sx={{ pt: 4 }}>
                <Grid item md={9}>
                    <Stack  direction="row" spacing={2} >
                        <Button size={'small'} variant="contained" onClick={addNewDetail} startIcon={<AddIcon />} sx={{m: 1}} disabled={!complete}>
                            Añadir detalle
                        </Button>
                        <MenuButton
                            label={'Contabilizar'}
                            startIcon={<AddIcon />}
                            disabled={!complete}
                            onClickOption={onAddInvoiceItem}
                            options={[
                                {id: 1, label: 'Factura compra'},
                                {id: 2, label: 'Factura venta'},
                                {id: 3, label: 'Pago'},
                                {id: 4, label: 'Cobro'},
                            ]}
                        />
                        <MenuButton
                            label={'Guardar'}
                            startIcon={<SaveIcon/>}
                            disabled={!records.length}
                            onClickOption={onSelectItem}
                            options={[
                                {id: 2, label: 'Guardar y cerrar'},
                                {id: 3, label: 'Guardar y nuevo'},
                            ]}
                        />
                    </Stack>
                </Grid>
                <Grid item md={3} align={'rigth'}>
                        <SingleRecordForm ui={detailsFormUI} records={detailsForm} onChange={formDetailsChange} disableAutoFocus />
                </Grid>
            </Grid>
            <GridTable ui={ui} setGridRef={setGridRef}
                       editMode={GridEditModes.Row}
                       records={records} rowId={'accountingentrydetailid'}
                       onChange={onChange} density={'compact'} hideFooter={true}
                       sx={{height: '40vh', bgcolor: '#ffffff', mt: 2}}
            />
            <Paper spacing={5} sx={{bgcolor: '#ffffff', mt: 2}}>
                <Typography component="h1" variant="h6" color={'#ec0e0e'} align={'center'}>
                    {balance !== 0 ? 'El asiento está descuadrado' : ''}
                </Typography>
                <Stack direction="row" spacing={2} justifyContent="center">
                    {'Debe: ' + debit + '. Haber: ' + credit + '. Diferencia: ' + balance + '.'}
                </Stack>
            </Paper>
        </Box>
    )
}
