import * as React from 'react';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useEffect} from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SingleRecordForm from "../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../View/Button/SuccessButton";
import {saveExecution} from "./PlannedproductionController";

const ui = {
    fields:{
        hours : {name: 'hours', label: 'Fecha y hora',typeName:'Datetime'},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number'},
    }
}

export default function EditExecutionDialog(props) {
    const {open, setOpen, selected, data, plannedproduction} = props;
    const [executionid, setExecutionid] = React.useState(selected);
    const [isComplete, setComplete] = React.useState(false);
    const [title, setTitle] = React.useState('Añadir ejecución');

    const handledChange = (record, complete) => {
        setExecutionid({...record});
        setComplete(complete);
    }

    useEffect(() =>{
        if (executionid && executionid.executionid){
            setTitle('Editar ejecución')
        } else {

        }
    }, [props])

    const onSuccessClick = (e) => {e.preventDefault(); saveExecution({...executionid, saleorderdetails: data.saleorderdetails, plannedproduction: plannedproduction}, result => {
        setOpen(false)
    })};
    const onNegativeClick = (e) => {e.preventDefault();setOpen(!open)};
    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <SingleRecordForm ui={ui} records={executionid} onChange={handledChange} disableAutoFocus />
            <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
        </Box>
    )
}
