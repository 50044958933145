import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useEffect} from "react";
import {doGet} from "../../../../Utils/Restclient/NetworkActions";
import Title from "../Dashboard/Title";
import Paper from "@mui/material/Paper";
import {getEntityDescription} from "../../../../Utils/UtilsCommon";
import {GREEN_COLOR, RED_COLOR} from "../../../../Utils/uiColor";

export default function StockmovementResumeView(props) {
    const [lastMovement, setLastMovement] = React.useState(null);
    const options = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
    useEffect(() =>{
        doGet("stock/stockinfo", result => setLastMovement(result));
    }, [])
    return (
        <React.Fragment >
            <Paper sx={{mt: 3, padding: 4}}>
                    <Title sx={{fontWeight: 'bold' }}>Últimos movimientos</Title>
                    <Table stickyHeader size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{fontWeight: 'bold' }}>Almacen</TableCell>
                                <TableCell sx={{fontWeight: 'bold' }}>Entrada/Salida</TableCell>
                                <TableCell sx={{fontWeight: 'bold' }}>Fecha</TableCell>
                                <TableCell sx={{fontWeight: 'bold' }}>Producto</TableCell>
                                <TableCell sx={{fontWeight: 'bold' }}>Cantidad</TableCell>
                                <TableCell sx={{fontWeight: 'bold' }}>Unidad</TableCell>
                                <TableCell sx={{fontWeight: 'bold' }}>Tipo</TableCell>
                                <TableCell sx={{fontWeight: 'bold' }}>Referencia</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {lastMovement && lastMovement.map((row, i) => (
                                <TableRow key={i} sx={{backgroundColor: row.entry? GREEN_COLOR : RED_COLOR}}>
                                    <TableCell sx={{fontWeight: 'bold' }}>{getEntityDescription(row.warehouseid, 'es.rbm.model.jpa.Warehouse')}</TableCell>
                                    <TableCell>{row.entry? "Entrada" : "Salida"}</TableCell>
                                    <TableCell>{new Date(row.date).toLocaleDateString('es-ES', options)}</TableCell>
                                    <TableCell sx={{fontWeight: 'bold' }}>{row.productid.product}</TableCell>
                                    <TableCell>{row.quantity.toFixed(3).replace(/[.,]0+$/ , "")}</TableCell>
                                    <TableCell>{row.movementunit}</TableCell>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell>{row.reference}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>

            </Paper>
        </React.Fragment>
    );
}