import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {error, success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import NewWorkerWorkernoteDialog from "./NewWorkerWorkernoteDialog";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import CustomDialog from "../../../../../../View/CustomDialog";


const ui = {
    fields: {
        //workernoteid : {name: 'workernoteid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.5, sort: 'asc' },
        workernote : {name: 'workernote', label:'Nota' , typeName: 'String', editable: true, align: 'center', flex: 2},
        day : {name: 'day', label:'Fecha' , typeName: 'Date', editable: true},
        readhour : {name: 'readhour', label:'Hora de lectura' , typeName: 'Time', editable: false},
    }
}

export default function WorkerWorkernoteView(props) {
    const {workerid} = props;
    const [records, setRecords] = React.useState([]);
    const [workernote, setWorkernote] = React.useState(null);
    const [openNewWorkerWorkernote, setOpenNewWorkerWorkernote] = React.useState(false);
    const [refreshObjects, setRefreshObjects] = React.useState(false);

    const newWorkerWorkernote = () => {
        setOpenNewWorkerWorkernote(true);
    }

    const onEdit = (workernoteid) => {
        setWorkernote(workernoteid);
        setOpenNewWorkerWorkernote(!openNewWorkerWorkernote)
    }

    const onDelete = (workernoteid) =>{
        deleteObject(workernoteid,{name:"workernote", keyField:'workernoteid'},() => setRefreshObjects(!refreshObjects))
    }

    const onChange = (e) => {
        const record = records.filter(r => r.workernoteid === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        doPutRest('workernote', toSend, response =>{
            if(response){
                success('Se ha guardado correctamente')
            }
        })
    }

    useEffect(() =>{
        if(!openNewWorkerWorkernote){
            const filters = {
                fields: {workerid : {name: 'workerid', operator: 'OPERATOR_EQUAL', value: workerid},},
                entity : { name: 'workernote'}
            }
            searchByCriteria(filters, result =>{
                setRecords(result);
                setWorkernote(null);
            });
        }
    }, [refreshObjects, openNewWorkerWorkernote, props])

    return (
        <Box sx={{ width: '100%' , mt: 2}}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}}>
                <CustomDialog title={workernote ? 'Editar nota' : 'Nueva nota'}
                    open={openNewWorkerWorkernote} onClose={() => setOpenNewWorkerWorkernote(false)}>
                    <NewWorkerWorkernoteDialog workernoteid={workernote} worker={workerid} setOpen={setOpenNewWorkerWorkernote} open={openNewWorkerWorkernote}/>
                </CustomDialog>
                <Button size={'small'} variant="contained" onClick={newWorkerWorkernote} startIcon={<AddIcon />} sx={{m: 1}}>
                    Añadir nota
                </Button>
                <GridTable ui={ui} sx={{mt: 2, height: '70vh'}} onDelete={onDelete} onEdit={onEdit}
                           records={records} rowId={'workernoteid'}
                           onChange={onChange} toolBar actionFlex={0.3}
                />
            </Box>
        </Box>
    );
}
