import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import DetailsSaleInvoiceDialog from "../Saleinvoice/DetailsSaleInvoiceDialog";
import SaleAccountingDialog from "../Saleinvoice/SaleAccountingDialog";
import * as React from "react";
import {saleinvoiceUI} from "../Saleinvoice/saleinvoiceCrud";

const getUiTab = (vatid) =>{
    return ({
        uiTabs : {fields : [
                {label : "IVA", component: <EditObjectDialog objectid={vatid} entityDefinition={vatUI}/> },
            ]}
    })
}
export const vatUI = {
    entity : {
        name: 'vat',
        keyField: 'vatid',
        label: 'Impuesto'
    },
    uiTable: {
        fields:{
            vatid : {name: 'vatid', label:'Codigo' , typeName: 'Number', editable: false, flex: 0.3 },
            vat : {name: 'vat', label:'Descripción' , typeName: 'String', editable: true, align: 'center' },
            value : {name: 'value', label:'Porcentaje' , typeName: 'Number', editable: true },
            incomeaccountid : {name: 'incomeaccountid',label: 'Cuenta soportado', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
            outputaccountid : {name: 'outputaccountid',label: 'Cuenta repercutido', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},

        },
        keyComponent: 'vatCrudManagement'
    },
    uiForm : {
        fields:{
            vatid : {name: 'vatid', label:'Codigo' , typeName: 'String', editable: false, flex: 0.3, optional: true },
            vat : {name: 'vat', label:'Descripción' , typeName: 'String', editable: true },
            value : {name: 'value', label:'Porcentaje' , typeName: 'Number', editable: true },
            incomeaccountid : {name: 'incomeaccountid',label: 'Cuenta soportado', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
            outputaccountid : {name: 'outputaccountid',label: 'Cuenta repercutido', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
        }
    },
    uiTab: (vat) => getUiTab(vat)
}

