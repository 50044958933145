import React, { useState, useEffect } from 'react';
import { ScheduleComponent, ViewsDirective, ViewDirective, Inject, Day, Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-react-schedule';
import {getRelativeMaxViews} from "../../Utils/Constant";
import OlapSample from "../PivotTable/OlapSample";
import {registerLicense, setCulture} from "@syncfusion/ej2-base";
const response =
    [
        {
            "id": 1,
            "Subject": "Reunión con equipo",
            "StartTime": "2025-02-22T10:00:00.000Z",
            "EndTime": "2025-02-22T11:00:00.000Z",
            "IsAllDay": false,
            "Location": "Oficina principal",
            "Description": "Discutir avances del proyecto ERP"
        },
{
    "id": 2,
    "Subject": "Llamada con cliente",
    "StartTime": "2025-02-23T14:30:00.000Z",
    "EndTime": "2025-02-23T15:00:00.000Z",
    "IsAllDay": false,
    "Location": "Virtual - Zoom",
    "Description": "Presentación de funcionalidades"
},
{
    "id": 3,
    "Subject": "Día festivo",
    "StartTime": "2025-02-24T00:00:00.000Z",
    "EndTime": "2025-02-24T23:59:59.000Z",
    "IsAllDay": true,
    "Location": "",
    "Description": "Día libre"
}
];


registerLicense('Ngo9BigBOggjHTQxAR8/V1NHaF1cWmhIfEx1RHxQdld5ZFRHallYTnNWUj0eQnxTdEBjW31ccXRWRmNYUUBzWg==');
setCulture("es");

const UserCalendar = () => {
    const [eventos, setEventos] = useState([]);

    useEffect(() => {
        cargarEventos();
    }, []);

    const cargarEventos = () => setEventos(response);

    const agregarEvento = (evento) => {
        console.error('Error agregando evento:');
    };

    const eliminarEvento = (id) => {
            setEventos(eventos.filter(evento => evento.id !== id));
    };

    return (
        <div className="p-4">
            <ScheduleComponent
                height={getRelativeMaxViews(5)}
                eventSettings={{ dataSource: eventos }}
                actionComplete={(args) => {
                    if (args.requestType === 'eventCreate') {
                        agregarEvento(args.data[0]);
                    } else if (args.requestType === 'eventRemove') {
                        eliminarEvento(args.data[0].Id);
                    }
                }}
            >
                <ViewsDirective>
                    <ViewDirective option='Day' />
                    <ViewDirective option='Week' />
                    <ViewDirective option='WorkWeek' />
                    <ViewDirective option='Month' />
                    <ViewDirective option='Agenda' />
                </ViewsDirective>
                <Inject services={[Day, Week, WorkWeek, Month, Agenda]} />
            </ScheduleComponent>
        </div>
    );
};

export default UserCalendar;
