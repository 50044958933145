import {actionTypes} from "../Redux/reducer";
import store from "../Redux/store";

export const success = (notificationmessage, callback) =>{
    store.dispatch({type: actionTypes.SHOW_NOTIFICATION, notification: 'success', notificationmessage: notificationmessage})
    callback && callback()
}

export const error = (notificationmessage, callback) =>{
    store.dispatch({type: actionTypes.SHOW_NOTIFICATION, notification: 'error', notificationmessage: notificationmessage})
    callback && callback()
}

export const warningNotification = (notificationmessage, callback) =>{
    store.dispatch({type: actionTypes.SHOW_NOTIFICATION, notification: 'warning', notificationmessage: notificationmessage})
    callback && callback()
}

export const info = (notificationmessage, callback) =>{
    store.dispatch({type: actionTypes.SHOW_NOTIFICATION, notification: 'info', notificationmessage: notificationmessage})
    callback && callback()
}