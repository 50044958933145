import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doDelete, doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../View/Form/GridTable";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {GridEditModes} from "@mui/x-data-grid";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import CustomDialog from "../../../../../../View/CustomDialog";
import PaymentDialog from "./PaymentDialog";
import Grid from "@mui/material/Grid";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {groupBy} from "../../../../../../Utils/UtilsCommon";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}

const ui = {
    fields: {
        //paymentduedateid : {name: 'paymentduedateid', label: '#',typeName:'String', flex: 0.3, align: 'center', optional: true, visible: false, editable: false},
        date : {name: 'date', label:'Fecha vencimiento' , typeName: 'Date', optional: false, editable: false},
        amount : {name: 'amount', label:'Importe' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter, editable: false},
        pending : {name: 'pending', label:'Pendiente' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter, editable: false},
    },
    sort: {
        field: 'date',
        sort: 'asc'
    },
    checkboxSelection: true,
    keyComponent: 'PaymentduedateGrid',
}

const paymentGridUI = {
    fields: {
        paymentid : {name: 'paymentid', label: '#',typeName:'String', flex: 0.1, align: 'center', optional: true, editable: false},
        payment : {name: 'payment', label: 'Pago',typeName:'String', flex: 0.5, align: 'center', optional: true},
        //document : {name: 'document', label: 'Documento',typeName:'String', flex: 0.3, align: 'center', optional: true},
        date : {name: 'date', label:'Fecha vencimiento' , typeName: 'Date', optional: false, editable: false, flex: 0.2},
    },
    keyComponent: 'PaymentGrid',
}

const paymentdetailGridUI = {
    fields: {
        paymentdetailid : {name: 'paymentdetailid', label: '#',typeName:'String', flex: 0.2, align: 'center', optional: true, editable: false},
        //paymentid : {name: 'paymentid', label:'Pago' , typeName: 'es.rbm.model.jpa.Payment', optional: false, editable: false, flex: 0.5},
        //date : {name: 'date', label: 'Fecha',typeName:'Date', flex: 0.3, align: 'center', optional: true},
        amount : {name: 'amount', label: 'Importe',typeName:'Number', flex: 0.3, align: 'center', optional: true, valueFormatter: decimalFormatter},
        bankaccountid : {name: 'bankaccountid', label: 'Cuenta bancaria',typeName:'es.rbm.model.jpa.Bankaccount', flex: 0.3, align: 'center', optional: true},
        //paymentduedateid : {name: 'paymentduedateid', label:'Vencimiento' , typeName: 'es.rbm.model.jpa.Paymentduedate', optional: false, editable: false},
    },
    keyComponent: 'PaymentdetailGrid',
}

export default function PaymentduedateGrid(props) {
    const {purchaseinvoiceid} = props;
    let initialState = {records: [], selecteds: [], paymentRecords: [], paymentSelecteds: [],
        paymentdetailsRecords: [], dialogIsOpen: false};
    const [state, setState] = React.useState(initialState);
    const {records, selecteds, dialogIsOpen, paymentRecords, paymentSelecteds, paymentdetailsRecords, originalsPaymentRecords} = state;

    useEffect(() => {
        refresh();
    }, [purchaseinvoiceid])

    const refresh = () => {
        setState({...initialState})
        if (purchaseinvoiceid) {
            doPost('accounting/paymentduedateinfo', purchaseinvoiceid, paymentduedateinfo => {
                let paymentduedatesFilter = paymentduedateinfo.map(pdd => { return {paymentduedateid: pdd.paymentduedateid}});
                if (paymentduedatesFilter?.length) {
                    const filters = {
                        entity : {name: 'paymentdetail'},
                        fields: {paymentduedateid : {name: 'paymentduedateid.paymentduedateid', operator: OPERATORS.OPERATOR_IN, value: paymentduedatesFilter}},
                    }
                    searchByCriteria(filters, paymentdetails => {
                        let payments = paymentdetails.filter((item, index, self) =>
                            index === self.findIndex((t) => t.paymentid.paymentid === item.paymentid.paymentid)
                        ).map(paymentdetails => paymentdetails.paymentid);
                        setState({...state, records: paymentduedateinfo, paymentRecords: payments, originalsPaymentRecords: [...paymentdetails], paymentdetailsRecords: [], dialogIsOpen: false, selecteds: []})
                    });
                } else {
                    setState({...state, records: paymentduedateinfo, dialogIsOpen: false, selecteds: []})
                }
            });
        }
    }
    
    const onDelete = (paymentduedate) => {
        doDelete('rest/paymentduedate/delete/' + paymentduedate.paymentduedateid, null, () => {
            refresh();
        })
    }

    const onPaymentDelete = (paymentid) => {
        doDelete('rest/payment/delete/' + paymentid.paymentid, null, () => {
            refresh();
        })
    }

    const onPaymentDetailDelete = (paymentdetailid) => {
        doDelete('rest/paymentdetail/delete/' + paymentdetailid.paymentdetailid, null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const {records} = state;
        const record = records.filter(r => r.paymentduedateid === e.paymentduedateid);
        if (record.length) {
            doPutRest('paymentduedate', e, () => refresh());
        } else {
            delete e.accountingentrydetailid
            doPutRest('paymentduedate', e, () => refresh());
        }
    }

    const onSelection = (e) => {
        const selecteds = records.filter(r => e.includes(r.paymentduedateid));
        setState({...state, selecteds})
    }

    const onPaymentSelection = (e) => {
        const paymentSelecteds = originalsPaymentRecords.filter(r => e.includes(r.paymentid.paymentid));
        setState({...state, paymentdetailsRecords: paymentSelecteds})
    }

    const isRowSelectable = (r) => {
        return r?.row?.pending;
    }

    let closeDialog = (update) => {
        setState({...state, dialogIsOpen: false});
        if (update) {
            refresh();
        }
    }
    let openDialog = () => setState({...state, dialogIsOpen: true});
    
    return(
        <Box sx={{m: 2, alignItems: 'left', width: '100%' }}>
            <CustomDialog fullWidth title={'Nueva liquidación'} maxWidth="xl" open={dialogIsOpen} onClose={closeDialog}>
                <PaymentDialog paymentduedates={selecteds} closeDialog={closeDialog} />
            </CustomDialog>
            <SuccessButton text={'Liquidar'} color={'secondary'} sx={{mb: 1}} onClick={openDialog} disabled={!selecteds.length}/>
            <GridTable ui={ui} checkboxSelection //onDelete={onDelete}
                       isRowSelectable={isRowSelectable}
                       editMode={GridEditModes.Row} enableRowSelectionOnClick
                       records={records} onSelection={onSelection} rowId={'paymentduedateid'}
                       onChange={onChange} density={'compact'} hideFooter={true}
                       sx={{height: '15vh', bgcolor: '#ffffff'}}
            />
            {paymentRecords?.length ? <Grid container sx={{mt: 2}} spacing={2}>
                <Grid item md={paymentdetailsRecords?.length ? 6 : 12}>
                    <GridTable ui={paymentGridUI}
                               enableRowSelectionOnClick
                               records={paymentRecords} onSelection={onPaymentSelection}
                               rowId={'paymentid'} density={'compact'} hideFooter={true}
                               sx={{height: '15vh', bgcolor: '#ffffff'}}
                    />
                </Grid>
                {paymentdetailsRecords?.length ?
                <Grid item md={6}>
                    <GridTable ui={paymentdetailGridUI} onDelete={onPaymentDetailDelete}
                               records={paymentdetailsRecords} rowId={'paymentdetailid'}
                               density={'compact'} hideFooter={true} actionFlex={0.1}
                               sx={{height: '15vh', bgcolor: '#ffffff'}}
                    />
                </Grid> : ''}
            </Grid> : ''}
        </Box>
    )
}
