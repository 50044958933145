
export const defaultDataperiodCostReportExcelReport = {
    ui : {
        fields :{
            since : {name: 'since', label: 'Desde' ,typeName: 'Date', optional: false},
            until : {name: 'until', label: 'Hasta' ,typeName: 'Date', optional: false},
            customerid : {name: 'customerid', label: 'Cliente' ,typeName: 'es.rbm.model.jpa.Customer', optional: true},
            saleorderid : {name: 'saleorderid', label: 'Orden' , descriptiveField: 'saleorder', typeName: 'es.rbm.model.jpa.Saleorder', optional: true},
        }
    },
    title: 'Costes de partes de trabajo',
    description: 'Resumen de los trabajores realizados en un intervalo de fechas',
    url: 'reports/report/es.rbm.report.DataperiodCostsReport',
    format: () => ('.xlsx'),
    documentName: 'Resumen_costes_' + new Date(),
    keyForm: 'defaultCostExcelReport',
}
