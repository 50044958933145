import * as React from 'react';

import {useEffect} from "react";
import GridTable from "../../../../../View/Form/GridTable";
import {doPost} from "../../../../../Utils/Restclient/NetworkActions";
import Paper from "@mui/material/Paper";
import SingleRecordForm from "../../../../../View/Form/SingleRecordForm";
import Grid from "@mui/material/Grid";
import RefreshIcon from "@mui/icons-material/Refresh";
import {IconButton} from "@mui/material";
import Button from "@mui/material/Button";
import EditImputationDialog from "./EditImputationDialog";
import {doDeleteRest, doPutRest} from "../../../../../Utils/Restclient/NetworkRestActions";
import {getRelativeMaxViews, maxHeightViews} from "../../../../../Utils/Constant";
import CustomDialog from "../../../../../View/CustomDialog";

export const uiImputation = {
    uiTable: {
        sort: false,
        fields:{
            workerid : {name: 'workerid', label: 'Trabajador' ,typeName: 'es.rbm.model.jpa.Worker', align: 'left', editable: false, flex: 1.5},
            idcardnumber : {name: 'idcardnumber', label: 'DNI' ,typeName: 'String', align: 'center', editable: false},
            since : {name: 'since', label: 'Desde' , typeName: 'Hour', flex:0.5, align: 'center', editable: true},
            until : {name: 'until', label: 'Hasta' , typeName: 'Hour', flex:0.5, align: 'center', editable: true},
            workspaceid : {name: 'workspaceid', label: 'Espacio' ,typeName: 'es.rbm.model.jpa.Workspace', align: 'center'},
            taskid : {name: 'taskid', label: 'Tarea' ,typeName: 'es.rbm.model.jpa.Task', align: 'center'},
            hourlycost : {name: 'hourlycost', label: 'Precio hora' ,typeName: 'Number',  align: 'center'},
            hours : {name: 'hours', label: 'Horas' ,typeName: 'Number', editable: false, align: 'center'},
            amount : {name: 'amount', label: 'Importe' ,typeName: 'Number', editable: false, align: 'center'},
        },
        keyComponent: 'ImputationDayView',
    },
}

const uiFilters = {
    fields: {
        date : {name: 'date', label: 'Fecha' ,typeName: 'Date', size: 12, height: 'small'}
    }
}

export default function ImputationDay(props) {
    const [state, setState] = React.useState({
            ui: uiImputation, records: [], filters: {}, openEditImputation: false,
            selected: null
        }
    );
    const [records, setRecords] = React.useState([]);

    const handledChange = (filter) =>{
        setState({...state, filters: filter});
    }

    useEffect(() => {
        refresh()
    },[state.filters.date, state.openEditImputation] )

    const refresh = () => {
        if (state.filters.date && !state.openEditImputation) {
            const toSend = {day: state.filters.date}
            doPost('salary/getImputationWorkedDay', toSend,records => {
                setRecords([...records])
            })
        }
    }

    const closeEditImputation = () => {
        setState({...state, openEditImputation: false})
    }

    const generateImputations = () => {
        doPost('salary/generateImputations', {day: state.filters.date}, () => refresh())
    }

    const onCellKeyDown = (v, e) => {
        if (e.code.toLowerCase() === 'delete'){
            doDeleteRest('imputation', v.value.imputation, () => refresh())
        }
    }

    const onEdit = (selected) => {
        setState(prevState => ({
            ...prevState,
            selected: selected,
            openEditImputation: true
        }));
    }


    const onChange = (newRecord) => {
        doPutRest('imputation', newRecord, result => {
            refresh();
        })
    }

    let title = state.selected ? 'Editar imputación' : 'Nueva imputación';
    return (
        <Paper sx={{ width: '100%', height: maxHeightViews + 3, pt: 1, mt:1}}>
            <CustomDialog title={title}
                maxWidth={"md"} open={state.openEditImputation} onClose={closeEditImputation}>
                <EditImputationDialog selected={state.selected} changeOpenEditImputation={closeEditImputation}/>
            </CustomDialog>

            <Grid container direction="row" justify="flex-end" alignItems="center">
                <Grid item md={12}>
                    <Paper sx={{backgroundColor: 'white', m:2, p: 2}}>
                        <Grid container direction="row" justify="flex-end" alignItems="center">
                            <Grid item md={2}>
                                <Button variant="contained" onClick={generateImputations} sx={{ml: 3}} size={'small'}> Generar imputaciones </Button>
                            </Grid>
                            <Grid item md={7}/>
                            <Grid item md={2}  align={'center'}>
                                <SingleRecordForm ui={uiFilters} records={state.filters} sx={{pr: 1}} onChange={handledChange}/>
                            </Grid>
                            <Grid item md={1} align={'left'} sx={{mt:1}}>
                                <IconButton onClick={refresh} color={'primary'} variant="contained" size="large">
                                    <RefreshIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item md={12}>
                    <GridTable ui={state.ui.uiTable} sx={{m:2, mb: 5, mt: 0, height: getRelativeMaxViews(-10), backgroundColor: 'white'}}
                               records={records} rowId={'imputationid'} toolBar
                               colorRow={'hours'} onEdit={onEdit}
                               density={'compact'} onChange={onChange}
                               onCellKeyDown={onCellKeyDown} />
                </Grid>
            </Grid>
        </Paper>
    )

}
