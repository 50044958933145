import * as React from 'react';
import AccountingentryDialog from "../Accounting/AccountingentryDialog";
import Grid from "@mui/material/Grid";
import PaymentduedateGrid from "../Accounting/PaymentduedateGrid";
import {useEffect} from "react";
import {doGetRest} from "../../../../../../Utils/Restclient/NetworkRestActions";

export default function PurchaseAccountingDialog(props) {
    const {purchaseinvoiceid} = props;
    const [invoice, setInvoice] = React.useState({...purchaseinvoiceid});

    useEffect(() => {
        doGetRest('purchaseinvoice', purchaseinvoiceid.purchaseinvoiceid, result => setInvoice(result));
    }, [props])

    return(
        <Grid container>
            <Grid item md={12}>
                <AccountingentryDialog accountingentryid={invoice.accountingentryid} />
            </Grid>
            <Grid item md={12}>
                <PaymentduedateGrid purchaseinvoiceid={purchaseinvoiceid} />
            </Grid>
        </Grid>

    )
}
