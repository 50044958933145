import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import DetailsPurchasenoteDialog from "./DetailsPurchasenoteDialog";
import * as React from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

const getUiTab = (purchasenoteid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Albarán de compra", component: <EditObjectDialog objectid={purchasenoteid} entityDefinition={purchasenoteUI}/> },
                {label : "Detalles", component: <DetailsPurchasenoteDialog selected={purchasenoteid}/> },
            ]}
    })
}

const filterOrders = (records, callback) => {
    if (records.providerid) {
        const toSend = {providerid: records.providerid};
        doPost('purchasenote/getPendingOrders', toSend, r => {
            callback && callback(r);
        })
    } else {
        callback && callback([])
    }
}

const autoComplete = (record, field, value, callback) =>{
    if (field === 'purchaseorderid') {
        const newRecord = {...record};
        newRecord.serie = record?.purchaseorderid?.contractorid?.seriepurchasenote;
        callback && callback({...newRecord});
    } else if (field === 'contractorid') {
        const newRecord = {...record};
        const contractorid = record?.contractorid;
        const filters = {
            entity : { name: 'serie'},
            fields: {
                contractorid : {name: 'contractorid', operator: OPERATORS.OPERATOR_EQUAL, value: contractorid},
                seriepurchasenote : {name: 'seriepurchasenote', operator: OPERATORS.OPERATOR_EQUAL, value: true},
            },
        }
        searchByCriteria(filters, result => {
            if (result && result.length) {
                newRecord.serie = result[0].serie;
                callback && callback({...newRecord});
            } else {
                callback && callback({...record})
            }
        });
    } else {
        callback && callback({...record})
    }
}

export const purchasenoteUI = {
    entity : {
        name: 'purchasenote',
        keyField: 'purchasenoteid',
        label: 'Albarán de compra',
        colorRow: 'purchaseinvoiceid',
    },
    uiTable: {
        fields: {
            purchasenoteid : {name: 'purchasenoteid', label:'#' , typeName: 'Number', editable: false,flex:0.2, optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false,flex:0.2, optional: true, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.3, optional: true},
            contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', size: 6, optional: false},
            purchasenote : {name: 'purchasenote', label: 'Descripción',typeName:'String', optional: true},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            providerid : {name: 'providerid', label:'Proveedor' , typeName: 'es.rbm.model.jpa.Provider'},
            purchaseinvoiceid : {name: 'purchaseinvoiceid', label:'Facturado' , typeName: 'Boolean', editable: false},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            purchaseorderid : {name: 'purchaseorderid', label:'Numero de pedido' , typeName: 'es.rbm.model.jpa.Purchaseorder', flex: 0.8, align: 'center', optional: true },
        },
        actions:{
            /*viewPDF:{
                fun: (entity, setPDF) => viewPDF(entity, setPDF)
            },
            downloadPDF:{
                fun: (entity) => downloadPDF(entity),
            },
            sendEmail:{
                fun: () => console.log('sending mail')
            },*/
        },
        keyComponent: 'purchasenoteCrudManagement'
    },
    uiForm : {
        fields: {
            purchasenoteid : {name: 'purchasenoteid', label:'Cod.' , typeName: 'Number', editable: false,flex:0.5, optional: true, size: 2},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', size: 2, optional: false},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 2, optional: true},
            contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', size: 6, optional: false},
            purchasenote : {name: 'purchasenote', label: 'Descripción',typeName:'String', optional: true},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            providerid : {name: 'providerid', label:'Proveedor' , typeName: 'es.rbm.model.jpa.Provider'},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            purchaseorderid : {name: 'purchaseorderid', label:'Numero de pedido' , typeName: 'es.rbm.model.jpa.Purchaseorder', flex: 0.8, align: 'center', filterRecords: filterOrders, optional: true},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        initialsValues: {
            date_1: new Date(new Date().setDate(new Date().getDate() - 7))
        },
        fields: {
            purchasenoteid : {name: 'purchasenoteid', label: 'Nº albarán' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            providerid : {name: 'providerid', label: 'Proveedor' ,typeName: 'es.rbm.model.jpa.Provider', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa' ,typeName: 'es.rbm.model.jpa.Contractor', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            purchasenotestatusid : {name: 'purchasenotestatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Purchasenotestatus', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            date : {name: 'date',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            date_1 : {name: 'date_1',label: 'Desde', typeName: 'Date', operator: OPERATORS.OPERATOR_GTE, empty: true, optional: true},
            date_2 : {name: 'date_2',label: 'Hasta', typeName: 'Date', operator: OPERATORS.OPERATOR_LTE, empty: true, endDay: true, optional: true},
        }
    },
    uiTab: (purchasenote) => getUiTab(purchasenote)
}

