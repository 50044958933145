import * as React from 'react';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useEffect} from "react";
import {doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SaveRoundedButton from "../../../../../../View/Button/SaveRoundedButton";
import {absencetypeUI} from "./absencetypeCrud";


const ui = {
    fields:{
        since : {name: 'since', label: 'Desde' ,typeName: 'Date', size: 4},
        until : {name: 'until', label: 'Hasta' ,typeName: 'Date', optional: true, empty: true, size: 4},
        absencetypeid : {name: 'absencetypeid', label: 'Tipo de ausencia' ,typeName: 'es.rbm.model.jpa.Absencetype', size: 4},
    },
    keyComponent: 'NewWorkerAbsenceDialog'
}
export default function NewWorkerAbsenceDialog(props) {
    const {open, setOpen, worker} = props;
    const [title, setTitle] = React.useState('Nuevo tipo');
    const [absenceid, setAbsenceid] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);

    const handledChange = (record, complete) =>{
        setAbsenceid({...record});
        setComplete(complete);
    }

    const addAbsence = (absenceid, callback) => {
        const toSend = {...absenceid, workerid: worker}
        doPost('rest/absence/create', toSend, () => callback && callback())
    }

    useEffect(() =>{
        const absenceid1 = props.absenceid;
        setAbsenceid(absenceid1 ? {...absenceid1} : {});
        setTitle(absenceid1 ? 'Editar ausencia' : 'Nueva ausencia');
    }, [props])

    const onSucessClick = (e) => {
        e.preventDefault()
        addAbsence(absenceid, () => setOpen(!open))
    };

    return(
        <Box sx={{m: 2, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <Grid container spacing={2}>
                <Grid item md={11}>
                    <SingleRecordForm ui={ui} records={absenceid} onChange={handledChange} disableAutoFocus />
                </Grid>
                <Grid item md={1} sx={{mt: -1}}>
                    <SaveRoundedButton onClick={onSucessClick} disabled={!isComplete} />
                </Grid>
            </Grid>
        </Box>
    );
}
