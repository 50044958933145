import React, { useEffect, useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import { fileToBase64 } from "./utils";
import { Button, Grid, IconButton, Box, Typography } from "@mui/material";
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import DeleteIcon from '@mui/icons-material/Delete';
import PropTypes from "prop-types";

const fileTypes = ["JPG", "JPEG", "PNG", "GIF"];

function DragDropPhoto({ onLoadFile, name, file: initialFile, label }) {
    const [file, setFile] = useState(initialFile);

    useEffect(() => {
        setFile(initialFile);
    }, [initialFile]);

    const handleChange = (file) => {
        setFile(file);
        fileToBase64(file, (result) => onLoadFile({ target: { name, value: result } }));
    };

    const handleRemove = (e) => {
        e.stopPropagation(); // Evita que se dispare el FileUploader al hacer clic en eliminar
        setFile(null);
        onLoadFile({ target: { name, value: null } });
    };

    return (
        <Grid container justifyContent="center">
            <Grid item md={12}>
                <Box
                    sx={{
                        border: "1.5px dashed #ccc",
                        borderRadius: "20px", // Bordes redondeados
                        padding: "6px",
                        textAlign: "center",
                        cursor: "pointer",
                        position: "relative",
                        display: "flex",
                        flexDirection: "row", // Diseño horizontal
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                        height: "50px", // Mantenerlo delgado
                        "&:hover": { borderColor: "#1976d2" },
                    }}
                >
                    <FileUploader
                        name={name}
                        handleChange={handleChange}
                        types={fileTypes}
                    >
                        <Button
                            size="small"
                            variant="text"
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                gap: 1,
                                textTransform: "none",
                                color: "#1976d2",
                            }}
                        >
                            <PhotoCameraIcon sx={{ fontSize: 20 }} />
                            <Typography variant="body2">
                                {file ? label + " cargado" : "Subir " + label}
                            </Typography>
                        </Button>
                    </FileUploader>
                    {file && (
                        <IconButton
                            sx={{
                                position: "absolute",
                                right: 10,
                                background: "rgba(255,255,255,0.8)",
                                boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                                height: "30px",
                                width: "30px",
                            }}
                            onClick={handleRemove}
                        >
                            <DeleteIcon color="error" fontSize="small" />
                        </IconButton>
                    )}
                </Box>
            </Grid>
        </Grid>
    );
}

DragDropPhoto.propTypes = {
    onLoadFile: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    file: PropTypes.any,
};

export default DragDropPhoto;
