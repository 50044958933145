import * as React from 'react';
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import {getRelativeMaxViews} from "../../../../../../Utils/Constant";
import Grid from "@mui/material/Grid";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {doPost, doPostFile} from "../../../../../../Utils/Restclient/NetworkActions";
import {Stack} from "@mui/material";
import {info} from "../../../../../../Utils/Notification/notifications";
import { BarChart } from '@mui/x-charts/BarChart';
import Paper from "@mui/material/Paper";
import {useEffect} from "react";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}

const colSpan = (value, row) => {
    let output = row.output;
    if (row.account && output === null) {
        return Object.keys(ui.fields).length - 1;
    }
    return undefined;
};

const ui = {
    fields: {
        account : {name: 'account', label: 'Cuenta', typeName:'String', flex: 0.3, align: 'left', optional: false, editable: true, colSpan: colSpan},
        t1 : {name: 't1', label: 'T1',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        t2 : {name: 't2', label: 'T2',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        t3 : {name: 't3', label: 'T3',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        t4 : {name: 't4', label: 'T4',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        balance : {name: 'balance', label: 'Diferencia',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
    },
    keyComponent: 'AccountingModel390Grid',
    disableSorted: true
}

const formUI =  {
    fields: {
        year : {name: 'year', label:'Año' , typeName: 'Number', editable: true, autofocus: true, size: 3.5},
        contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center', size: 5},
    }
}

const currentYear = new Date().getFullYear();
const firstDayOfYear = new Date(currentYear, 0, 1);
const lastDayOfYear = new Date(currentYear, 2, 31);

const initialState = {filter: {since: firstDayOfYear, until: lastDayOfYear}, records: [], chartRecords: null};

export default function AccountingModel390(props) {
    const [state, setState] = React.useState({...initialState});
    const [gridRef, setGridRef] = React.useState(null);
    const {filter, records, complete, chartRecords} = state;
    const {onPressBack} = props;

    useEffect(() => {
        let newChartRecords = records.map(r => {
            return {
                label: r.account,
                data: [Math.abs(r.t1), Math.abs(r.t2), Math.abs(r.t3), Math.abs(r.t4), Math.abs(r.balance)]
            }
        });
        let record = newChartRecords[newChartRecords.length - 1];
        if (record) {
            record.label = 'Diferencia'
        }
        setState({...state, chartRecords: newChartRecords})
    }, [records])

    const filterChange = (filter, complete) => {
        setState({...state, filter, complete, records: complete ? records : []});
    }

    const updateRecords = () => {
        const request = {...filter, model: 390}
        doPost("accounting/getAccountingModel", request, result => {
            if (result) {
                let newRecords = [...result.records];
                if (!newRecords.length) {
                    info('No existen datos para las cuentas seleccionadas');
                }
                setState({...state, records: newRecords})
            }
        })
    }

    const downloadReport = () => {
        const csvString = gridRef.current.getDataAsCsv();
        doPostFile("utils/convertCsvToExcel", [csvString],response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Modelo_390_' + filter?.contractorid?.contractor + '_' + filter?.year + '.xlsx';
                alink.click();
            })
        },false)
    }

    const getRowColor = (data) => {
        return 'color--' + data?.row?.color ;
    }

    let relativeMaxViews = getRelativeMaxViews(-5);
    return(
        <Box sx={{m: 2}}>
            <Grid container spacing={2}>
                <Grid item md={7}>
                    <SingleRecordForm ui={formUI} records={filter} onChange={filterChange} disableAutoFocus/>
                </Grid>
                <Grid item md={2}/>
                <Grid item md={3} align={'center'} maxHeight>
                    <Stack spacing={2} direction={'row'} sx={{mt: 0.5}}>
                        <SuccessButton fullWidth text={'Actualizar'} onClick={updateRecords} disabled={!complete}/>
                        <SuccessButton fullWidth text={'Descargar'} onClick={downloadReport} disabled={!complete || !records?.length} color={'secondary'}/>
                        <SuccessButton fullWidth text={'Atras'} onClick={onPressBack}/>
                    </Stack>
                </Grid>
            </Grid>
            <GridTable ui={ui} setGridRef={setGridRef}
                       records={records} rowId={'id'} hideFooter
                       density={'compact'} rowColorFunc={getRowColor}
                       sx={{height: '45vh', bgcolor: '#ffffff', mt: 2}}
            />
            {chartRecords && <Paper sx={{backgroundColor: 'white'}}>
                <BarChart
                          xAxis={[{ scaleType: 'band', data: ['T1', 'T2', 'T3', 'T4', 'Total'] }]}
                          series={chartRecords}
                          height={300}
                          borderRadius={10}
                />
            </Paper>}
        </Box>
    )
}
