import React, { useEffect, useState } from "react";
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions
} from "@mui/material";
import {doPost} from "../../../../../Utils/Restclient/NetworkActions";
import {error, info} from "../../../../../Utils/Notification/notifications";

const WorkerTimeCodeControl = () => {
    const [currentTime, setCurrentTime] = useState(new Date().toLocaleTimeString());
    const [codigo, setCodigo] = useState("");
    const [password, setPassword] = useState("");
    const [step, setStep] = useState("codigo");
    const [lastFichaje, setLastFichaje] = useState(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    let wakeLock = null;

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTime(new Date().toLocaleTimeString());
        }, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (typeof window !== "undefined" && "NDEFReader" in window) {
            try {
                const nfcReader = new window.NDEFReader();
                if (nfcReader) {
                    nfcReader.scan().then(() => {
                        nfcReader.onreading = (event) => {
                            let records = event?.message?.records;
                            if (!records || records.length === 0) {
                                info("Etiqueta detectada, pero sin registros NDEF.");
                                return;
                            }
                            const decoder = new TextDecoder();
                            for (const record of records) {
                                const nfcCode = decoder?.decode(record?.data);
                                handleNFCCard(nfcCode);
                                break;
                            }
                        };
                    }).catch(err => {
                        console.log(err);
                    });
                }
            } catch (err) {
                error("NFC no soportado en este navegador:", err);
            }
        }
    }, []);

    useEffect(() => {
        const requestWakeLock = async () => {
            if ('wakeLock' in navigator) {
                try {
                    wakeLock = await navigator.wakeLock.request('screen');
                    console.log("Wake lock activado");
                } catch (err) {
                    console.error("Error al activar wake lock:", err);
                }
            }
        };

        requestWakeLock();

        return () => {
            if (wakeLock) {
                wakeLock.release().then(() => {
                    console.log("Wake lock liberado");
                });
            }
        };
    }, []);

    const handleNFCCard = (nfcCode) => {
        onSubmit(nfcCode);
    };

    const handleButtonClick = (value) => {
        if (step === "codigo") {
            setCodigo((prev) => prev + value);
        } else {
            setPassword((prev) => prev + value);
        }
    };

    const onNextStep = () => {
        if (step === "codigo" && codigo) {
            setStep("password");
        } else if (step === "password" && password) {
            onSubmit();
        }
    };

    const onSubmit = (nfcCode) => {
        const request = {code: codigo, password: password, hash: nfcCode};
        doPost('presence/doTimetick', request, result => {
            if (result) {
                const {hours, records} = result;
                let record = records[records.length - 1];
                const lastTick = {action: record.type, worker: record.worker, timetick: record.timetick, hours}
                setLastFichaje(lastTick)
                setDialogOpen(true);
                setTimeout(() => setDialogOpen(false), 3000);
            }
        })
        setCodigo("");
        setPassword("");
        setStep("codigo");
    };

    const renderKeypad = () => (
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gap: 1 }}>
            {[...Array(9).keys()].map((num) => (
                <Button key={num + 1} variant="contained" sx={{ height: 60, fontSize: '1.5rem'}} onClick={() => handleButtonClick((num + 1).toString())}>
                    {num + 1}
                </Button>
            ))}
            <Button variant="contained" color="secondary" sx={{ height: 60 }} onClick={() => step === "codigo" ? setCodigo("") : setPassword("") }>
                Borrar
            </Button>
            <Button variant="contained" sx={{ height: 60, fontSize: '1.5rem' }} onClick={() => handleButtonClick("0")}>
                0
            </Button>
            <Button variant="contained" color="success" sx={{ height: 60 }} onClick={onNextStep}>
                {step === "codigo" ? "Siguiente" : "Fichar"}
            </Button>
        </Box>
    );

    return (
        <Box sx={{ width: "100%", padding: 2 }}>
            <AppBar position="static" sx={{ backgroundColor: "white", color: "black" }}>
                <Toolbar>
                    <Typography variant="h6" sx={{ flexGrow: 1 }}>
                        Registro de Horarios
                    </Typography>
                    <Typography variant="body1">{currentTime}</Typography>
                </Toolbar>
            </AppBar>
            <Box textAlign="center" mt={4}>
                <Typography variant="h6">{step === "codigo" ? "Código" : "Contraseña"}</Typography>
                <Box sx={{ mb: 2, fontSize: "1.5rem", fontWeight: "bold", padding: 1, backgroundColor: "lightgray", display: "inline-block", minWidth: 120, minHeight: 40 }}>
                    {step === "codigo" ? codigo || " " : password.replace(/./g, "*") || " "}
                </Box>
                {renderKeypad()}
            </Box>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
                <DialogTitle>Fichaje Registrado</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Usuario: {lastFichaje?.worker}</Typography>
                    <Typography variant="body1">Hora: {lastFichaje?.timetick}</Typography>
                    <Typography variant="body1">Acción: {lastFichaje?.action}</Typography>
                    <Typography variant="body1">Horas totales: {lastFichaje?.hours}</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDialogOpen(false)} color="primary">Cerrar</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default WorkerTimeCodeControl;
