import * as React from 'react';
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import {getRelativeMaxViews} from "../../../../../../Utils/Constant";
import Grid from "@mui/material/Grid";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {doPost, doPostFile} from "../../../../../../Utils/Restclient/NetworkActions";
import {Stack} from "@mui/material";
import {info} from "../../../../../../Utils/Notification/notifications";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}


const ui = {
    fields: {
        account : {name: 'account', label: 'Cuenta', typeName:'String', flex: 0.3, align: 'left', optional: false, editable: true},
        idcardnumber : {name: 'idcardnumber', label: 'NIF', typeName:'String', flex: 0.3, align: 'left', optional: false, editable: true},
        name : {name: 'name', label: 'Razón social', typeName:'String', flex: 0.3, align: 'left', optional: false, editable: true},
        totalamount : {name: 'totalamount', label: 'Importe total',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        q1 : {name: 'q1', label: 'Q1',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        q2 : {name: 'q2', label: 'Q2',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        q3 : {name: 'q3', label: 'Q3',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        q4 : {name: 'q4', label: 'Q4',typeName:'Number', flex: 0.2, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        },
    keyComponent: 'AccountingModel347Grid',
    disableSorted: true
}

const formUI =  {
    fields: {
        year : {name: 'year', label:'Año' , typeName: 'Number', editable: true, autofocus: true, size: 3.5},
        contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center', size: 4},
    }
}

const currentYear = new Date().getFullYear();
const firstDayOfYear = new Date(currentYear, 0, 1);
const lastDayOfYear = new Date(currentYear, 2, 31);

const initialState = {filter: {since: firstDayOfYear, until: lastDayOfYear}, records: []};

export default function AccountingModel347(props) {
    const [state, setState] = React.useState({...initialState});
    const [gridRef, setGridRef] = React.useState(null);
    const {filter, records, complete} = state;
    const {onPressBack} = props;

    const filterChange = (filter, complete) => {
        setState({...state, filter, complete, records: complete ? records : []});
    }

    const updateRecords = () => {
        const request = {...filter, model: 347}
        doPost("accounting/getAccountingModel", request, result => {
            if (result) {
                let newRecords = [...result.records];
                if (!newRecords.length) {
                    info('No existen datos para las cuentas seleccionadas');
                }
                setState({...state, records: newRecords})
            }
        })
    }

    const downloadReport = () => {
        const csvString = gridRef.current.getDataAsCsv();
        doPostFile("utils/convertCsvToExcel", [csvString],response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'Modelo_303_' + filter?.contractorid?.contractor + '.xlsx';
                alink.click();
            })
        },false)
    }

    const getRowColor = (data) => {
        return 'color--' + data?.row?.color ;
    }

    let relativeMaxViews = getRelativeMaxViews(-5);
    return(
        <Box sx={{m: 2}}>
            <Grid container spacing={2}>
                <Grid item md={7}>
                    <SingleRecordForm ui={formUI} records={filter} onChange={filterChange} disableAutoFocus/>
                </Grid>
                <Grid item md={2}/>
                <Grid item md={3} align={'center'} maxHeight>
                    <Stack spacing={2} direction={'row'} sx={{mt: 0.5}}>
                        <SuccessButton fullWidth text={'Actualizar'} onClick={updateRecords} disabled={!complete}/>
                        <SuccessButton fullWidth text={'Descargar'} onClick={downloadReport} disabled={!complete || !records?.length} color={'secondary'}/>
                        <SuccessButton fullWidth text={'Atras'} onClick={onPressBack}/>
                    </Stack>
                </Grid>
            </Grid>
            <GridTable ui={ui} setGridRef={setGridRef}
                       records={records} rowId={'id'}
                       density={'compact'} rowColorFunc={getRowColor}
                       sx={{height: relativeMaxViews, bgcolor: '#ffffff', mt: 2}}
            />
        </Box>
    )
}
