import * as React from 'react';
import Box from '@mui/material/Box';
import Button from "@mui/material/Button";
import {useEffect} from "react";
import AddIcon from '@mui/icons-material/Add';
import Dialog from "@mui/material/Dialog";
import {doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {error, success} from "../../../../../../Utils/Notification/notifications";
import GridTable from "../../../../../../View/Form/GridTable";
import {deleteObject} from "../../../../../../View/Form/CrudForm/actions";
import NewWorkerAbsenceDialog from "./NewWorkerAbsenceDialog";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import Typography from "@mui/material/Typography";
import CustomDialog from "../../../../../../View/CustomDialog";

const uiAbsence = {
    fields:{
        //absenceid : {name: 'absenceid', label: '#' ,typeName: 'Number', flex: 0.3, editable: false},
        since : {name: 'since', label: 'Desde' ,typeName: 'Date'},
        until : {name: 'until', label: 'Hasta' ,typeName: 'Date', optional: true},
        absencetypeid : {name: 'absencetypeid', label: 'Tipo' ,typeName: 'es.rbm.model.jpa.Absencetype'},
        remarks : {name: 'remarks', label: 'Observaciones' ,typeName: 'String'},
    }
}

const uiPresenceCardcode = {
    fields:{
        presencecardcodeid : {name: 'presencecardcodeid', label: '#' ,typeName: 'Number', flex: 0.3, editable: false},
        presencecardcode : {name: 'presencecardcode', label: 'Tarjeta' ,typeName: 'String', align: 'center'},
        registerdate : {name: 'registerdate', label: 'Fecha de registro' ,typeName: 'Date'},
    }
}

export default function WorkerPresenceView(props) {
    const {workerid} = props;
    const [records, setRecords] = React.useState([]);
    const [cardsRecords, setCardsRecords] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [openNewWorkerAbsence, setOpenNewWorkerAbsence] = React.useState(false);
    const [refreshObjects, setRefreshObjects] = React.useState(false);

    const newWorkerAbsence = () => {
        setOpenNewWorkerAbsence(true);
    }

    const onEdit = (absenceid) => {
        setSelected(absenceid);
        setOpenNewWorkerAbsence(true)
    }

    const onDelete = (absenceid) =>{
        deleteObject(absenceid,{name:"absence", keyField:'absenceid'},() => setRefreshObjects(!refreshObjects))
    }

    const onDeletePresencecardcode = (presencecardcode) =>{
        deleteObject(presencecardcode,{name:"presencecardcode", keyField:'presencecardcodeid'},() => setRefreshObjects(!refreshObjects))
    }

    const onChange = (e) => {
        const record = records.filter(r => r.absenceid === e.id);
        let toSend = e;
        if(record && record.length>0){
            toSend = record[0];
            toSend[e.field] = e.value
        }
        doPut('rest/absence/update',toSend, response =>{
            if(response){
                success('Se ha guardado correctamente')
            }
        })

    }

    const onChangeCard = (e) => {
        const record = cardsRecords.filter(r => r.presencecardcodeid === e.id);
        if(record && record.length>0){
            const toSend = record[0];
            toSend[e.field] = e.value
            doPut('rest/presencecardcode/update',toSend, response =>{
                if(response){
                    success('Se ha guardado correctamente')
                }
            })
        } else {
            error('Error al modificar el tarjeta');
        }
    }

    const searchPresenceCard = () =>{
        const filters = {
            fields: {workerid : {name: 'workerid', operator: 'OPERATOR_EQUAL', value: workerid},},
            entity : { name: 'presencecardcode'}
        }
        searchByCriteria(filters, result =>{
            setCardsRecords(result);
        });
    }


    useEffect(() =>{
        if(!openNewWorkerAbsence){
            const filters = {
                fields: {workerid : {name: 'workerid', operator: 'OPERATOR_EQUAL', value: workerid},},
                entity : { name: 'absence'}
            }
            searchByCriteria(filters, result =>{
                setRecords(result);
                searchPresenceCard();
            });
        }
    }, [refreshObjects, openNewWorkerAbsence, props])

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ height: '80vh', width: '100%', mb: 1}} overflow={"auto"} >
                <CustomDialog title={selected ? 'Editar ausencia' : 'Nueva ausencia'} maxWidth={'md'}
                    open={openNewWorkerAbsence} onClose={() => setOpenNewWorkerAbsence(false)}>
                    <NewWorkerAbsenceDialog worker={workerid} absenceid={selected} setOpen={setOpenNewWorkerAbsence} open={openNewWorkerAbsence}/>
                </CustomDialog>
                <Typography sx={{m: 2}} component="h1" variant="h5" align={'center'}>
                    Ausencias
                </Typography>
                <Button size={'small'} variant="contained" onClick={newWorkerAbsence} startIcon={<AddIcon />} sx={{m: 1}}>
                    Añadir ausencia
                </Button>
                <GridTable ui={uiAbsence} onDelete={onDelete} onEdit={onEdit}
                           records={records} rowId={'absenceid'}  sx={{height: '40vh'}}
                           onChange={onChange} toolBar actionFlex={0.3}
                />
                <Typography sx={{m: 2}} component="h1" variant="h5" align={'center'}>
                    Tarjetas de presencia
                </Typography>
                <GridTable ui={uiPresenceCardcode} onDelete={onDeletePresencecardcode}
                           onChange={onChangeCard}  sx={{height: '20vh'}}
                           records={cardsRecords} rowId={'presencecardcodeid'}
                           actionFlex={0.15}
                />
            </Box>
        </Box>
    );
}
