import {doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {doDeleteRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

export const updateDataperioddetail = (dataperioddetailid, callback) => {
    let toSent = [];
    if(dataperioddetailid.workerid instanceof Array){
        toSent = dataperioddetailid.workerid.map(worker =>{
            return {...dataperioddetailid, workerid: worker, dataperiodid: {dataperiodid: dataperioddetailid?.dataperiodid?.dataperiodid}}
        });
    } else{
        toSent = [{...dataperioddetailid, dataperiodid: {dataperiodid: dataperioddetailid?.dataperiodid?.dataperiodid}}]
    }

    toSent.forEach(dd => {
        doPut('rest/dataperioddetail/update',dd, response =>{
            if(response){
                callback && callback();
            }
        })
    })
}

export const deleteDataperioddetail = (dataperioddetailid, callback) =>{
    doDeleteRest('dataperioddetail', dataperioddetailid.dataperioddetailid, callback)
}

export const getDetails = (dataperiodid, callback) =>{
    const filters = {
        entity : {name: 'dataperioddetail'},
        fields: {dataperiodid : {name: 'dataperiodid', operator: 'OPERATOR_EQUAL', value: {dataperiodid: dataperiodid.dataperiodid}}},
    }
    searchByCriteria(filters, result =>{
        callback && callback(result)
    });
}