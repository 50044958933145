import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import SingleRecordForm from "../../../../View/Form/SingleRecordForm";
import SuccessButton from "../../../../View/Button/SuccessButton";

export default function GenerateStockMovement(props){
    const {data} = props;
    const [movement, setMovement] = React.useState({});
    const [isComplete, setComplete] = React.useState(false);
    const {ui, operation, onSubmit} = data;
    const handledChange = (record, complete) => {
        setMovement({...record});
        setComplete(complete);
    }

    const generateMovement = () =>{
        onSubmit && onSubmit(movement);
    }

    useEffect(() =>{}, [])

    const onSuccessClick = (e) => {
        e.preventDefault();
        generateMovement()
    };

    return(
            <Box sx={{p:2, pt: 3}}>
                <SingleRecordForm ui={ui} records={movement} onChange={handledChange} />
                <SuccessButton text={'GUARDAR'} fullWidth sx={{mt: 3, mb:1}} onClick={onSuccessClick} disabled={!isComplete}/>
            </Box>
    )
}
