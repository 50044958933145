const DB_NAME = "TicksDB";
const PENDING_STORE = "fichajes";
const SYNCED_STORE = "fichajes_synced";

export const openDB = () =>
    new Promise((resolve, reject) => {
        const request = indexedDB.open(DB_NAME, 1);
        request.onerror = () => reject('IndexedDB error');
        request.onsuccess = () => resolve(request.result);
        request.onupgradeneeded = (event) => {
            const db = event.target.result;
            db.createObjectStore(PENDING_STORE, { autoIncrement: true });
            db.createObjectStore(SYNCED_STORE, { keyPath: "id" });
        };
    });



export const saveFichaje = async (data) => {
    const db = await openDB();
    const tx = db.transaction(PENDING_STORE, 'readwrite');
    tx.objectStore(PENDING_STORE).add(data);
    return tx.complete;
};

export const getAllFichajes = async () => {
    const db = await openDB();
    const tx = db.transaction(PENDING_STORE, 'readonly');
    const store = tx.objectStore(PENDING_STORE);

    return new Promise((resolve, reject) => {
        const request = store.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject('Error leyendo fichajes pendientes');
    });
};


export const clearFichajes = async () => {
    const db = await openDB();
    const tx = db.transaction(PENDING_STORE, 'readwrite');
    tx.objectStore(PENDING_STORE).clear();
    return tx.complete;
};

export const removeFichajes = async (fichajesToRemove) => {
    const db = await openDB();
    const tx = db.transaction(PENDING_STORE, 'readwrite');
    const store = tx.objectStore(PENDING_STORE);

    const keyRequest = store.openCursor();
    return new Promise((resolve, reject) => {
        keyRequest.onsuccess = (event) => {
            const cursor = event.target.result;
            if (!cursor) {
                resolve();
                return;
            }

            const currentValue = cursor.value;
            const shouldDelete = fichajesToRemove.some(f =>
                f.date === currentValue.date &&
                f.latitude === currentValue.latitude &&
                f.longitude === currentValue.longitude
            );

            if (shouldDelete) {
                cursor.delete();
            }

            cursor.continue();
        };

        keyRequest.onerror = () => reject("Error al eliminar fichajes");
    });
};

const generateId = (record) => {
    return record.id;
};

export const saveSyncedFichajes = async (fichajes) => {
    const db = await openDB();
    const tx = db.transaction(SYNCED_STORE, 'readwrite');
    const store = tx.objectStore(SYNCED_STORE);

    await store.clear();

    for (const record of fichajes) {
        const newRecord = {...record, id: generateId(record)};
        await store.put(newRecord);
    }

    await tx.done;
};

export const getSyncedFichajes = async () => {
    const db = await openDB();
    const tx = db.transaction(SYNCED_STORE, 'readonly');
    const store = tx.objectStore(SYNCED_STORE);

    return new Promise((resolve, reject) => {
        const request = store.getAll();
        request.onsuccess = () => resolve(request.result);
        request.onerror = () => reject("Error al leer fichajes sincronizados");
    });
};


