export const recypeTypes = ["TRATAMIENTO", "FERTIRRIGACIÓN","OTROS"]
export const timeticktypes = ["ENTRADA", "SALIDA"]
export const getToken = () =>{
    const token = localStorage['token-session-erp'] || null;
    return token
}

//const debug = process.env.REACT_APP_DEBUG;

export const serverName = window.location.hostname;
//const devRootServer = serverName.startsWith("http") ? serverName : "http://" + serverName + ':8080' + (serverName.includes("/ERPBerma/") ? "" : "/ERPBerma/");
//const devRootServer =  'https://testsibema.sibema.es/ERPBerma/'
const devRootServer =  process.env.REACT_APP_WEB_PROTOCOL + '://' + serverName + process.env.REACT_APP_WEB_PORT + (serverName.includes(process.env.REACT_APP_WEB_SUFFIX) ? "" : process.env.REACT_APP_WEB_SUFFIX);

//export const rootServer = debug ? devRootServer : serverName.startsWith("https") ? serverName : "https://" + serverName + (serverName.includes("/ERPBerma/") ? "" : "/ERPBerma/");
export const rootServer = devRootServer;


const maxHeightViewsNumber = 78

export const maxHeightViews = maxHeightViewsNumber + 'vh';

export const getRelativeMaxViews = (offset) => {
    return 80 + offset + 'vh';
}
