import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../View/Form/GridTable";
import {doPost} from "../../../../Utils/Restclient/NetworkActions";
import Typography from "@mui/material/Typography";

const ui = {
    fields: {
        //stockmovementid : {name: 'stockmovementid', label:'Codigo' , typeName: 'Number', editable: false},
        warehouseid : {name: 'warehouseid', label:'Almacén' , typeName: 'es.rbm.model.jpa.Warehouse', editable: false, urlRecords: 'rest/warehouse/getActives'},
        date : {name: 'date', label:'Fecha' , typeName: 'Time', editable: false},
        type : {name: 'type', label:'Tipo' , typeName: 'String', editable: false},
        //movementid : {name: 'movementid', label:'Referencia' , typeName: 'Number', editable: false},
        movementunitid : {name: 'movementunitid', label:'Unidad de movimiento' , typeName: 'es.rbm.model.jpa.Movementunit', editable: false},
        quantity : {name: 'quantity', label: 'Cantidad',typeName:'Number', editable: false},
    },
    keyComponent: 'MovementLinesViewGrid',
}

export default function MovementLinesView(props){
    const {warehouseid, productid, batchid} = props;
    const [records, setRecords] = React.useState([]);

    const getDetails = () =>{
        const toSend = {warehouseid: [warehouseid], productid: [productid], batchid: [batchid], batchFilter: true};
        if(!batchid) {
            delete toSend.batchid;
        }
        doPost("stock/getMovementLines", toSend, records =>{
            setRecords(records);
        })
    }

    useEffect(() =>{getDetails()}, [])

    return (
                <GridTable sx={{height: '60vh'}} ui={ui} hideFooter records={records} rowId={'stockmovementid'}/>
    )
}
