import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doDelete, doPost} from "../../../../../../Utils/Restclient/NetworkActions";
import GridTable from "../../../../../../View/Form/GridTable";
import {doPutRest} from "../../../../../../Utils/Restclient/NetworkRestActions";
import {GridEditModes} from "@mui/x-data-grid";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import CustomDialog from "../../../../../../View/CustomDialog";
import ChargeDialog from "./ChargeDialog";
import Grid from "@mui/material/Grid";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}

const ui = {
    fields: {
        date : {name: 'date', label:'Fecha vencimiento' , typeName: 'Date', optional: false, editable: false},
        amount : {name: 'amount', label:'Importe' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter, editable: false},
        pending : {name: 'pending', label:'Pendiente' , typeName: 'Number', optional: false, valueFormatter: decimalFormatter, editable: false},
    },
    sort: {
        field: 'date',
        sort: 'asc'
    },
    checkboxSelection: true,
    keyComponent: 'ChargeduedateGrid',
}

const chargeGridUI = {
    fields: {
        chargeid : {name: 'chargeid', label: '#',typeName:'String', flex: 0.1, align: 'center', optional: true, editable: false},
        charge : {name: 'charge', label: 'Pago',typeName:'String', flex: 0.5, align: 'center', optional: true},
        //document : {name: 'document', label: 'Documento',typeName:'String', flex: 0.3, align: 'center', optional: true},
        date : {name: 'date', label:'Fecha vencimiento' , typeName: 'Date', optional: false, editable: false, flex: 0.2},
    },
    keyComponent: 'ChargeGrid',
}

const chargedetailGridUI = {
    fields: {
        chargedetailid : {name: 'chargedetailid', label: '#',typeName:'String', flex: 0.2, align: 'center', optional: true, editable: false},
        //chargeid : {name: 'chargeid', label:'Pago' , typeName: 'es.rbm.model.jpa.Charge', optional: false, editable: false, flex: 0.5},
        //date : {name: 'date', label: 'Fecha',typeName:'Date', flex: 0.3, align: 'center', optional: true},
        amount : {name: 'amount', label: 'Importe',typeName:'Number', flex: 0.3, align: 'center', optional: true, valueFormatter: decimalFormatter},
        bankaccountid : {name: 'bankaccountid', label: 'Cuenta bancaria',typeName:'es.rbm.model.jpa.Bankaccount', flex: 0.3, align: 'center', optional: true},
        //chargeduedateid : {name: 'chargeduedateid', label:'Vencimiento' , typeName: 'es.rbm.model.jpa.Chargeduedate', optional: false, editable: false},
    },
    keyComponent: 'ChargedetailGrid',
}

export default function ChargeduedateGrid(props) {
    const {saleinvoiceid} = props;
    let initialState = {records: [], selecteds: [], chargeRecords: [], chargeSelecteds: [],
        chargedetailsRecords: [], dialogIsOpen: false};
    const [state, setState] = React.useState(initialState);
    const {records, selecteds, dialogIsOpen, chargeRecords, chargeSelecteds, chargedetailsRecords, originalsChargeRecords} = state;

    useEffect(() => {
        refresh();
    }, [saleinvoiceid])

    const refresh = () => {
        setState({...initialState})
        if (saleinvoiceid) {
            doPost('accounting/chargeduedateinfo', saleinvoiceid, chargeduedateinfo => {
                let chargeduedatesFilter = chargeduedateinfo.map(pdd => { return {chargeduedateid: pdd.chargeduedateid}});
                if (chargeduedatesFilter?.length) {
                    const filters = {
                        entity : {name: 'chargedetail'},
                        fields: {chargeduedateid : {name: 'chargeduedateid.chargeduedateid', operator: OPERATORS.OPERATOR_IN, value: chargeduedatesFilter}},
                    }
                    searchByCriteria(filters, chargedetails => {
                        let charges = chargedetails.filter((item, index, self) =>
                            index === self.findIndex((t) => t.chargeid.chargeid === item.chargeid.chargeid)
                        ).map(chargedetails => chargedetails.chargeid);
                        setState({...state, records: chargeduedateinfo, chargeRecords: charges, originalsChargeRecords: [...chargedetails], chargedetailsRecords: [], dialogIsOpen: false, selecteds: []})
                    });
                } else {
                    setState({...state, records: chargeduedateinfo, dialogIsOpen: false, selecteds: []})
                }
            });
        }
    }
    
    const onDelete = (chargeduedate) => {
        doDelete('rest/chargeduedate/delete/' + chargeduedate.chargeduedateid, null, () => {
            refresh();
        })
    }

    const onChargeDelete = (chargeid) => {
        doDelete('rest/charge/delete/' + chargeid.chargeid, null, () => {
            refresh();
        })
    }

    const onChargeDetailDelete = (chargedetailid) => {
        doDelete('rest/chargedetail/delete/' + chargedetailid.chargedetailid, null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const {records} = state;
        const record = records.filter(r => r.chargeduedateid === e.chargeduedateid);
        if (record.length) {
            doPutRest('chargeduedate', e, () => refresh());
        } else {
            delete e.accountingentrydetailid
            doPutRest('chargeduedate', e, () => refresh());
        }
    }

    const onSelection = (e) => {
        const selecteds = records.filter(r => e.includes(r.chargeduedateid));
        setState({...state, selecteds})
    }

    const onChargeSelection = (e) => {
        const chargeSelecteds = originalsChargeRecords.filter(r => e.includes(r.chargeid.chargeid));
        setState({...state, chargedetailsRecords: chargeSelecteds})
    }

    const isRowSelectable = (r) => {
        return r?.row?.pending;
    }

    let closeDialog = (update) => {
        setState({...state, dialogIsOpen: false});
        if (update) {
            refresh();
        }
    }
    let openDialog = () => setState({...state, dialogIsOpen: true});
    
    return(
        <Box sx={{m: 2, alignItems: 'left', width: '100%' }}>
            <CustomDialog fullWidth title={'Nueva liquidación'} maxWidth="xl" open={dialogIsOpen} onClose={closeDialog}>
                <ChargeDialog chargeduedates={selecteds} closeDialog={closeDialog} />
            </CustomDialog>
            <SuccessButton text={'Liquidar'} color={'secondary'} sx={{mb: 1}} onClick={openDialog} disabled={!selecteds.length}/>
            <GridTable ui={ui} checkboxSelection //onDelete={onDelete}
                       isRowSelectable={isRowSelectable}
                       editMode={GridEditModes.Row} enableRowSelectionOnClick
                       records={records} onSelection={onSelection} rowId={'chargeduedateid'}
                       onChange={onChange} density={'compact'} hideFooter={true}
                       sx={{height: '15vh', bgcolor: '#ffffff'}}
            />
            {chargeRecords?.length ? <Grid container sx={{mt: 2}} spacing={2}>
                <Grid item md={chargedetailsRecords?.length ? 6 : 12}>
                    <GridTable ui={chargeGridUI}
                               enableRowSelectionOnClick
                               records={chargeRecords} onSelection={onChargeSelection}
                               rowId={'chargeid'} density={'compact'} hideFooter={true}
                               sx={{height: '15vh', bgcolor: '#ffffff'}}
                    />
                </Grid>
                {chargedetailsRecords?.length ?
                <Grid item md={6}>
                    <GridTable ui={chargedetailGridUI} onDelete={onChargeDetailDelete}
                               records={chargedetailsRecords} rowId={'chargedetailid'}
                               density={'compact'} hideFooter={true} actionFlex={0.1}
                               sx={{height: '15vh', bgcolor: '#ffffff'}}
                    />
                </Grid> : ''}
            </Grid> : ''}
        </Box>
    )
}
