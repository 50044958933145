import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {defaultSendMail} from "../../../../../../View/Form/utils";
import PurchasenoteProviderView from "./PurchasenoteProviderView";
import PurchaseorderProviderView from "./PurchaseorderProviderView";
import ProviderproductView from "./ProviderproductView";
import AttributeProviderDialog from "./AttributeProviderDialog";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {townUI} from "../Other/townCrud";
import {accountingAccountUI} from "../Accounting/accountingaccountCrud";
import PurchaseinvoiceProviderView from "./PurchaseinvoiceProviderView";

const getUiTab = (providerid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Proveedor", component: <EditObjectDialog objectid={providerid} entityDefinition={providerUI}/> },
                {label : "Atributos", component: <AttributeProviderDialog providerid={providerid}/> },
                {label : "Tarifas", component: <ProviderproductView providerid={providerid}/> },
                {label : "Pedidos", component: <PurchaseorderProviderView providerid={providerid}/> },
                {label : "Albaranes", component: <PurchasenoteProviderView providerid={providerid}/> },
                {label : "Facturas", component: <PurchaseinvoiceProviderView providerid={providerid}/>}
            ]}
    })
}

const autoComplete = (record, field, value, callback) =>{
    if (field === 'townid') {
        const newRecord = {...record};
        const townid = record?.townid;
        newRecord.stateid = townid?.stateid;
        newRecord.countryid = townid?.stateid?.countryid;
        callback && callback({...newRecord});
    } else {
        callback && callback({...record})
    }
}

const groupByState = (option) => option?.stateid?.state || '';

export const providerUI = {
    entity : {
        name: 'provider',
        keyField: 'providerid',
        label: 'Proveedor',
        urlExcelImport: 'importer/importProviderFromExcel',
        urlDownloadTemplate: 'importer/getProviderTemplate',
        info: {typeName: 'es.rbm.model.jpa.Provider'}
    },
    uiTable: {
        fields:{
            providerid : {name: 'providerid', label:'#' , typeName: 'Number', editable: false, flex: 0.3 },
            provider : {name: 'provider', label:'Nombre' , typeName: 'String', editable: true, flex: 1.5},
            idcardnumber : {name: 'idcardnumber', label:'CIF' , typeName: 'String', editable: true, align: 'center'},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', editable: true, align: 'center'},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, align: 'center'},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, align: 'center'},
        },
        keyComponent: 'providerCrudManagement',
        actions:{
            sendEmail: {
                fun: (entity ,setOpenMail, setMailRecords) => defaultSendMail(entity,'email',setOpenMail, setMailRecords)
            },
        },
    },
    uiForm : {
        fields:{
            providerid : {name: 'providerid', label:'Cod.' , typeName: 'Number', editable: false, optional: true, size: 2},
            provider : {name: 'provider', label:'Nombre' , typeName: 'String', size: 10},
            idcardnumber : {name: 'idcardnumber', label:'CIF' , typeName: 'String', editable: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', editable: true, groupBy: groupByState, addObject: townUI},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', disabled: true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', disabled: true},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'String', editable: true},
            vatid : {name: 'vatid',label: 'IVA', typeName: 'es.rbm.model.jpa.Vat', optional: true},
            accountingaccountid : {name: 'accountingaccountid',label: 'Cuenta contable', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true, addObject: accountingAccountUI},
            counterpart : {name: 'counterpart',label: 'Cuenta contrapartida', typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true, addObject: accountingAccountUI},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String', optional: true, size: 12 , multiline: true, rows: 3},
        },
        onChangeHandleTrigger : (records, field, value, callback) => {
            autoComplete(records, field, value, callback);
        },
    },
    filters : {
        startBegin : true,
        fields: {
            provider : {name: 'provider', label: 'Proveedor' ,typeName: 'String', operator: 'OPERATOR_LIKE', optional: true},
            idcardnumber : {name: 'idcardnumber', label:'DNI/CIF' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            email : {name: 'email', label:'Email' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            countryid : {name: 'countryid', label:'País' , typeName: 'es.rbm.model.jpa.Country',  operator: OPERATORS.OPERATOR_EQUAL, optional: true},
        },
    },
    uiTab: (provider) => getUiTab(provider)
}

