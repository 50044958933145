import * as React from 'react';
import Box from "@mui/material/Box";
import {useEffect} from "react";
import {doDelete} from "../../../Utils/Restclient/NetworkActions";
import GridTable from "../GridTable";
import {doPutRest} from "../../../Utils/Restclient/NetworkRestActions";
import {searchByCriteria} from "../../../Utils/Persistence/PersistenceQuery";
import {OPERATORS} from "../../../Utils/Persistence/PersistenceConstant";

import AddIcon from "@mui/icons-material/Add";
import Button from "@mui/material/Button";
import uiDefinition from "../../../Utils/uiDefinition";
import NewEntityCollectionDialog from "./NewEntityCollectionDialog";
import CustomDialog from "../../CustomDialog";

export default function EntityCollectionDialog(props) {
    const {parentid, info} = props;
    const [state, setState] = React.useState({records: [], openNewEntity: false});
    const {records, openNewEntity} = state;
    const parentInfo = uiDefinition[info.parentTypeName];
    const childrenInfo = uiDefinition[info.childrentypeName];

    useEffect(() => {
        if (!openNewEntity) {
            refresh();
        }
    }, [parentid, openNewEntity])

    const refresh = () => {
        setState({...state, records: []})
        if (parentid) {
            const filters = {
                entity : {name: childrenInfo.entityName},
                fields: {
                    [parentInfo.keyField] : {
                        name: parentInfo.keyField,
                        operator: OPERATORS.OPERATOR_EQUAL,
                        value: parentid
                    }
                },
            }
            searchByCriteria(filters, result =>{
                if (result) {
                    setState({...state, records: result})
                }
            });
        }
    }
    
    const onDelete = (entity) => {
        doDelete('rest/' + childrenInfo.entityName + '/delete/' + entity[childrenInfo.keyField], null, () => {
            refresh();
        })
    }

    const onChange = (e) => {
        const {records} = state;
        let keyField = childrenInfo.keyField;
        const record = records.filter(r => r[keyField] === e[keyField]);
        if (record.length) {
            doPutRest(childrenInfo.entityName, e, () => refresh());
        } else {
            delete e[keyField]
            doPutRest(childrenInfo.entityName, e, () => refresh());
        }
    }

    const addRecord = () => {
        setState({...state, openNewEntity: true});
    }

    const onCloseDialog = () => {
        setState({...state, openNewEntity: false});
    }

    return(
        <Box sx={{m: 2, alignItems: 'left', width: '100%' }}>
            <CustomDialog fullWidth maxWidth={"md"} title={'Nuevo'} open={openNewEntity} onClose={onCloseDialog}>
                <NewEntityCollectionDialog {...props} onClose={onCloseDialog}/>
            </CustomDialog>
            <Button size={'small'} variant="contained" onClick={addRecord} startIcon={<AddIcon />} sx={{m: 1}}>
                Añadir
            </Button>
            <GridTable ui={info.uiTable} onDelete={onDelete}
                       records={records} rowId={childrenInfo.keyField}
                       onChange={onChange} density={'compact'} hideFooter={true}
                       sx={{height: '20vh', bgcolor: '#ffffff'}}
            />
        </Box>
    )
}
