import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {groupBy} from "../../../../../../Utils/UtilsCommon";
import {purchaseorderUI} from "../Purchaseorder/purchaseorderCrud";
import {doPut} from "../../../../../../Utils/Restclient/NetworkActions";
import {error} from "../../../../../../Utils/Notification/notifications";


const purchaseorderdetailUI = {
    fields: {
        purchaseorderdetailid : {name: 'purchaseorderdetailid', label:'#' , typeName: 'Number', editable: false},
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product'},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number'},
        discount : {name: 'discount', label:'Descuento' , typeName: 'Number'},
        price : {name: 'price', label:'Precio unitario' , typeName: 'Number'},
    }
}

const purchaseOrderUI = {
    uiTable: {
        fields:{
            purchaseorderid : {name: 'purchaseorderid', label:'#' , typeName: 'String', editable: false,flex:0.3 },
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false, flex:0.5, optional: false, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: false},
            date : {name: 'date', label: 'Fecha',typeName:'Date', editable: true},
            purchaseorderstatusid : {name: 'purchaseorderstatusid', label: 'Estado',typeName:'es.rbm.model.jpa.Purchaseorderstatus', align: 'center'},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center'},
            remarks : {name: 'remarks', label: 'Observaciones',typeName:'String', optional: true},

        },
        actions: {
            onPrint: {
                onSelectedOption: purchaseorderUI.uiTable.actions.onPrint.onSelectedOption,
                getOptions: purchaseorderUI.uiTable.actions.onPrint.getOptions
            },
            sendEmail:{
                fun: purchaseorderUI.uiTable.actions.sendEmail.fun
            },
        },
        keyComponent: 'purchaseorderCrudManagement'
    }
}

export default function PurchaseorderProviderView(props) {
    const {providerid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [providerid])

    const refresh = () => {
        setDetails([])
        setRecords([])
        const filters = {
            entity : {name: 'purchaseorder'},
            fields: {providerid : {name: 'providerid', operator: 'OPERATOR_EQUAL', value: providerid}},
        }
        searchByCriteria(filters, result =>{
            if(result && result.length > 0){
                const filters = {
                    entity : {name: 'purchaseorderdetail'},
                    fields: {purchaseorderid : {name: 'purchaseorderid', operator: 'OPERATOR_IN', value: result}},
                }
                searchByCriteria(filters, result1 =>{
                    const purchaseorderGroup = groupBy(result1, "purchaseorderid.purchaseorderid");
                    setGroups(purchaseorderGroup);
                    const newRecords = Object.keys(purchaseorderGroup).map(key => purchaseorderGroup[key][0].purchaseorderid);
                    setRecords(newRecords)
                    setDetails([]);
                });
            }
        });
    }

    const onRowClick = (e, v) => {
        if(e){
            setDetails(groups[e.row.purchaseorderid]);
        }
    }

    const onChangeHeader = (newRecord, oldRecord, refresh) => {
        doPut('rest/purchaseorder/update',newRecord, response =>{
            if(!response){
                const index = records.indexOf(oldRecord);
                records[index] = response;
                setRecords([...records]);
                error('Fallo al modificar pedido')
            } else if (refresh){
                const index = records.indexOf(oldRecord);
                records[index] = newRecord;
                setRecords([...records]);
            }
        })
    }

    const onChangeDetail = (newRecord, oldRecord, refresh) => {
        doPut('rest/purchaseorderdetail/update',newRecord, response =>{
            if(!response){
                const index = details.indexOf(oldRecord);
                details[index] = response;
                setDetails([...details]);
                error('Fallo al modificar línea del pedido')
            } else if (refresh){
                const index = details.indexOf(oldRecord);
                details[index] = newRecord;
                setDetails([...details]);
            }
        })
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Box overflow={'auto'}>
                <GridTable ui={purchaseOrderUI.uiTable} enableRowSelectionOnClick
                           sx={{height: '40vh'}} onChange={onChangeHeader}
                           records={records} rowId={'purchaseorderid'} onRowClick={onRowClick}
                           toolBar={'S'} hideFooter/>
            </Box>
            <Box overflow={'auto'}>
                <GridTable ui={purchaseorderdetailUI}
                           density={'compact'}
                           sx={{height: '30vh', mt:2}} onChange={onChangeDetail}
                           records={details} rowId={'purchaseorderdetailid'} hideFooter/>
            </Box>
        </Box>
    )
}
