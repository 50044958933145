import * as React from 'react';
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import SingleRecordForm from "../../../../../../View/Form/SingleRecordForm";
import {getRelativeMaxViews} from "../../../../../../Utils/Constant";
import Grid from "@mui/material/Grid";
import SuccessButton from "../../../../../../View/Button/SuccessButton";
import {doPost, doPostFile} from "../../../../../../Utils/Restclient/NetworkActions";
import {Stack} from "@mui/material";
import {error, success} from "../../../../../../Utils/Notification/notifications";
import DragDropFiles from "../../../../../../View/DragDropFiles/DragDropFiles";
import CustomDialog from "../../../../../../View/CustomDialog";
import Typography from "@mui/material/Typography";

const decimalFormatter = (value) => {
    value = value || 0;
    return value.toFixed(2);
}

const ui = {
    fields: {
        entrynumber : {name: 'entrynumber', label: 'Nº asiento',typeName:'String', flex: 0.3, align: 'center', optional: false, editable: false},
        date : {name: 'date', label: 'Fecha' , typeName: 'Date', editable: false, flex: 0.4},
        concept : {name: 'concept', label: 'Concepto',typeName:'String', align: 'left', optional: false, editable: false},
        account : {name: 'account', label: 'Cuenta',typeName:'String', flex: 0.5, align: 'center', optional: false, editable: false},
        description : {name: 'description', label: 'Descripción', typeName:'String', align: 'left', optional: false, editable: false},
        debit : {name: 'debit', label: 'Debe',typeName:'Number', flex: 0.4, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
        credit : {name: 'credit', label: 'Haber',typeName:'Number', flex: 0.4, align: 'center', optional: false, editable: false, valueFormatter: decimalFormatter},
    },
    keyComponent: 'AccountingJournalGrid',
}

const formUI =  {
    fields: {
        since : {name: 'since', label:'Desde' , typeName: 'Date', editable: true, autofocus: true, size: 2.5},
        until : {name: 'until', label:'Hasta' , typeName: 'Date', editable: true, size: 2.5},
        contractorid : {name: 'contractorid', label:'Empresa' , typeName: 'es.rbm.model.jpa.Contractor', editable: true, align: 'center', size: 3},
    }
}

const currentYear = new Date().getFullYear();
const firstDayOfYear = new Date(currentYear, 0, 1);
const lastDayOfYear = new Date(currentYear, 11, 31);

const initialState = {filter: {since: firstDayOfYear, until: lastDayOfYear, levels: '3,9'}, records: [],
    fileToImport: null, openImportData: false, openRenumber: false};

export default function AccountingJournal() {
    const [state, setState] = React.useState({...initialState});
    const [gridRef, setGridRef] = React.useState(null);
    const {filter, records, complete, fileToImport, openImportData, openRenumber} = state;

    const filterChange = (filter, complete) => {
        setState({...state, filter, complete, records: complete ? records : []});
    }

    const updateRecords = () => {
        const request = {...filter}
        let url = "accounting/getAccountingJournal";
        doPost(url, request, result => {
            if (result) {
                setState({...state, records: [...result.records]})
            }
        })
    }

    const downloadReport = () => {
        const csvString = gridRef.current.getDataAsCsv();
        doPostFile("utils/convertCsvToExcel", [csvString],response => {
            response.blob().then(blob => {
                const fileURL = window.URL.createObjectURL(blob);
                let alink = document.createElement('a');
                alink.href = fileURL;
                alink.download = 'diario-' + filter?.contractorid?.contractor + '.xlsx';
                alink.click();
            })
        },false)
    }

    const doImportData = () => {
        let url = 'importer/importAccountingentriesFromExcel';
        doPostFile(url, fileToImport,
            () => {
                onCloseImportData();
                success("Se ha realizado la importacion correctamente")
            })
    }

    const doRenumber = () => {
        const request = {...filter}
        let url = "accounting/renumberAccountingentries";
        doPost(url, request, result => {
            if (result) {
                setState({...state, records: [...result.records], openRenumber: false})
                success('Operación correcta correcto');
            }
        })
    }

    const onCloseImportData = () => setState({...state, openImportData: false})
    const onCloseRenumber = () => setState({...state, openRenumber: false})

    const openDialogImportData = () => setState({...state, openImportData: true})
    const openDialogRenumber = () => setState({...state, openRenumber: true})

    const handledFileAdd = (file1) => setState({...state, fileToImport: file1.target.value});


    let relativeMaxViews = getRelativeMaxViews(-5);
    return(
        <Box sx={{m: 2}}>
            <CustomDialog fullWidth title={'Seleccione el archivo a importar'} open={openImportData} disableEscapeKeyDown={false} onClose={onCloseImportData}>
                <Box sx={{m: 5, mb:2}}>
                    <Box sx={{m: 5, mt: 0}}>
                        <DragDropFiles onLoadFile={handledFileAdd} name={'fileAccounting'} file={fileToImport}/>
                    </Box>
                    <SuccessButton text={'IMPORTAR'} fullWidth sx={{mb: 1}} onClick={doImportData}/>
                </Box>
            </CustomDialog>
            <CustomDialog fullWidth title={'Reenumerar asientos'} open={openRenumber} disableEscapeKeyDown={false} onClose={onCloseRenumber}>
                <Box sx={{mx: 5, mb:2}}>
                    <Typography component="h5" variant="h6" sx={{mb: 2, mt: 2}}>
                        {'Se reenumerarán todos los asientos mostrados para los filtros introducidos.' +
                            'Los asientoa vacíos se borrarán.' +
                            '¿Estás seguro/a?'}
                    </Typography>
                    <SuccessButton text={'Reenumerar'} fullWidth sx={{mb: 1}} onClick={doRenumber}/>
                </Box>
            </CustomDialog>
            <Grid container spacing={2} >
                <Grid item md={8}>
                    <SingleRecordForm ui={formUI} records={filter} onChange={filterChange} disableAutoFocus/>
                </Grid>
                <Grid item md={4} align={'center'}>
                    <Stack spacing={2} direction={'row'} sx={{mt: 0.5}}>
                        <SuccessButton text={'Actualizar'} onClick={updateRecords} disabled={!complete}/>
                        <SuccessButton text={'Descargar'} onClick={downloadReport} disabled={!complete || !records.length} color={'secondary'}/>
                        <SuccessButton text={'Importar'} onClick={openDialogImportData} disabled={complete} variant={'outlined'}/>
                        <SuccessButton text={'Reenumerar'} onClick={openDialogRenumber} disabled={!complete || !records.length}  color={'error'}/>
                    </Stack>
                </Grid>
            </Grid>
            <GridTable ui={ui} records={records} density={'compact'} setGridRef={setGridRef}
                       rowId={'accountingentrydetailid'} sx={{height: relativeMaxViews, bgcolor: '#ffffff', mt: 2}}
            />
        </Box>
    )
}
