import LogoutIcon from '@mui/icons-material/Logout';
import {Paper, Typography, Box } from '@mui/material';

export default function LogoutPage() {

    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
                backgroundColor: '#f5f5f5',
                width: '100%',
                padding: { xs: 2, sm: 4 }
            }}
        >
            <Paper
                elevation={3}
                sx={{
                    padding: { xs: 3, sm: 4 },
                    textAlign: 'center',
                    borderRadius: 3,
                    width: '100%',
                    maxWidth: 400
                }}
            >
                <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                    <LogoutIcon sx={{ fontSize: { xs: 48, sm: 64 }, color: 'red' }} />
                </Box>
                <Typography variant="h5" fontWeight="bold" color="text.primary" sx={{ fontSize: { xs: '1.5rem', sm: '2rem' } }}>Sesión finalizada</Typography>
                <Typography variant="body1" color="text.secondary" mt={2} sx={{ fontSize: { xs: '0.9rem', sm: '1rem' } }}>Has cerrado sesión en el ERP. Gracias por confiar en nuestro sistema.</Typography>

                <Box mt={4}>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>Si necesitas ayuda, consulta nuestra <a href="/soporte" style={{ color: '#1976d2', textDecoration: 'none' }}>página de soporte</a>.</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>También puedes acceder a nuestra <a href="/documentacion" style={{ color: '#1976d2', textDecoration: 'none' }}>documentación</a> para más información sobre el sistema.</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ fontSize: { xs: '0.8rem', sm: '0.9rem' } }}>Si deseas contactarnos, escríbenos a <a href="mailto:soporte@sibema.es" style={{ color: '#1976d2', textDecoration: 'none' }}>soporte@sibema.es</a>.</Typography>
                </Box>

                <Typography variant="caption" color="text.disabled" mt={4} display="block" sx={{ fontSize: { xs: '0.7rem', sm: '0.8rem' } }}>© {new Date().getFullYear()} Sibema. Todos los derechos reservados.</Typography>
            </Paper>
        </Box>
    );
}
