import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {townUI} from "../Other/townCrud";
import {stateUI} from "../Other/stateCrud";
import {countryUI} from "../Other/countryCrud";
import EntityCollectionDialog from "../../../../../../View/Form/CrudForm/EntityCollectionDialog";

const getUiTab = (contractorid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Empresa", component: <EditObjectDialog objectid={contractorid} entityDefinition={contractorUI}/> },
                {label : "Series", component: <EntityCollectionDialog parentid={contractorid} info={contractorSerieInfo} /> }
            ]}
    })
}

export const contractorSeriesUI = {
    entity : {
        name: 'serie',
        keyField: 'serieid',
        label: 'Serie',
        info: {typeName: 'es.rbm.model.jpa.Serie'}
    },
    uiTable: {
        fields : {
            serieid : {name: 'serieid', label: '#',typeName:'String', align: 'center', optional: true, flex: 0.2},
            serie : {name: 'serie', label:'Serie' , typeName: 'String'},
            accountingaccountid : {name: 'accountingaccountid', label:'Cuenta' , typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
            seriepurchaseorder : {name: 'seriepurchaseorder', label: 'Pedido compras', typeName: 'Boolean', flex: 0.3, optional: true},
            seriepurchasenote : {name: 'seriepurchasenote', label: 'Albarán compras', typeName: 'Boolean', flex: 0.3, optional: true},
            seriepurchaseinvoice : {name: 'seriepurchaseinvoice', label: 'Factura compras', typeName: 'Boolean', flex: 0.3, optional: true},
            seriepurchaseinvoicerev : {name: 'seriepurchaseinvoicerev', label: 'Factura compras devuelta', typeName: 'Boolean', flex: 0.3, optional: true},
            seriesaleorder : {name: 'seriesaleorder', label: 'Pedido ventas', typeName: 'Boolean', flex: 0.3, optional: true},
            seriesalenote : {name: 'seriesalenote', label: 'Albarán ventas', typeName: 'Boolean', flex: 0.3, optional: true},
            seriesaleinvoice : {name: 'seriesaleinvoice', label: 'Factura ventas', typeName: 'Boolean', flex: 0.3, optional: true},
            seriesaleinvoicerev : {name: 'seriesaleinvoicerev', label: 'Factura ventas devuelta', typeName: 'Boolean', flex: 0.3, optional: true},
        },
        keyComponent: 'contractorSerieCrudManagement',
    },
    uiForm : {
        fields:{
            serieid : {name: 'serieid', label: '#',typeName:'String', align: 'center', optional: true},
            serie : {name: 'serie', label:'Serie' , typeName: 'String'},
            accountingaccountid : {name: 'accountingaccountid', label:'Cuenta' , typeName: 'es.rbm.model.jpa.Accountingaccount', optional: true},
            seriepurchaseorder : {name: 'seriepurchaseorder', label: 'Pedido compras', typeName: 'Boolean', size: 2, optional: true},
            seriepurchasenote : {name: 'seriepurchasenote', label: 'Albarán compras', typeName: 'Boolean', size: 2, optional: true},
            seriepurchaseinvoice : {name: 'seriepurchaseinvoice', label: 'Factura compras', typeName: 'Boolean', size: 2, optional: true},
            seriepurchaseinvoicerev : {name: 'seriepurchaseinvoicerev', label: 'Factura compras devuelta', typeName: 'Boolean', size: 2, optional: true},
            seriesaleorder : {name: 'seriesaleorder', label: 'Pedido ventas', typeName: 'Boolean', size: 2, optional: true},
            seriesalenote : {name: 'seriesalenote', label: 'Albarán ventas', typeName: 'Boolean', size: 2, optional: true},
            seriesaleinvoice : {name: 'seriesaleinvoice', label: 'Factura ventas', typeName: 'Boolean', size: 2, optional: true},
            seriesaleinvoicerev : {name: 'seriesaleinvoicerev', label: 'Factura ventas devuelta', typeName: 'Boolean', size: 2, optional: true},
        }
    },
}

export const contractorSerieInfo = {
    childrentypeName: 'es.rbm.model.jpa.Serie',
    parentTypeName: 'es.rbm.model.jpa.Contractor',
    ...contractorSeriesUI
}


const autoComplete = (record, field, value, callback) =>{
    if (field === 'townid') {
        const newRecord = {...record};
        const townid = record?.townid;
        newRecord.stateid = townid?.stateid;
        newRecord.countryid = townid?.stateid?.countryid;
        callback && callback({...newRecord});
    } else {
        callback && callback({...record})
    }
}
const groupByState = (option) => option?.stateid?.state || '';

export const contractorUI = {
    entity : {
        name: 'contractor',
        keyField: 'contractorid',
        label: 'Empresa',
        info: {typeName: 'es.rbm.model.jpa.Contractor'}
    },
    uiTable: {
        fields:{
            contractorid : {name: 'contractorid', label:'#' , typeName: 'Number', editable: false,flex:0.3},
            contractor : {name: 'contractor', label:'Empresa' , typeName: 'String', flex: 1.5, align: 'center'},
            idcardnumber : {name: 'idcardnumber', label:'CIF' , typeName: 'String', editable: true, align: 'center', flex: 0.75},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true, align: 'center'},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true, align: 'center'},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            townid : {name: 'townid', label:'Población' , typeName: 'es.rbm.model.jpa.Town', editable: true, align: 'center'},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true, align: 'center'},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, align: 'center'},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'String', editable: true, flex: 0.5},
        },
        keyComponent: 'contractorCrudManagement'
    },
    uiForm : {
        fields: {
            contractor : {name: 'contractor', label: 'Empresa', typeName: 'String'},
            idcardnumber : {name: 'idcardnumber', label: 'CIF', typeName: 'String', editable: true},
            address : {name: 'address', label: 'Dirección', typeName: 'String', editable: true},
            email : {name: 'email', label: 'Correo', typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label: 'Teléfono', typeName: 'Number', editable: true},
            townid : {name: 'townid', label: 'Población', typeName: 'es.rbm.model.jpa.Town', editable: true, addObject: townUI, groupBy: groupByState},
            stateid : {name: 'stateid', label: 'Provincia', typeName: 'es.rbm.model.jpa.State', editable: true, addObject: stateUI, disabled:true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true, addObject: countryUI, disabled:true},
            postalcode : {name: 'postalcode', label: 'CP', typeName: 'String', editable: true},
            resetdocumentnumber : {name: 'resetdocumentnumber', label: 'Fecha fin día contable', typeName: 'Date', size: 3, optional: true, empty: true},
            logo : {name: 'logo', label: 'Logo', typeName: 'DragAndDrop', optional: true, empty: true, size: 3},
        },
        onChangeHandleTrigger : (records, field, value, callback) => {
            autoComplete(records, field, value, callback);
        },
    },
    uiTab: (contractor) => getUiTab(contractor)
}