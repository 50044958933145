import {doDelete, doGet, doPost, doPut} from "./NetworkActions";

export const doPutRest = (entityname, toSend, callback) => {
    doPut('rest/' + entityname + '/update',toSend, response =>{
        callback && callback(response);
    })
}

export const doPostRest = (entityname, toSend, callback) => {
    doPost('rest/' + entityname + '/create', toSend, response =>{
        callback && callback(response);
    })
}

export const doPostRestList = (entityname, toSend, callback) => {
    doPost('rest/' + entityname + '/createlist', toSend, response =>{
        callback && callback(response);
    })
}

export const doGetRest = (entityname, id, callback) => {
    doGet('rest/' + entityname + '/' + id, response =>{
        callback && callback(response);
    })
}

export const doDeleteRest = (entityname, entityid, callback) => {
    doDelete('rest/' + entityname + '/delete/' + entityid, null,response =>{
        callback && callback(response);
    })
}