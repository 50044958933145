import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { doLogin } from "./actions";
import { actionTypes } from "../../../../Utils/Redux/reducer";
import {error, warningNotification} from "../../../../Utils/Notification/notifications";
import { getToken } from "../../../../Utils/Constant";
import store from "../../../../Utils/Redux/store";
import { useStateValue } from "../../../../Utils/Redux/StateProvider";
import { FormControlLabel, Checkbox } from "@mui/material";
import warning from "react-redux/es/utils/warning";

export default function SignIn(props) {
    const [email, setEmail] = useState(localStorage.getItem('remember-email') || "");
    const [password, setPassword] = useState(localStorage.getItem('remember-password') || "");
    const [remember, setRemember] = useState(localStorage.getItem('remember-me') === "true");
    const [login, setLogin] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [{ token }, dispatch] = useStateValue();
    const { navigateTo, offLineLogin} = props;

    useEffect(() => {
        // Si hay credenciales guardadas, intenta hacer login automático
        if (remember && email && password) {
            doSignIn();
        }
    }, []);

    const doSignIn = (e) => {
        if (e) e.preventDefault();
        doLogin(email, password, (result) => {
            if (result) {
                setLogin(true);
                let newLocation = location.pathname === "/" ? '/main' : location.pathname;
                navigate(navigateTo || newLocation);

                localStorage.setItem('token-session-erp', result.token);
                dispatch({ type: actionTypes.SET_USER, token: result.token });
                store.dispatch({ type: actionTypes.UNAUTHORIZED, authorized: true });
                store.dispatch({ type: actionTypes.SET_USER, token: result.token });
                localStorage.setItem('last-success-user', email);
                localStorage.setItem('last-success-password', password);
                if (remember) {
                    localStorage.setItem('remember-email', email);
                    localStorage.setItem('remember-password', password);
                    localStorage.setItem('remember-me', "true");
                } else {
                    localStorage.removeItem('remember-email');
                    localStorage.removeItem('remember-password');
                    localStorage.removeItem('remember-me');
                }
            } else {
                if (offLineLogin) {
                    doLoginOffline()
                } else {
                    error("Fallo al acceder");
                    localStorage.removeItem('remember-password');
                }
            }
        }, {hideError: offLineLogin, offlineMode: offLineLogin, onError : (result) => {
                let status = result?.status;
                if (status === 401) {
                    error("Usuario/Contraseña incorrecto")
                } else {
                    if (offLineLogin) {
                        doLoginOffline()
                    } else {
                        error('Fallo ' + status + ' al acceder');
                    }
                }
            }});
    };

    const doLoginOffline = () => {
        let last_success_user = localStorage.getItem('last-success-user');
        let last_success_password = localStorage.getItem('last-success-password');
        if (email === last_success_user && password === last_success_password) {
            warningNotification("Modo sin conexión activo");
            setLogin(true);
            let newLocation = location.pathname === "/" ? '/main' : location.pathname;
            navigate(navigateTo || newLocation);
        } else {
            error("Usuario/Contraseña offline incorrecto")
        }
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    p: 5,
                    marginTop: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    alignContent: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: '#053a70' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Acceso
                </Typography>
                <Box component="form" noValidate sx={{ mt: 1 }}>
                    <TextField
                        sx={{ bgcolor: 'white' }}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        margin="normal"
                        required
                        fullWidth
                        id="user"
                        label="Usuario"
                        name="user"
                        autoComplete="user"
                        autoFocus
                    />
                    <TextField
                        value={password}
                        sx={{ bgcolor: 'white' }}
                        onChange={e => setPassword(e.target.value)}
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Contraseña"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        color={'primary'}
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={remember}
                                onChange={(e) => setRemember(e.target.checked)}
                                color="primary"
                            />
                        }
                        label="Recordar contraseña"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                        onClick={doSignIn}
                    >
                        ENTRAR
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}
