import EditObjectDialog from "../../../../../../View/Form/CrudForm/EditObjectDialog";
import {doGet} from "../../../../../../Utils/Restclient/NetworkActions";
import * as React from "react";
import DetailsPurchaseorderDialog from "./DetailsPurchaseorderDialog";
import PurchaseorderPurchasenoteView from "./PurchaseorderPurchasenoteView";
import PurchaseorderPurchaseinvoiceView from "./PurchaseorderPurchaseinvoiceView";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";
import {purchaseorderstatusUI} from "./purchaseorderstatusUI";
import {paymentmethodUI} from "../Other/paymentmethodCrud";
import {showOrDownloadDocument} from "../../../../../../Utils/UtilsCommon";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";

const getUiTab = (purchaseorderid) =>{
    return ({
        uiTabs : {fields : [
                {label : "Pedido de compra", component: <EditObjectDialog objectid={purchaseorderid} entityDefinition={purchaseorderUI}/> },
                {label : "Detalles", component: <DetailsPurchaseorderDialog selected={purchaseorderid}/> },
                {label : "Facturas", component: <PurchaseorderPurchaseinvoiceView purchaseorderid={purchaseorderid}/> },
                {label : "Albaranes", component: <PurchaseorderPurchasenoteView purchaseorderid={purchaseorderid}/> },
            ]}
    })
}

const sendMail = (purchaseorderid, setOpenMail, setMailRecords) =>{
    const to = purchaseorderid.email || purchaseorderid.providerid?.email;
    setMailRecords({to: to, subject: 'Pedido '+ purchaseorderid.serie + '/' + purchaseorderid.number})
    setOpenMail(true);
}

const autoComplete = (record, field, value, callback) =>{
    if (field === 'contractorid' && record?.contractorid) {
        const newRecord = {...record};
        const contractorid = record?.contractorid;
        const filters = {
            entity : { name: 'serie'},
            fields: {
                contractorid : {name: 'contractorid', operator: OPERATORS.OPERATOR_EQUAL, value: contractorid},
                seriepurchaseorder : {name: 'seriepurchaseorder', operator: OPERATORS.OPERATOR_EQUAL, value: true},
            },
        }
        searchByCriteria(filters, result => {
            if (result && result.length) {
                newRecord.serie = result[0].serie;
                callback && callback({...newRecord});
            } else {
                callback && callback({...record})
            }
        });
    } else {
        callback && callback({...record})
    }
}

export const providerUILocal = {
    entity : {
        name: 'provider',
        keyField: 'providerid',
        label: 'Proveedor',
        urlExcelImport: 'importer/importProviderFromExcel',
        info: {typeName: 'es.rbm.model.jpa.Provider'}
    },
    uiForm : {
        fields:{
            providerid : {name: 'providerid', label:'Cod.' , typeName: 'Number', editable: false, optional: true},
            provider : {name: 'provider', label:'Nombre' , typeName: 'String' },
            idcardnumber : {name: 'idcardnumber', label:'DNI/CIF' , typeName: 'String', editable: true},
            address : {name: 'address', label:'Dirección' , typeName: 'String', editable: true},
            email : {name: 'email', label:'Correo' , typeName: 'String', editable: true, optional: true},
            phone : {name: 'phone', label:'Teléfono' , typeName: 'Number', editable: true},
            townid : {name: 'townid', label:'Ciudad' , typeName: 'es.rbm.model.jpa.Town', editable: true},
            stateid : {name: 'stateid', label:'Provincia' , typeName: 'es.rbm.model.jpa.State', editable: true},
            postalcode : {name: 'postalcode', label:'CP' , typeName: 'String', editable: true},
            countryid : {name: 'countryid', label:'Pais' , typeName: 'es.rbm.model.jpa.Country', editable: true},
            remarks : {name: 'remarks', label:'Observaciones' , typeName: 'String',  multiline: true, rows: 5, size: 12, optional: true},
        }
    },
}

const getReportOptions = (setOption) => {
    doGet('purchaseorder/getPurchaseorderReportOptions/' + 1, result => {
        setOption && setOption(result);
    });
}

const onSelectReport = (op, selected, pdfSet) => {
    const url = 'purchaseorder/downloadPurchaseorderReport/' + selected.purchaseorderid;
    let fileName = op.label + '_' + selected.purchaseorderid + op.extension;
    showOrDownloadDocument(url, op, fileName, pdfSet)
}

export const purchaseorderUI = {
    entity : {
        name: 'purchaseorder',
        keyField: 'purchaseorderid',
        label: 'Pedido de compra',
        info: {typeName: 'es.rbm.model.jpa.Purchaseorder'},
        colorValue: 'purchaseorderstatusid'
    },
    uiTable: {
        fields:{
            purchaseorderid : {name: 'purchaseorderid', label:'#' , typeName: 'String', editable: false,flex:0.3 },
            serie : {name: 'serie', label:'Serie' , typeName: 'String', editable: false, flex:0.5, optional: false, align: 'center'},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false,flex:0.5, optional: false},
            contractorid : {name: 'contractorid', label: 'Empresa', typeName:'es.rbm.model.jpa.Contractor'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            providerid : {name: 'providerid', label: 'Proveedor',typeName:'es.rbm.model.jpa.Provider'},
            purchaseorderstatusid : {name: 'purchaseorderstatusid', label: 'Estado',typeName:'es.rbm.model.jpa.Purchaseorderstatus', align: 'center'},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center'},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            remarks : {name: 'remarks', label: 'Observaciones',typeName:'String', optional: true},

        },
        actions: {
            onPrint: {
                onSelectedOption: onSelectReport,
                getOptions: getReportOptions
            },
            sendEmail:{
                fun: (entity, setOpenMail, setMailRecords) => sendMail(entity, setOpenMail, setMailRecords)
            },
        },
        keyComponent: 'purchaseorderCrudManagement'
    },
    uiForm : {
        fields:{
            purchaseorderid : {name: 'purchaseorderid', label:'Cod.' , typeName: 'String',optional: true, editable: false,size:2},
            serie : {name: 'serie', label:'Serie' , typeName: 'String', size: 2, optional: true},
            number : {name: 'number', label:'Número' , typeName: 'Number', editable: false, size: 2, optional: true},
            contractorid : {name: 'contractorid', label: 'Empresa',typeName:'es.rbm.model.jpa.Contractor'},
            date : {name: 'date', label: 'Fecha',typeName:'Date'},
            providerid : {name: 'providerid', label: 'Proveedor',typeName:'es.rbm.model.jpa.Provider', addObject: providerUILocal},
            purchaseorderstatusid : {name: 'purchaseorderstatusid', label: 'Estado',typeName:'es.rbm.model.jpa.Purchaseorderstatus', align: 'center', addObject: purchaseorderstatusUI},
            paymentmethodid : {name: 'paymentmethodid', label:'Método de pago' , typeName:'es.rbm.model.jpa.Paymentmethod', align: 'center', addObject: paymentmethodUI},
            discount : {name: 'discount', label:'Descuento' , typeName:'Number', optional: true},
            remarks : {name: 'remarks', label: 'Observaciones',typeName:'String', optional: true,  multiline: true, rows: 8, size: 12},
        },
        onChangeHandleTrigger : (records, field, value, callback) =>{
            autoComplete(records, field, value, callback);
        }
    },
    filters : {
        startBegin : true,
        openEdit : true,
        initialsValues: {
            date_1: new Date(new Date().setDate(new Date().getDate() - 7))
        },
        fields: {
            purchaseorderid : {name: 'purchaseorderid', label: 'Nº pedido' ,typeName: 'Number', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            providerid : {name: 'providerid', label: 'Proveedor' ,typeName: 'es.rbm.model.jpa.Provider', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            serie : {name: 'serie', label: 'Serie' ,typeName: 'String', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            number : {name: 'number', label: 'Número' ,typeName: 'String', operator: OPERATORS.OPERATOR_LIKE, optional: true},
            purchaseorderstatusid : {name: 'purchaseorderstatusid',label: 'Estado', typeName: 'es.rbm.model.jpa.Purchaseorderstatus', operator: OPERATORS.OPERATOR_EQUAL, optional: true},
            date : {name: 'date',label: 'Día', typeName: 'Date', operator: OPERATORS.OPERATOR_BETWEEN, empty: true, optional: true},
            date_1 : {name: 'date_1',label: 'Desde', typeName: 'Date', operator: OPERATORS.OPERATOR_GTE, empty: true, optional: true},
            date_2 : {name: 'date_2',label: 'Hasta', typeName: 'Date', operator: OPERATORS.OPERATOR_LTE, empty: true, endDay: true, optional: true},
        }
    },
    uiTab: (purchaseorder) => getUiTab(purchaseorder)
}
