import * as React from 'react';
import {useEffect} from "react";
import Box from "@mui/material/Box";
import GridTable from "../../../../../../View/Form/GridTable";
import {searchByCriteria} from "../../../../../../Utils/Persistence/PersistenceQuery";
import {groupBy} from "../../../../../../Utils/UtilsCommon";
import {purchaseinvoiceUI} from "../Purchaseinvoice/purchaseinvoiceCrud";
import {OPERATORS} from "../../../../../../Utils/Persistence/PersistenceConstant";


const purchaseinvoicedetailUI = {
    fields: {
        productid : {name: 'productid', label: 'Producto',typeName:'es.rbm.model.jpa.Product', flex: 3, optional: false},
        purchaseinvoicedetail : {name: 'purchaseinvoicedetail', label: 'Concepto',typeName:'String', flex: 3, align: 'center', optional: false},
        quantity : {name: 'quantity', label:'Cantidad' , typeName: 'Number', optional: false},
        price : {name: 'price', label:'Precio' , typeName: 'Number', optional: false},
        discount : {name: 'discount', label:'Descuento' , typeName: 'Number'},
        batchid : {name: 'batchid', label:'Lote' , typeName: 'es.rbm.model.jpa.Batch'},
        vatid : {name: 'vatid', label:'IVA' , typeName: 'es.rbm.model.jpa.Vat', optional: false},
        purchasenotedetailid : {name: 'purchasenotedetailid', label:'Albarán', typeName: 'Boolean', editable: false},
    },
    sort: {
        field: 'purchaseinvoicedetailis',
        sort: 'desc'
    },
    keyComponent: 'purchaseinvoiceDetails',
}

export default function PurchaseinvoiceProviderView(props) {
    const {providerid} = props;
    const [records, setRecords] = React.useState([]);
    const [details, setDetails] = React.useState([]);
    const [groups, setGroups] = React.useState([]);

    useEffect(() =>{
        refresh()
    }, [providerid])

    const refresh = () => {
        setDetails([])
        setRecords([])
        const filters = {
            entity : {name: 'purchaseinvoice'},
            fields: {providerid : {name: 'providerid', operator: OPERATORS.OPERATOR_EQUAL, value: providerid}},
        }
        searchByCriteria(filters, result => {
            if (result && result.length > 0) {
                const filters = {
                    entity : {name: 'purchaseinvoicedetail'},
                    fields: {purchaseinvoiceid : {name: 'purchaseinvoiceid', operator: OPERATORS.OPERATOR_IN, value: result}},
                }
                searchByCriteria(filters, result1 =>{
                    const purchaseinvoiceGroup = groupBy(result1, "purchaseinvoiceid.purchaseinvoiceid");
                    setGroups(purchaseinvoiceGroup);
                    const newRecords = Object.keys(purchaseinvoiceGroup).map(key => purchaseinvoiceGroup[key][0].purchaseinvoiceid);
                    setRecords(newRecords)
                    setDetails([]);
                });
            }
        });
    }

    const onChange = (e) => {

    }
    const onRowClick = (e, v) => {
        if(e){
            setDetails(groups[e.row.purchaseinvoiceid]);
        }
    }

    return(
        <Box sx={{m: 2, alignItems: 'center'}}>
            <Box overflow={'auto'}>
                <GridTable ui={purchaseinvoiceUI.uiTable} colorValue={'purchaseinvoicestatusid'}
                           sx={{height: '40vh'}} enableRowSelectionOnClick
                           records={records} rowId={'purchaseinvoiceid'} onRowClick={onRowClick}
                           onChange={onChange} toolBar={'S'} hideFooter/>
            </Box>
            <Box overflow={'auto'}>
                <GridTable ui={purchaseinvoicedetailUI}
                           sx={{height: '30vh', mt:2}}
                           density={'compact'}
                           records={details} rowId={'purchaseinvoicedetailid'}
                           onChange={onChange}  hideFooter/>
            </Box>
        </Box>
    )
}